import { useParams } from "react-router-dom";
import NavBar from "../NavBar";
import "./PublicationsDetails.scss";
import LoadingOverlay from "components/blocks/LoadingOverlay";
import { useTranslation } from "react-i18next";
import useSystemDateTheme from "hooks/useSystemDateTheme";
import { useCookieContext } from "contexts";
import PublicationBookCard from "./PublicationBookCard";
import { useQuery } from "hooks/useQuery";
import { ScientificPublicationsService } from "services/scientificPublicationsService";
import { OperatorFilter } from "services/shared-models/sharedEnums";
import { ScientificPublishingAttachment } from "views/home/scientificPublication/form/ScientificPublicationForm.types";

export const FileCard = ({ file }: { file: ScientificPublishingAttachment }) => {
  return (
    <div className="file-card flex-1 d-flex gap-2 p-4 bg-white rounded-4 align-items-start shadow">
      <span className="icon-epub fs-48px" />
      <div className="flex-1">
        <h6 className="mb-1 fw-bold">{file.fileLang?.fileLangDetail.displayName}</h6>
        <p className="m-0 text-gray">{file.translatedName}</p>
        <p className="mt-3 mb-0">{file.description}</p>
      </div>
    </div>
  );
};

const PublicationsDetails = () => {
  const { isAr } = useCookieContext();
  const { toSystemThemeDateFormat } = useSystemDateTheme();
  const { t, i18n } = useTranslation("ScientificPublication");

  const { id } = useParams();

  const { data: scientificPublication, loading: scientificPublicationLoading } = useQuery({
    queryFn: async () => {
      return await ScientificPublicationsService.getPublicScientificPublishingById(id ? +id : 0);
    },
    options: { enabled: !!id },
    triggers: [isAr, id],
  });

  const { data: allPublicScientificPublishing, loading: allPublicScientificPublishingLoading } = useQuery({
    queryFn: async () => {
      return await ScientificPublicationsService.getAllPublicScientificPublishing({
        pageIndex: 1,
        pageSize: 10,
        filter: [
          {
            field: "TypeOfScientificId",
            value: scientificPublication?.typeOfScientific?.id,
            operator: OperatorFilter.equal,
          },
        ],
      });
    },
    options: { enabled: !!scientificPublication },
    triggers: [scientificPublication],
  });

  return (
    <div className=" bg-white d-flex flex-column gap-4 position-relative text-dark">
      <LoadingOverlay visible={scientificPublicationLoading} className="h-100" />

      <div className="shadow py-1  px-6rem ">
        <NavBar tapClassName="text-dark" variant="dark" />
      </div>

      <div className="publications-details-container d-flex gap-4 pb-5">
        {/* Right Side (Image, Info, etc.) */}
        <div className="d-flex flex-column gap-4 align-items-center">
          <img
            className="rounded-1 book-image"
            src={scientificPublication?.scientificPublishingImageUrl}
            alt="publication"
            style={{ height: "320px", width: "200px" }}
          />
          <div className="d-flex w-100 justify-content-between align-items-center gap-2">
            <div className="text-center">
              <p className="m-0">{t("numberOfPages")}</p>
              <p className="m-0 fw-bold">{scientificPublication?.numberOfPaper}</p>
            </div>
            <div className="h-50 border-start" />
            <div className="text-center">
              <p className="m-0">{t("scientificField")}</p>
              <p className="m-0 fw-bold">
                {scientificPublication?.typeOfScientific?.typeOfScientificDetail.displayName}
              </p>
            </div>
          </div>

          {/* TODO: ask about it */}
          <img className="barcode mt-2" src={require("./temp-barcode.svg").default} alt="barcode" />
        </div>
        {/* End of Right Side */}

        {/* Left Side (Info, etc.) */}
        <div className="border-start" />
        <div className="flex-1 d-flex flex-column gap-2">
          <div className="d-flex gap-2">
            <p className="m-0 fs-12px">{t("scientificPublications")}</p>
            <span className="icon-arrow-left fs-20px" />
            <p className="m-0 fs-12px">{scientificPublication?.scientificPublishingDetail?.displayName}</p>
          </div>

          {/* Header */}
          <div>
            <h5 className="mt-4 fw-bold"> {scientificPublication?.scientificPublishingDetail?.displayName}</h5>
            <p className="mt-3">{scientificPublication?.scientificPublishingDetail?.description}</p>
          </div>
          {/* End of Header */}

          {/* Striped Table Info */}
          <div className="striped d-flex flex-column position-relative ">
            <div className="d-flex flex-1 p-3">
              <span className="flex-1 fw-bold text-dark">{t("scientificPublicationNumber")}</span>
              <span className="flex-1">{scientificPublication?.serialNumber}</span>
            </div>
            <div className="d-flex flex-1 p-3">
              <span className="flex-1 fw-bold text-dark">{t("publisherName")}</span>
              <span className="flex-1">{scientificPublication?.authorName} </span>
            </div>
            <div className="d-flex flex-1 p-3">
              <span className="flex-1 fw-bold text-dark">{t("publicationTypeCategory")}</span>
              <span className="flex-1">
                {scientificPublication?.scientificClassification?.scientificClassificationDetail.displayName}
              </span>
            </div>
            <div className="d-flex flex-1 p-3">
              <span className="flex-1 fw-bold text-dark">{t("publicationLanguage")}</span>
              <span className="flex-1">
                {scientificPublication?.languageOfScientific?.languageOfScientificDetail.displayName}
              </span>
            </div>
            <div className="d-flex flex-1 p-3">
              <span className="flex-1 fw-bold text-dark">{t("publicationDate")}</span>
              <span className="flex-1">{toSystemThemeDateFormat(scientificPublication?.publicationDate)}</span>
            </div>
            <div className="d-flex flex-1 p-3">
              <span className="flex-1 fw-bold text-dark">{t("publicationOwner")}</span>
              <span className="flex-1">{scientificPublication?.publisher?.publisherDetail.displayName} </span>
            </div>
            <div className="d-flex flex-1 p-3">
              <span className="flex-1 fw-bold text-dark">{t("publicationOwnerType")}</span>
              <span className="flex-1">{scientificPublication?.publisherType?.publisherTypeDetail.displayName} </span>
            </div>
          </div>
          {/* End of Striped Table Info */}

          <div className="divider my-4" />

          {/* Files */}
          <div>
            <h5 className="mb-4">{t("scientificPublications")}</h5>
            <div className="d-flex flex-wrap gap-3">
              {scientificPublication
                ? scientificPublication?.scientificPublishingAttachments.map((ele, index) => {
                    return <FileCard key={index} file={ele} />;
                  })
                : t("Common:noData")}
            </div>
          </div>
          {/* End of Files */}

          <div className="divider mb-2 mt-4" />

          {/* Libraries */}
          <div>
            <h5 className="mb-4">{t("availableIn")}</h5>
            <div className="d-flex flex-wrap gap-3">
              {scientificPublication?.libraries.map((ele, index) => (
                <div key={index} className="p-4 bg-white rounded-4 align-items-center shadow">
                  <h6 className="mb-1 fw-bold text-center">{ele.libraryDetail.displayName} </h6>
                  <p className="m-0 text-gray text-center">الموقع</p>
                  {/* TODO: publ location */}
                </div>
              ))}
            </div>
          </div>
          {/* End of Libraries */}

          <div className="divider mb-3 mt-4" />

          {/* Similar Publications */}
          <div className="d-flex flex-column gap-3">
            <h5 className="mb-4">{t("similarPublications")}</h5>
            <div className="d-flex gap-2 flex-wrap">
              {allPublicScientificPublishingLoading ? (
                <div className="custom-loader"></div>
              ) : (
                allPublicScientificPublishing?.map((ele, index) => (
                  <PublicationBookCard key={index} scientificPublishing={ele} />
                ))
              )}
            </div>
          </div>
          {/* End of Similar Publications */}

          {/* End of Left Side */}
        </div>
      </div>
    </div>
  );
};

export default PublicationsDetails;
