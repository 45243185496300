import Accordion from "components/blocks/Accordion";
import AccordionItem from "components/blocks/Accordion/AccordionItem";
import DatePicker from "components/blocks/DatePicker";
import FormInput from "components/blocks/FormInput";
import { useTranslation } from "react-i18next";
import { CreateScientificPublicationRequest } from "../ScientificPublicationForm.types";
import { FieldErrors, UseFormRegister, UseFormSetValue, UseFormWatch } from "react-hook-form";
import LookupDropdown from "components/blocks/LookupDropdown";

type SPFormMainInfoProps = {
  register: UseFormRegister<CreateScientificPublicationRequest>;
  setValue: UseFormSetValue<CreateScientificPublicationRequest>;
  watch: UseFormWatch<CreateScientificPublicationRequest>;
  errors: FieldErrors<CreateScientificPublicationRequest>;
};

const SPFormMainInfo: React.FC<SPFormMainInfoProps> = ({ register, setValue, watch, errors }) => {
  const { t } = useTranslation("ScientificPublication");

  return (
    <Accordion negatePadding>
      <AccordionItem initiallyOpen title={t("mainInfo")} elementId="main-info">
        <div className="d-flex flex-column gap-3 mb-3">
          <FormInput
            label={t("publicationNameAr")}
            placeholder={t("publicationNameAr")}
            {...register("scientificPublishingDetails.0.displayName")}
            error={t(errors.scientificPublishingDetails?.[0]?.displayName?.message ?? "")}
          />
          <FormInput
            label={t("publicationDescriptionAr")}
            placeholder={t("publicationDescriptionAr")}
            {...register("scientificPublishingDetails.0.description")}
            error={t(errors.scientificPublishingDetails?.[0]?.description?.message ?? "")}
          />
          <FormInput
            label={t("publicationNameEn")}
            placeholder={t("publicationNameEn")}
            {...register("scientificPublishingDetails.1.displayName")}
            error={t(errors.scientificPublishingDetails?.[1]?.displayName?.message ?? "")}
          />
          <FormInput
            label={t("publicationDescriptionEn")}
            placeholder={t("publicationDescriptionEn")}
            {...register("scientificPublishingDetails.1.description")}
            error={t(errors.scientificPublishingDetails?.[1]?.description?.message ?? "")}
          />
        </div>

        <div className="row">
          <LookupDropdown
            service="lookupService"
            endpoint="getAllScientificSpecializationDropDown"
            label={t("publicationField")}
            wrapperClassName="mb-2 col-12 col-md-6 col-lg-3"
            noSelectionPlaceholder={t("publicationField")}
            idValueKey="id"
            textValueKey="details.0.displayName"
            useReactSelect
            value={watch("scientificSpecializationId")}
            onChange={(value) => {
              setValue("scientificSpecializationId", +value);
            }}
            error={t(errors.scientificSpecializationId?.message ?? "")}
          />
          <LookupDropdown
            service="lookupService"
            endpoint="getAllTypeOfScientificDropDown"
            label={t("publicationType")}
            wrapperClassName="mb-2 col-12 col-md-6 col-lg-3"
            noSelectionPlaceholder={t("publicationType")}
            idValueKey="id"
            textValueKey="details.0.displayName"
            useReactSelect
            value={watch("typeOfScientificId")}
            onChange={(value) => {
              setValue("typeOfScientificId", +value);
            }}
            error={t(errors.typeOfScientificId?.message ?? "")}
          />
          <LookupDropdown
            service="lookupService"
            endpoint="getAllScientificClassificationDropDown"
            label={t("publicationCategory")}
            wrapperClassName="mb-2 col-12 col-md-6 col-lg-3"
            noSelectionPlaceholder={t("publicationCategory")}
            idValueKey="id"
            textValueKey="details.0.displayName"
            useReactSelect
            value={watch("scientificClassificationId")}
            onChange={(value) => {
              setValue("scientificClassificationId", +value);
            }}
            error={t(errors.scientificClassificationId?.message ?? "")}
          />
          <LookupDropdown
            service="lookupService"
            endpoint="getAllLanguageOfScientificDropDown"
            label={t("publicationLanguage")}
            wrapperClassName="mb-2 col-12 col-md-6 col-lg-3"
            noSelectionPlaceholder={t("publicationLanguage")}
            idValueKey="id"
            textValueKey="details.0.displayName"
            useReactSelect
            value={watch("languageOfScientificId")}
            onChange={(value) => {
              setValue("languageOfScientificId", +value);
            }}
            error={t(errors.languageOfScientificId?.message ?? "")}
          />

          <FormInput
            label={t("publisherName")}
            wrapperClassName="mb-2 col-12 col-md-6 col-lg-3"
            placeholder={t("publisherName")}
            {...register("authorName")}
            error={t(errors.authorName?.message ?? "")}
          />

          <DatePicker
            label={t("publicationDate")}
            wrapperClassName="mb-2 col-12 col-md-6 col-lg-3"
            placeholder={t("publicationDate")}
            value={watch("publicationDate")}
            onChange={(value) => {
              setValue("publicationDate", value!);
            }}
            error={t(errors.publicationDate?.message ?? "")}
          />

          <LookupDropdown
            service="lookupService"
            endpoint="getAllPublisherDropDown"
            label={t("publicationOwner")}
            wrapperClassName="mb-2 col-12 col-md-6 col-lg-3"
            noSelectionPlaceholder={t("publicationOwner")}
            idValueKey="id"
            textValueKey="details.0.displayName"
            useReactSelect
            value={watch("publisherId")}
            onChange={(value) => {
              setValue("publisherId", +value);
            }}
            error={t(errors.publisherId?.message ?? "")}
          />
          <LookupDropdown
            service="lookupService"
            endpoint="getAllPublisherTypeDropDown"
            label={t("publicationOwnerType")}
            wrapperClassName="mb-2 col-12 col-md-6 col-lg-3"
            noSelectionPlaceholder={t("publicationOwnerType")}
            idValueKey="id"
            textValueKey="details.0.displayName"
            useReactSelect
            value={watch("publisherTypeId")}
            onChange={(value) => {
              setValue("publisherTypeId", +value);
            }}
            error={t(errors.publisherTypeId?.message ?? "")}
          />
        </div>
      </AccordionItem>
    </Accordion>
  );
};

export default SPFormMainInfo;
