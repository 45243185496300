import Grid from "components/GridView/components/Grid";
import { GridSchema } from "components/GridView/GridView.types";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { extensionsMapper } from "../../form/components/SPFormAttachmentsTable";
import { ScientificPublishingLocalItemResult } from "services/scientificPublicationsService/models/scientificPublicationsTypes";

const SPVStatistics = ({ data }: { data: ScientificPublishingLocalItemResult }) => {
  const { t } = useTranslation("ScientificPublication");

  // TODO: use grid view hook

  const dd = [
    {
      file: "file.pdf",
      language: "عربي",
      viewCount: 10,
      readCount: 5,
      downloadCount: 2,
      shareCount: 3,
    },
  ];
  const gridSchema = useMemo<GridSchema[]>(
    () => [
      {
        field: "file",
        displayName: t("file"),
        showOnMobile: true,
        columnClassName: "text-center",
        render(row) {
          return (
            <button className="text-center btn btn-link w-100">
              <span
                className={`icon-${extensionsMapper[row.file.extension as keyof typeof extensionsMapper] ?? "pdf"}-big`}
              />
            </button>
          );
        },
      },
      {
        field: "language",
        displayName: t("language"),
        showOnMobile: true,
      },
      {
        field: "viewCount",
        displayName: t("viewCount"),
      },
      {
        field: "readCount",
        displayName: t("readCount"),
      },
      {
        field: "downloadCount",
        displayName: t("downloadCount"),
        showOnMobile: true,
      },
      {
        field: "shareCount",
        displayName: t("shareCount"),
      },
    ],
    [],
  );

  return (
    <div>
      <h6 className="fw-bold mb-3">{t("Common:attachments")}</h6>
      <Grid data={dd} gridSchema={gridSchema} />
    </div>
  );
};

export default SPVStatistics;
