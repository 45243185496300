import Modal from "components/blocks/Modal";
import Switch from "components/blocks/Switch";
import useMutation from "hooks/useMutation";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ExaminersService } from "services/examinersService";
import { ExaminerRewardApprovedRequest, ExaminerResult } from "services/examinersService/Model/ExaminerTypes";
import { BaseResponseType } from "services/shared-models/sharedTypes";

export type AcceptExaminerRewardModalProps = {
  isOpen: boolean;
  onClose?: (shouldUpdateTable: boolean) => void;
  courseOrderExaminerId?: number;
};

const AcceptExaminerRewardModal: React.FC<AcceptExaminerRewardModalProps> = (props: AcceptExaminerRewardModalProps) => {
  const { isOpen, onClose, courseOrderExaminerId } = props;
  const { t } = useTranslation("Orders");

  const {
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
    control,
  } = useForm<ExaminerRewardApprovedRequest>({
    defaultValues: {
      feesNotes: "",
      isConfirmFees: false,
    },
  });

  const { mutateAsync, loading } = useMutation({
    queryFn: async ({
      courseOrderExaminerId,
      request,
    }: {
      courseOrderExaminerId: number;
      request: ExaminerRewardApprovedRequest;
    }) => {
      return await ExaminersService.examinerRewardApproved(courseOrderExaminerId, request);
    },
  });

  const onSubmit = async (values: ExaminerRewardApprovedRequest) => {
    const res = (await mutateAsync({
      courseOrderExaminerId: courseOrderExaminerId!,
      request: values,
    })) as BaseResponseType<ExaminerResult>;

    if (!res.hasError) {
      onClose?.(true);
    }
  };

  const onCancel = () => {
    onClose?.(false);
  };

  useEffect(() => {
    if (!isOpen) {
      setValue("feesNotes", "");
      setValue("isConfirmFees", false);
    }
  }, [isOpen]);

  return (
    <Modal
      containerClassName="bg-white pb-2"
      isOpen={isOpen}
      onClose={onCancel}
      title={t("confirmExaminersResult")}
      withHeaderBorder
      size="lg"
      titleClassName="fs-6 text-dark"
    >
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Switch
          checked={watch("isConfirmFees")}
          onChange={(value) => {
            setValue("isConfirmFees", value);
          }}
          label={t("approveExaminerResult")}
        />

        <label className="form-label mt-2">{t("Common:notes")}</label>
        <textarea
          className={`form-control`}
          rows={5}
          placeholder={t("Common:notes")}
          onChange={(e) => setValue("feesNotes", e.target.value)}
          style={{ resize: "none" }}
        />

        <div className="d-flex justify-content-center gap-2 w-100 mt-4">
          <button type="button" className="btn text-dark rounded-3 p-10-32P" onClick={onCancel}>
            {t("Common:cancel")}
          </button>
          <Controller
            name="feesNotes"
            control={control}
            render={({ field }) => (
              <button className="btn btn-primary rounded-3 p-10-32P" disabled={loading || !field.value?.length}>
                {!loading ? t("Common:save") : <div className="custom-loader"></div>}
              </button>
            )}
          />
        </div>
      </form>
    </Modal>
  );
};

export default AcceptExaminerRewardModal;
