import GridView from "components/GridView";
import { GridSchema } from "components/GridView/GridView.types";
import { useCookieContext } from "contexts";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

/* ***** TODO: Move to the proper location (Service/Models) ***** */
export type cycleApplicantsResponse = any & {};
const data: cycleApplicantsResponse[] = [
  {
    id: "1",
    appliedUserName: "علي خالد سليمان",
    degree: "استاذ مشارك",
    collage: "كلية الشريعة",
    section: "قسم الفقه",
    specialization: "الفقه الإسلامي",
    employer: "جهة العمل",
    email: "ali@gmail.com",
    phoneNumber: "0599999999",
  },
  {
    id: "2",
    appliedUserName: "محمد خالد سليمان",
    degree: "استاذ مشارك",
    collage: "كلية الشريعة",
    section: "قسم الفقه",
    specialization: "الفقه الإسلامي",
    employer: "جهة العمل",
    email: "ali@gmail.com",
    phoneNumber: "0599999999",
  },
];
/* ************************************************************** */

type ApplicantsTabProps = {
  courseId: string | number;
};

const ApplicantsTab: React.FC<ApplicantsTabProps> = (props) => {
  const { courseId } = props;
  const { t } = useTranslation("Courses");
  const { isAr } = useCookieContext();

  // TODO:use grid hook to here

  const applicantsGridSchema = useMemo<GridSchema[]>(
    () => [
      {
        field: "appliedUserName",
        displayName: t("appliedUserName"),
        showOnMobile: true,
      },
      {
        field: "degree",
        displayName: t("degree"),
      },
      {
        field: "collage",
        displayName: t("collage"),
        render: (row) => <>{row.collegeNavigation?.collegeDetail.displayName ?? row.college}</>,
      },
      {
        field: "section",
        displayName: t("section"),
      },
      {
        field: "specialization",
        displayName: t("specialization"),
        showOnMobile: true,
      },
      {
        field: "employer",
        displayName: t("employer"),
      },
      {
        field: "email",
        displayName: t("Common:email"),
      },
      {
        field: "phoneNumber",
        displayName: t("Common:phoneNumber"),
      },
    ],
    [isAr],
  );

  return (
    <GridView
      loading={false /*loading*/}
      viewHeaderProps={{
        singularName: t("applicant"),
        title: t("applicants"),
        totalCount: 2 /*totalCount*/,
        hideAddButton: true,
      }}
      gridProps={{
        data: data ?? [],
        gridSchema: applicantsGridSchema,
      }}
    />
  );
};

export default ApplicantsTab;
