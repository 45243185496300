import { HttpClient } from "helpers/http/httpClient";
import {
  BaseResponseType,
  CentreManagementLocalResult,
  DataGridRequest,
  FileDownload,
  PagedResult,
} from "services/shared-models/sharedTypes";
import {
  CreateCourseOrderRequest,
  CourseOrderResult,
  CourseOrderLocalResult,
  CourseOrderLocalItemResult,
} from "./models/CourseOrderType";

export const CourseOrderService = {
  createCourseOrder: async (createCourseOrderRequest: CreateCourseOrderRequest) => {
    return await HttpClient.post<BaseResponseType<CourseOrderResult>>(
      "/api/CourseOrder/CreateCourseOrder",
      createCourseOrderRequest,
    );
  },

  updateCourseOrder: async (id: number, createOrderRequest: CreateCourseOrderRequest) => {
    return await HttpClient.put<BaseResponseType<CourseOrderResult>>(
      `/api/CourseOrder/UpdateCourseOrder/${id}`,
      createOrderRequest,
    );
  },

  getAllCourseOrders: async (request: DataGridRequest) => {
    return await HttpClient.post<BaseResponseType<PagedResult<CourseOrderLocalResult>>>(
      "/api/CourseOrder/GetAllCourseOrders",
      request,
    );
  },

  getCourseOrderById: async (id: number) => {
    return await HttpClient.get<BaseResponseType<CourseOrderLocalItemResult>>(
      `/api/CourseOrder/GetCourseOrderById/${id}`,
    );
  },

  deleteCourseOrder: async (id: number) => {
    return await HttpClient.delete<BaseResponseType<CourseOrderResult>>(`/api/CourseOrder/DeleteCourseOrder/${id}`);
  },

  exportCourseOrdersToExcel: async (request: DataGridRequest) => {
    return await HttpClient.post<FileDownload>("/api/CourseOrder/ExportCourseOrdersToExcel", request, {
      responseType: "blob",
    });
  },

  getAllMyCourseOrder: async (request: DataGridRequest) => {
    return await HttpClient.post<BaseResponseType<PagedResult<CourseOrderLocalResult>>>(
      "/api/CourseOrder/GetAllMyCourseOrder",
      request,
    );
  },

  getAllMyCourseOrderPublishingStatus: async (request: DataGridRequest) => {
    return await HttpClient.post<BaseResponseType<PagedResult<CourseOrderLocalResult>>>(
      "/api/CourseOrder/GetAllMyCourseOrderPublishingStatus",
      request,
    );
  },

  exportMyCourseOrderToExcel: async (request: DataGridRequest) => {
    return await HttpClient.post<FileDownload>("/api/CourseOrder/ExportMyCourseOrderToExcel", request, {
      responseType: "blob",
    });
  },

  exportMyCourseOrderPublishingStatusToExcel: async (request: DataGridRequest) => {
    return await HttpClient.post<FileDownload>("/api/CourseOrder/ExportMyCourseOrderPublishingStatusToExcel", request, {
      responseType: "blob",
    });
  },

  getManagementCentersDropdown: async (request: DataGridRequest) => {
    return await HttpClient.post<BaseResponseType<PagedResult<CentreManagementLocalResult>>>(
      `/api/CourseOrder/GetManagementCentersDropdown`,
      request,
    );
  },
};
