import AttachmentCard from "components/blocks/AttachmentCard";
import FilePreview from "components/blocks/FileInput/FilePreview";
import Grid from "components/GridView/components/Grid";
import { GridSchema } from "components/GridView/GridView.types";
import RichTextEditor from "components/RichTextEditor";
import { useCookieContext } from "contexts";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CourseLocalItemResult } from "services/courseService/models/CourseType";
import { FileService } from "services/fileService";

const AboutCourseTab = ({ course }: { course?: CourseLocalItemResult | null }) => {
  const { t } = useTranslation("Courses");
  const { isAr } = useCookieContext();

  const courseDetails = course?.courseDetails?.find((x) => {
    return x.locale === (isAr ? "Ar" : "En");
  });

  const generatePdf = (content: string, fileName?: string) => async () => {
    if (!content) return;
    await FileService.htmlToPdf(content, `${fileName ?? "template"}.pdf`);
  };

  const financingGridSchema = useMemo<GridSchema[]>(
    () => [
      {
        displayName: t("rewardType"),
        field: "-",
        showOnMobile: true,
        render: (item: CourseLocalItemResult["bonusCalculationTerms"][0], i) =>
          item.bonusType.bonusTypesDetail.displayName,
      },
      {
        displayName: t("calculationMethod"),
        field: "-",
        render: (item: CourseLocalItemResult["bonusCalculationTerms"][0]) => item.notes,
      },
      {
        displayName: t("amount"),
        field: "-",
        showOnMobile: true,
        render: (item: CourseLocalItemResult["bonusCalculationTerms"][0]) =>
          `${item.fromValue} - ${item.toValue} ${t("Common:riyal")}`,
      },
    ],
    [],
  );

  return (
    <div className="d-flex flex-column gap-4">
      <div>
        <h6 className="fw-bold mb-4">{t("courseDescription")}</h6>
        <p className="px-2">{courseDetails?.description}</p>
      </div>
      <div className="d-flex flex-column gap-2">
        <h6 className="fw-bold mb-0">{t("publishingConditions")}</h6>
        <RichTextEditor initialData={courseDetails?.ruleAndConditionTemplate ?? ""} isReadOnly onChange={() => {}} />
        <FilePreview
          wrapperClassName="align-self-start"
          title={courseDetails?.ruleAndConditionName}
          callbackValue="1"
          onShow={generatePdf(courseDetails?.ruleAndConditionTemplate ?? "", courseDetails?.ruleAndConditionName)}
          hideDeleteButton
        />
      </div>
      <div className="d-flex flex-column gap-2">
        <h6 className="fw-bold mb-0">{t("commitment")}</h6>
        <RichTextEditor initialData={courseDetails?.commitmentTemplate ?? ""} isReadOnly onChange={() => {}} />
        <FilePreview
          wrapperClassName="align-self-start"
          title={courseDetails?.commitmentName}
          callbackValue="2"
          onShow={generatePdf(courseDetails?.commitmentTemplate ?? "", courseDetails?.commitmentName)}
          hideDeleteButton
        />
      </div>

      <div className="divider" />

      <div>
        <h6 className="fw-bold mb-3">{t("Common:attachments")}</h6>
        <div className="d-flex gap-4">
          {course?.files?.map((file) => (
            <AttachmentCard
              key={file.id}
              attachment={{
                extension: file.extension ?? "",
                fileName: file.name ?? "",
                id: file.id,
                size: file.size ?? "",
              }}
            />
          ))}
        </div>
      </div>

      <div className="divider" />

      <div>
        <h6 className="fw-bold mb-3">{t("rewardCalculationTerms")}</h6>
        <Grid data={course?.bonusCalculationTerms ?? []} gridSchema={financingGridSchema} />
      </div>
    </div>
  );
};

export default AboutCourseTab;
