import { AxiosResponse } from "axios";
import { HttpClient } from "helpers/http/httpClient";
import { BaseResponseType, FileUploadResponse, UploadUrlRequest } from "services/shared-models/sharedTypes";

export const FileService = {
  uploadFile: async (file: File) => {
    const formData = new FormData();
    formData.append("file", file);
    return HttpClient.post<BaseResponseType<FileUploadResponse>>("/api/FileManager/FileUpload", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  downloadFile: async (id: string) => {
    const response = await HttpClient.get<AxiosResponse<Blob>>(`/api/FileManager/DownloadFile/${id}`, {
      responseType: "blob",
    });

    let fileName = response.headers["content-disposition"].split("filename=")[1].split(";")[0].replaceAll('"', "");
    if (!fileName.includes(".")) {
      const ext = response.headers["content-type"].split("/")[1];
      fileName += `.${ext}`;
    }

    const blob = new Blob([response.data], { type: response.headers["content-type"] });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName; // Specify the file name with the appropriate extension
    document.body.appendChild(link);
    link.click();
    // Cleanup
    link.remove();
  },
  getFileById: async (id: string) => {
    return await HttpClient.get<AxiosResponse<Blob>>(`/api/FileManager/DownloadFile/${id}`, {
      responseType: "blob",
    });
  },
  uploadByUrlAsync: async (req: UploadUrlRequest) => {
    return HttpClient.post<BaseResponseType<FileUploadResponse>>("/api/FileManager/UploadByUrlAsync", req);
  },
  htmlToPdf: async (htmlContent: string, fileName: string) => {
    const response = await HttpClient.post<AxiosResponse<Blob>>(
      "/api/FileManager/HtmlToPdf",
      {
        htmlContent,
        fileName,
      },
      {
        responseType: "blob",
      },
    );

    if (!fileName.includes(".")) {
      const ext = response.headers["content-type"].split("/")[1];
      fileName += `.${ext}`;
    }

    const blob = new Blob([response.data], { type: response.headers["content-type"] });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName; // Specify the file name with the appropriate extension
    document.body.appendChild(link);
    link.click();
    // Cleanup
    link.remove();
  },
};
