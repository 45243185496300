import { zodResolver } from "@hookform/resolvers/zod";
import { CreateCourseOrderRequest } from "services/courseOrder/models/CourseOrderType";
import { z } from "zod";

export type CreateCourseOrderRequestFormProps = CreateCourseOrderRequest & {
  isFormValueReady?: boolean;
};

export const getCreateOrderSchema = (t: any) => {
  const schema = z.object({
    courseId: z.number(),
    appliedProcessInstanceId: z.number().optional(),
    formValue: z.string().optional(),
    centreManagementId: z.number().min(1, t("Common:invalidValue")),
    scientificSpecializationId: z.number().min(1, t("Common:invalidValue")),
    languageOfScientificId: z.number().min(1, t("Common:invalidValue")),
    typeOfScientificId: z.number().min(1, t("Common:invalidValue")),
    scientificClassificationId: z.number().min(1, t("Common:invalidValue")),
    numberOfPages: z.number().min(1, t("Common:invalidValue")),
    acceptTermsAndCondition: z.boolean().refine((value) => value, { message: t("Common:invalidValue") }),
    acceptAmendments: z.boolean().refine((value) => value, { message: t("Common:invalidValue") }),
    researcherAttachmentIds: z.string().array(),
    researcherAttachmentWithoutInfoIds: z.string().array(),
    researcherNotes: z.string().optional(),
    isDraft: z.boolean(),
    serialGuid: z.string().optional().nullable(),
    actionName: z.string().optional().nullable(),
    isFormValueReady: z
      .boolean({ message: t("pleaseFillAllRequiredFields") })
      .refine((value) => value === true, { message: t("pleaseFillAllRequiredFields") }),
  }) as z.ZodType<CreateCourseOrderRequestFormProps>;

  return zodResolver(schema);
};
