import { DropdownItemType } from "components/blocks/Dropdown";
import { useState, useEffect } from "react";
import { UseFormSetError, UseFormSetValue } from "react-hook-form";
import { CreateCourseRequest } from "services/courseService/models/CourseType";
import { TemplateService } from "services/templatesService";

const useTemplate = ({
  setValue,
  setError,
}: {
  setValue: UseFormSetValue<CreateCourseRequest>;
  setError: UseFormSetError<CreateCourseRequest>;
}) => {
  const [conditionAndRulesIds, setConditionAndRulesIds] = useState({
    ruleId: 0,
    commitmentId: 0,
  });
  const [isCommitmentTemplateLoading, setIsCommitmentTemplateLoading] = useState(false);
  const [isConditionAndRulesTemplateLoading, setIsConditionAndRulesTemplateLoading] = useState(false);
  const [editorModalProps, setEditorModalProps] = useState<{
    id: number | string;
    isOpen: boolean;
    target?: "rule" | "commitment";
  }>({
    id: 0,
    isOpen: false,
    target: undefined,
  });

  const onEditorContentSave = (content: { ar: string; en: string }, target: "rule" | "commitment") => {
    if (target === "rule") {
      setValue("courseDetails.0.ruleAndConditionTemplate", content.ar);
      setValue("courseDetails.1.ruleAndConditionTemplate", content.en);
    } else if (target === "commitment") {
      setValue("courseDetails.0.commitmentTemplate", content.ar);
      setValue("courseDetails.1.commitmentTemplate", content.en);
    }
  };

  const setEditorModalVisibleState = (isOpen: boolean, target?: "rule" | "commitment") => {
    setEditorModalProps((pre) => ({ ...pre, target: target ?? pre.target, isOpen }));
  };

  const onConditionsAndRulesDropdownChange = (item: DropdownItemType, target?: "rule" | "commitment") => {
    setEditorModalProps({ isOpen: false, id: item.id, target });
    setConditionAndRulesIds((prev) => ({
      ruleId: target === "rule" ? +item.id : prev.ruleId,
      commitmentId: target === "commitment" ? +item.id : prev.commitmentId,
    }));

    setError(`courseDetails.0.${target === "rule" ? "ruleAndConditionName" : "commitmentName"}`, {
      type: "manual",
      message: "",
    });
  };

  const fetchCommitmentsTemplate = async () => {
    setIsCommitmentTemplateLoading(true);
    const response = await TemplateService.getPdfTemplateById(+editorModalProps.id);

    const arData = response.data.pdfTemplateDetails.find((x) => x.locale === "Ar");
    const enData = response.data.pdfTemplateDetails.find((x) => x.locale === "En");
    const content = {
      ar: arData?.templateContent ?? "",
      en: enData?.templateContent ?? "",
    };

    setValue("courseDetails.0.commitmentName", arData?.displayName ?? "test");
    setValue("courseDetails.1.commitmentName", enData?.displayName ?? "test");

    onEditorContentSave(content, "commitment");
    setEditorModalProps((prev) => ({
      ...prev,
      content,
    }));

    setIsCommitmentTemplateLoading(false);
  };

  const fetchConditionsAndRulesTemplate = async () => {
    setIsConditionAndRulesTemplateLoading(true);
    const response = await TemplateService.getConditionAndRuleById(+editorModalProps.id);

    const arData = response.data.conditionAndRuleDetails.find((x) => x.locale === "Ar");
    const enData = response.data.conditionAndRuleDetails.find((x) => x.locale === "En");
    const content = {
      ar: arData?.templateContent ?? "",
      en: enData?.templateContent ?? "",
    };

    setValue("courseDetails.0.ruleAndConditionName", arData?.displayName ?? "");
    setValue("courseDetails.1.ruleAndConditionName", enData?.displayName ?? "");

    onEditorContentSave(content, "rule");
    setEditorModalProps((prev) => ({
      ...prev,
      content,
    }));

    setIsConditionAndRulesTemplateLoading(false);
  };

  useEffect(() => {
    if (editorModalProps.id && editorModalProps.target && !editorModalProps.isOpen) {
      if (editorModalProps.target === "rule") {
        fetchConditionsAndRulesTemplate();
      } else if (editorModalProps.target === "commitment") {
        fetchCommitmentsTemplate();
      }
    }
  }, [editorModalProps.id, editorModalProps.target]);

  return {
    conditionAndRulesIds,
    isCommitmentTemplateLoading,
    isConditionAndRulesTemplateLoading,
    editorModalProps,
    setEditorModalVisibleState,
    onConditionsAndRulesDropdownChange,
    onEditorContentSave,
  };
};

export default useTemplate;
