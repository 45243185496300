import IconButton from "components/blocks/IconButton";
import Switch from "components/blocks/Switch";
import PageWithTabs from "components/PageWithTabs";
import useSystemDateTheme from "hooks/useSystemDateTheme";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import OrdersTab from "./tabs/OrdersTab";
import ApplicantsTab from "./tabs/ApplicantsTab";
import AboutCourseTab from "./tabs/AboutCourseTab";
import { CourseStatus } from "services/courseService/models/CourseType";
import { DeleteButton } from "./DeleteButton";
import useCourseView from "./useCourseView";

type TapsIds = "about" | "orders" | "applicants";
const tabsIds: { [key in TapsIds]: TapsIds } = {
  about: "about",
  orders: "orders",
  applicants: "applicants",
};

const CourseView: React.FC = () => {
  const { t, i18n } = useTranslation("Courses");
  const navigate = useNavigate();
  const { toSystemThemeDateFormat } = useSystemDateTheme();
  const { courseData, courseImage, imageLoading } = useCourseView();

  const subTitle = courseData ? `${t("courseNumber")}: ${courseData?.id} • ${t("thecourse")}: ${courseData.id}` : "";

  return (
    <PageWithTabs
      titleImgSrc={courseImage}
      title={
        <div className="d-flex gap-2">
          {courseData?.courseDetails.find((x) => x.locale.toLowerCase() === i18n.language.toLowerCase())?.displayName}
          <Switch checked={courseData?.status === CourseStatus.Completed.label} onChange={() => {}} />
        </div>
      }
      loading={imageLoading}
      subTitle={subTitle}
      defaultTabId="about"
      pageHeaders={
        !courseData
          ? []
          : [
              {
                title: t("courseStartDate"),
                value: toSystemThemeDateFormat(courseData.startDate),
              },
              {
                title: t("courseEndDate"),
                value: toSystemThemeDateFormat(courseData.applyCloseDate),
              },
              {
                title: t("applicantsCount"),
                value: courseData.applicantsCount,
              },
            ]
      }
      actions={() => {
        return (
          <>
            <IconButton variant="light" icon="icon-share" />
            <IconButton
              variant="light"
              icon="icon-edit"
              onClick={() => {
                navigate(`/home/courses/form?id=${courseData?.id}`);
              }}
            />
            <DeleteButton course={courseData} />
          </>
        );
      }}
      tabs={[
        {
          id: tabsIds.about,
          title: t("aboutCourse"),
          content: courseData ? <AboutCourseTab course={courseData} /> : null,
        },
        {
          id: tabsIds.orders,
          title: t("orders"),
          content: courseData ? <OrdersTab courseId={courseData.id} /> : null,
        },
        {
          id: tabsIds.applicants,
          title: t("applicants"),
          content: courseData ? <ApplicantsTab courseId={courseData.id} /> : null,
        },
      ]}
    />
  );
};

export default CourseView;
