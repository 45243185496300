import { PagedResult } from "./../shared-models/sharedTypes";
import { HttpClient } from "helpers";
import { BaseResponseType } from "services/shared-models/sharedTypes";
import { CourseOrderSpeechesResult, CreateCourseSpeechesRequest } from "./Model/CourseSpeechesTypes";
import { AxiosResponse } from "axios";

export const CourseSpeechesService = {
  createCourseSpeeches: async (createCourseSpeechesRequest: CreateCourseSpeechesRequest) => {
    return await HttpClient.post<BaseResponseType<CourseOrderSpeechesResult>>(
      `/api/CourseSpeeches/CreateCourseSpeeches`,
      createCourseSpeechesRequest,
    );
  },

  getAllCourseSpeechesByCourseOrderId: async (courseOrderId: number) => {
    return await HttpClient.get<BaseResponseType<PagedResult<CourseOrderSpeechesResult>>>(
      `/api/CourseSpeeches/GetAllCourseSpeechesByCourseOrderId`,
      {
        params: {
          courseOrderId,
        },
      },
    );
  },

  deleteCourseSpeech: async (id: number) => {
    return await HttpClient.delete<BaseResponseType<CreateCourseSpeechesRequest>>(
      `/api/CourseSpeeches/DeleteCourseSpeech`,
      {
        params: {
          id,
        },
      },
    );
  },

  downloadDoc: async (courseSpeechId: number) => {
    return await HttpClient.get<AxiosResponse<Blob, any>>(`/api/CourseSpeeches/DownloadDoc/${courseSpeechId}`, {
      responseType: "blob",
    });
  },

  downloadPdf: async (courseSpeechId: number) => {
    return await HttpClient.get<AxiosResponse<Blob, any>>(`/api/CourseSpeeches/DownloadPdf/${courseSpeechId}`, {
      responseType: "blob",
    });
  },
};
