import Accordion from "components/blocks/Accordion";
import AccordionItem from "components/blocks/Accordion/AccordionItem";
import DatePicker from "components/blocks/DatePicker";
import FilePreview from "components/blocks/FileInput/FilePreview";
import FileUploadWithPreview from "components/blocks/FileUploadWithPreview";
import FormInput from "components/blocks/FormInput";
import IconButton from "components/blocks/IconButton";
import LoadingOverlay from "components/blocks/LoadingOverlay";
import Switch from "components/blocks/Switch";
import { useCookieContext } from "contexts";
import { useBreadcrumb } from "contexts/breadcrumb/BreadcrumbContext";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CourseStatus } from "services/courseService/models/CourseType";
import LookupDropdown from "components/blocks/LookupDropdown";
import CourseTemplateEditorModal from "./components/CourseTemplateEditorModal";
import useCourseForm from "./useCourseForm";
import RewardCalculationItemCreation from "./components/RewardCalculationItemCreation";
import RewardCalculationItem from "./components/RewardCalculationItem";

const CourseForm = () => {
  // Hooks
  const { t } = useTranslation("Courses");
  const navigate = useNavigate();
  const { isRTL } = useCookieContext();
  const { setBreadcrumbs } = useBreadcrumb();

  const {
    errors,
    onSubmit,
    register,
    watch,
    setValue,
    conditionAndRulesIds,
    isConditionAndRulesTemplateLoading,
    setEditorModalVisibleState,
    onConditionsAndRulesDropdownChange,
    editorModalProps,
    isCommitmentTemplateLoading,
    onEditorContentSave,
    files,
    isSubmitting,
    isCourseLoading,
    setFiles,
    setImageFile,
    courseId,
    imageFile,
  } = useCourseForm();

  // EFFECTS
  useEffect(() => {
    setBreadcrumbs([
      {
        localizationKey: "allCourses",
        path: "/home/courses",
        menuItemId: "allCourses",
      },
      {
        localizationKey: courseId ? "editCourse" : "addCourse",
        path: `/home/courses/form${courseId ? `?id=${courseId}` : ""}`,
      },
    ]);
  }, []);

  return (
    <form className="position-relative" noValidate onSubmit={onSubmit}>
      <LoadingOverlay visible={isCourseLoading} className="position-fixed" />

      {/*** Form Page Header - Start ***/}
      <div className="d-flex flex-wrap justify-content-between align-items-center mb-3">
        <div className="d-flex gap-2 align-items-center">
          <IconButton
            icon={isRTL ? "icon-full-arrow-right" : "icon-full-arrow-left"}
            innerIconColor="black"
            size="lg"
            className="text-dark"
            onClick={() => navigate(-1)}
          />
          <div className="d-flex gap-3 align-items-start">
            <div>
              <h4 className="text-dark mb-0">{courseId ? t("editCourse") : t("addCourse")}</h4>
              {courseId && <span>{watch("serialNumber")}</span>}
            </div>
            <Switch
              className="mt-1"
              checked={watch("status") === CourseStatus.Completed.label}
              onChange={(checked) => {
                setValue("status", checked ? CourseStatus.Completed.label : CourseStatus.Draft.label);
              }}
            />
          </div>
        </div>
        <div className="d-flex gap-2">
          <button className="btn btn-primary px-3" type="submit" disabled={isSubmitting}>
            {isSubmitting && (
              <span className="spinner-border spinner-border-sm mx-2" role="status" aria-hidden="true" />
            )}
            {courseId ? t("editCourse") : t("addCourse")}
          </button>
        </div>
      </div>
      {/*** Form Page Header - End ***/}

      <div className="divider" />

      {/*** Course Main Info - Start ***/}
      <Accordion negatePadding>
        <AccordionItem initiallyOpen title={t("mainInfo")} elementId={1}>
          <div className="d-flex flex-column gap-2">
            <FormInput
              label={t("courseNameAr")}
              {...register("courseDetails.0.displayName", { required: t("required") })}
              error={errors.courseDetails?.[0]?.displayName?.message}
            />
            <FormInput
              label={t("courseDescriptionAr")}
              {...register("courseDetails.0.description", { required: t("required") })}
              error={errors.courseDetails?.[0]?.description?.message}
            />
            <FormInput
              label={t("courseNameEn")}
              {...register("courseDetails.1.displayName", { required: t("required") })}
              error={errors.courseDetails?.[1]?.displayName?.message}
            />
            <FormInput
              label={t("courseDescriptionEn")}
              {...register("courseDetails.1.description", { required: t("required") })}
              error={errors.courseDetails?.[1]?.description?.message}
            />
          </div>
        </AccordionItem>
      </Accordion>
      {/*** Course Main Info - End ***/}

      <div className="divider" />

      {/*** About Course - Start ***/}
      <Accordion negatePadding>
        <AccordionItem initiallyOpen title={t("aboutCourse")} elementId={2}>
          <div className="row">
            <LookupDropdown
              wrapperClassName="col-md-3 col-sm-6 mb-2"
              label={t("year")}
              service="lookupService"
              endpoint="getAllProgramCycleYears"
              changeEffect={() => {
                setValue("programCycleId", 0);
              }}
              noSelectionPlaceholder={t("year")}
              onChange={(value) => {
                setValue("cycleYear", +value);
              }}
              value={watch("cycleYear")}
              textValueKey="programCycleYearDetail.displayName"
              idValueKey="id"
              error={t(errors.cycleYear?.message ?? "")}
              useReactSelect
            />
            <LookupDropdown
              wrapperClassName="col-md-3 col-sm-6 mb-2"
              label={t("courseNumber")}
              service="lookupService"
              endpoint="getAllProgramCycleByYear"
              query={{ year: watch("cycleYear") }}
              onChange={(value) => {
                setValue("programCycleId", +value);
              }}
              value={watch("programCycleId")}
              textValueKey="programCycleDetail.displayName"
              idValueKey="id"
              disabled={!watch("cycleYear")}
              disableApi={!watch("cycleYear")}
              error={t(errors.programCycleId?.message ?? "")}
              useReactSelect
            />
            <DatePicker
              label={t("startDate")}
              wrapperClassName="col-sm-6 col-md-3"
              value={watch("startDate")}
              onChange={(date) => setValue("startDate", date ?? new Date())}
              error={errors.startDate?.message}
            />
            <DatePicker
              label={t("closeDate")}
              wrapperClassName="col-sm-6 col-md-3"
              value={watch("applyCloseDate")}
              onChange={(date) => setValue("applyCloseDate", date ?? new Date())}
              error={errors.applyCloseDate?.message}
            />
          </div>
        </AccordionItem>
      </Accordion>
      {/*** About Course - End ***/}

      <div className="divider" />

      {/*** Rules/Conditions - Start ***/}
      <Accordion negatePadding>
        <AccordionItem initiallyOpen title={t("conditions")} elementId={3}>
          <LookupDropdown
            wrapperClassName="col-md-3 col-sm-6 mb-2 w-100 mb-2"
            label={t("publishingConditions")}
            service="templateService"
            endpoint="getAllConditionsAndRules"
            onChange={(value, item) => {
              onConditionsAndRulesDropdownChange(item, "rule");
            }}
            value={conditionAndRulesIds.ruleId}
            textValueKey="conditionAndRuleDetail.displayName"
            idValueKey="id"
            isPaginated
            error={t(errors.courseDetails?.[0]?.ruleAndConditionName?.message ?? "")}
          />

          <FilePreview
            onShow={() => setEditorModalVisibleState(true, "rule")}
            title={watch("courseDetails.0.ruleAndConditionName")}
            showButtonLabel={t("openAndEdit")}
            callbackValue="preview"
            loading={isConditionAndRulesTemplateLoading}
            disabled={!watch("courseDetails.0.ruleAndConditionTemplate")}
          />

          <LookupDropdown
            wrapperClassName="col-md-3 col-sm-6 mb-2 w-100 mb-2 mt-4"
            label={t("commitment")}
            service="templateService"
            endpoint="getAllPdfTemplates"
            onChange={(value, item) => {
              onConditionsAndRulesDropdownChange(item, "commitment");
            }}
            value={conditionAndRulesIds.commitmentId}
            textValueKey="pdfTemplateDetail.displayName"
            idValueKey="id"
            isPaginated
            error={t(errors.courseDetails?.[0]?.commitmentName?.message ?? "")}
          />

          <FilePreview
            onShow={() => setEditorModalVisibleState(true, "commitment")}
            title={watch("courseDetails.0.commitmentName")}
            showButtonLabel={t("openAndEdit")}
            callbackValue="preview"
            loading={isCommitmentTemplateLoading}
            disabled={!watch("courseDetails.0.commitmentTemplate")}
          />
        </AccordionItem>
      </Accordion>
      {/*** Rules - End ***/}

      <div className="divider" />

      {/*** Others - Start ***/}
      <Accordion negatePadding>
        <AccordionItem initiallyOpen title={t("others")} elementId={4}>
          <FileUploadWithPreview
            files={files}
            setFiles={setFiles}
            onChange={(files) => {
              setValue(
                "filesIds",
                files.map((x) => x.uuid),
              );
            }}
          />
          <div className="divider" />
          <FileUploadWithPreview
            label={t("courseImage")}
            clickHereForLabel={t("toAttachCourseImage")}
            files={imageFile ? [imageFile] : []}
            setFiles={(files) => {
              setImageFile(files[0]);
              setValue("courseImageId", files[0].uuid);
            }}
            allowedFileTypes="image/*"
            maxNumberOfFiles={1}
          />
        </AccordionItem>
      </Accordion>
      {/*** Others - End ***/}

      <div className="divider" />

      {/*** Reward calculation rules/conditions - Start ***/}
      <Accordion negatePadding>
        <AccordionItem initiallyOpen title={t("rewardCalculationConditions")} elementId={5}>
          {watch("bonusCalculationTerms")?.map((item, index) => (
            <RewardCalculationItem
              key={index}
              onDelete={(index) => {
                setValue(
                  "bonusCalculationTerms",
                  watch("bonusCalculationTerms").filter((x, i) => i !== index),
                );
              }}
              setValue={setValue}
              errors={errors}
              watch={watch}
              index={index}
            />
          ))}

          <RewardCalculationItemCreation
            onAdd={(item) => {
              setValue("bonusCalculationTerms", [...watch("bonusCalculationTerms"), item]);
            }}
          />
        </AccordionItem>
      </Accordion>
      {/*** Reward calculation conditions - End ***/}

      {editorModalProps.isOpen && !!editorModalProps.target && (
        <CourseTemplateEditorModal
          isOpen
          fieldName={editorModalProps.target === "rule" ? "ruleAndConditionTemplate" : "commitmentTemplate"}
          content={watch("courseDetails")}
          onClose={() => {
            setEditorModalVisibleState(false);
          }}
          onSave={(content) => onEditorContentSave(content, editorModalProps.target!)}
        />
      )}
    </form>
  );
};

export default CourseForm;
