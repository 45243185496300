import { useForm } from "react-hook-form";
import { CreateCourseRequest, CourseStatus } from "services/courseService/models/CourseType";
import useTemplate from "./useTemplate";
import useMutation from "hooks/useMutation";
import { CourseService } from "services/courseService";
import { CreateCourseRequestFormProps, getCreateCourseSchema } from "./createCourse.schema";
import { useTranslation } from "react-i18next";
import { useQuery } from "hooks/useQuery";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { SelectedFilesType } from "components/blocks/FileUploadWithPreview";

const useCourseForm = () => {
  const { t, i18n } = useTranslation("Courses");
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const courseId = params.get("id");

  // STATES
  const [files, setFiles] = useState<SelectedFilesType[]>([]);
  const [imageFile, setImageFile] = useState<SelectedFilesType>();
  const {
    formState: { errors },
    handleSubmit,
    register,
    watch,
    getValues,
    setValue,
    reset,
    setError,
  } = useForm<CreateCourseRequestFormProps>({
    defaultValues: {
      applyCloseDate: new Date(),
      startDate: new Date(),
      status: CourseStatus.Draft.label,
      bonusCalculationTerms: [],
      courseDetails: [
        {
          locale: "Ar",
          ruleAndConditionTemplate: "",
          commitmentTemplate: "",
          ruleAndConditionName: "",
          commitmentName: "",
        },
        {
          locale: "En",
          ruleAndConditionTemplate: "",
          commitmentTemplate: "",
          ruleAndConditionName: "",
          commitmentName: "",
        },
      ],
      courseImageId: "",
      filesIds: [],
      programCycleId: 0,
    },
    resolver: getCreateCourseSchema(t),
  });

  const {
    conditionAndRulesIds,
    isConditionAndRulesTemplateLoading,
    setEditorModalVisibleState,
    onConditionsAndRulesDropdownChange,
    editorModalProps,
    isCommitmentTemplateLoading,
    onEditorContentSave,
  } = useTemplate({ setValue, setError });

  const { mutateAsync, loading: isCreatingCourse } = useMutation({
    queryFn: async (data: CreateCourseRequest) => {
      return courseId ? await CourseService.updateCourse(+courseId!, data) : await CourseService.createCourse(data);
    },
  });

  const { data: courseData, loading: isCourseLoading } = useQuery({
    queryFn: async () => {
      return await CourseService.getCourseById(courseId ? +courseId : 0);
    },
    options: {
      enabled: !!courseId,
    },
    triggers: [courseId],
  });

  useEffect(() => {
    if (courseData) {
      reset({
        ...courseData,
        bonusCalculationTerms: courseData.bonusCalculationTerms.map((term) => ({
          ...term,
          bonusTypeId: term.bonusType.id,
        })),
        filesIds: courseData.files.map((file) => file.id),
        courseImageId: courseData.courseImage.id,
        programCycleId: courseData.programCycleDetail?.find(
          (x) => x.locale.toLowerCase() === i18n.language.toLowerCase(),
        )?.programCycleId,
        cycleYear: courseData.programCycleYearDetail?.find(
          (x) => x.locale.toLowerCase() === i18n.language.toLowerCase(),
        )?.programCycleYearId,
        startDate: new Date(courseData.startDate),
        applyCloseDate: new Date(courseData.applyCloseDate),
      });

      setFiles(
        courseData.files.map((file) => ({
          file: new File([], file.name ?? ""),
          fileName: file.name ?? "",
          uuid: file.id.toString(),
          size: file.size,
        })),
      );

      setImageFile({
        file: new File([], courseData.courseImage.name ?? ""),
        fileName: courseData.courseImage.name ?? "",
        uuid: courseData.courseImage.id,
        size: courseData.courseImage.size,
      });
    }
  }, [courseData]);

  const onSubmit = async (data: CreateCourseRequest) => {
    const res = await mutateAsync(data);

    if (res && !res.hasError) {
      reset();
      navigate("/home/courses");
    }
  };

  return {
    errors,
    onSubmit: handleSubmit(onSubmit),
    register,
    watch,
    getValues,
    setValue,
    reset,
    setError,
    conditionAndRulesIds,
    isConditionAndRulesTemplateLoading,
    setEditorModalVisibleState,
    onConditionsAndRulesDropdownChange,
    editorModalProps,
    isCommitmentTemplateLoading,
    onEditorContentSave,
    isSubmitting: isCreatingCourse,
    isCourseLoading,
    files,
    setFiles,
    imageFile,
    setImageFile,
    courseId,
  };
};

export default useCourseForm;
