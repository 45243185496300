import Accordion from "components/blocks/Accordion";
import AccordionItem from "components/blocks/Accordion/AccordionItem";
import LoadingOverlay from "components/blocks/LoadingOverlay";
import { useQuery } from "hooks/useQuery";
import { useTranslation } from "react-i18next";
import { CourseOrderLocalItemResult } from "services/courseOrder/models/CourseOrderType";
import { ScientificPublicationsService } from "services/scientificPublicationsService";

const ScientificPublicationLibraries = ({ courseOrder }: { courseOrder: CourseOrderLocalItemResult | null }) => {
  const { t } = useTranslation("Orders");
  const { data: scientificPublication, loading: courseLoading } = useQuery({
    queryFn: async () => {
      // TODO: need Scientific PublicationBy Id
      return await ScientificPublicationsService.getScientificPublicationById(4);
    },
    options: { enabled: !!courseOrder },
    triggers: [courseOrder],
  });

  return (
    <Accordion>
      <AccordionItem initiallyOpen title={t("availableIn")} elementId={"ScientificPublicationLibraries"}>
        <div className="position-relative">
          <LoadingOverlay visible={courseLoading} />
          <div className="d-flex flex-wrap gap-3">
            {scientificPublication?.libraries
              ? scientificPublication?.libraries.map((ele, index) => (
                  <div key={index} className="p-4 bg-white rounded-4 align-items-center shadow">
                    <h6 className="mb-1 fw-bold text-center">{ele.libraryDetail.displayName} </h6>
                    <p className="m-0 text-gray text-center">الموقع</p>
                    {/* TODO: publ location */}
                  </div>
                ))
              : t("Common:noData")}
          </div>
        </div>
      </AccordionItem>
    </Accordion>
  );
};

export default ScientificPublicationLibraries;
