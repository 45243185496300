import { GridSchema } from "components/GridView/GridView.types";
import IconButton from "components/blocks/IconButton";
import { useMemo } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { EXAMINER_TASK_STATUS } from "../ExaminersSection/ExaminersTable";
import Modal from "components/blocks/Modal";
import Grid from "components/GridView/components/Grid";
import { useTranslation } from "react-i18next";
import { ExaminerListResult } from "services/examinersService/Model/ExaminerTypes";

export type ShowAllExaminersModalTableProps = {
  examiners: ExaminerListResult[];
  onViewExaminerEvaluation: (row: ExaminerListResult) => void;
  onClose: () => void;
  isOpen: boolean;
};

// TODO: types and labels and endpoint will change to judges when the backend is ready

const ExaminersTableModal = ({
  examiners,
  onViewExaminerEvaluation,
  onClose,
  isOpen,
}: ShowAllExaminersModalTableProps) => {
  const { t } = useTranslation("Orders");

  const gridSchema = useMemo<GridSchema[]>(
    () => [
      {
        field: "examinerName",
        displayName: t("judgeName"),
        render: (row: ExaminerListResult) => {
          return (
            <span className={row.status === EXAMINER_TASK_STATUS.Cancel.value ? "text-overline" : ""}>
              {row.examinerName}
            </span>
          );
        },
      },
      {
        field: "round",
        displayName: t("round"),
        render: (row: ExaminerListResult) => {
          const isDelayed =
            row.deadLine && row.status !== EXAMINER_TASK_STATUS.Submitted.value
              ? new Date().getTime() > new Date(row.deadLine).getTime()
              : false;

          return (
            <div className="d-flex justify-content-between">
              <span>{row.iteration}</span>
              {row.status === EXAMINER_TASK_STATUS.Pending.value && row.isSentInvitation && (
                <OverlayTrigger placement="top" overlay={<Tooltip>{t("notRegisteredWithSentInvitation")}</Tooltip>}>
                  <i className="icon-help text-primary"></i>
                </OverlayTrigger>
              )}
              {isDelayed && <i className="icon-warning text-danger"></i>}
            </div>
          );
        },
      },
      {
        field: "evaluationPercentage",
        displayName: t("evaluation"),
        render: (row: ExaminerListResult) => {
          return <>{row.evaluationPercentage ? `${row.evaluationPercentage}%` : "-"}</>;
        },
      },
      {
        field: "recommendations",
        displayName: t("recommendations"),
        render: (row) => {
          return row.recommendations;
        },
      },
      {
        field: "taskStatus",
        displayName: t("taskStatus"),
        render: (row: ExaminerListResult) => {
          const status = EXAMINER_TASK_STATUS[row.status as keyof typeof EXAMINER_TASK_STATUS];

          return row.status ? t(status.key) : "-";
        },
      },
      {
        field: "confirmExaminersResult",
        displayName: t("confirmJudgesResult"),
        render: (row: ExaminerListResult) => {
          return "-";
          /* return !row.accreditationType
            ? "-"
            : row.accreditationType === AccreditationTypes.Accreditation.label
            ? t("approved")
            : t("notApproved"); */
        },
      },
      {
        field: "reward",
        displayName: t("reward"),
        render: (row: ExaminerListResult) => {
          return "-";
          /* const acceptanceText = !row.accreditationType
            ? "-"
            : row.accreditationType === AccreditationTypes.Accreditation.label
            ? t("approved")
            : t("notApproved");
          const rewardedText = row.isConfirmFees ? t("paid") : t("notPaid");

          return EXAMINER_TASK_STATUS.Evaluated.value === row.status ? `${acceptanceText} - ${rewardedText}` : "-"; */
        },
      },
      {
        field: "actions",
        displayName: t("actions"),
        render: (row: ExaminerListResult) => {
          return row.status === EXAMINER_TASK_STATUS.Evaluated.value ||
            row.status === EXAMINER_TASK_STATUS.Submitted.value ? (
            <IconButton
              icon="icon-view"
              fitToIconWidth
              innerIconColor="primary"
              size="md"
              onClick={() => {
                onViewExaminerEvaluation(row);
              }}
            />
          ) : (
            <></>
          );
        },
      },
    ],
    [t],
  );

  return (
    <Modal
      title={t("evaluations")}
      isOpen={isOpen}
      onClose={onClose}
      containerClassName="bg-white"
      size="xl"
      titleClassName="fs-6"
      bodyMargin="p-0 m-0"
    >
      <div className="mt-4">
        <Grid data={examiners} gridSchema={gridSchema} />
      </div>
    </Modal>
  );
};

export default ExaminersTableModal;
