import useMutation from "hooks/useMutation";
import { useNotification } from "hooks/useNotification";
import { BaseSyntheticEvent, useImperativeHandle, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { OrdersService } from "services/ordersService";
import { OrderResult } from "services/ordersService/models/ordersTypes";
import { ProgramOrderUpdateService } from "services/programOrderUpdate";
import { EvaluateProgramOrderUpdateRequest, ProgramOrderUpdateResult } from "services/programOrderUpdate/models";
import { BaseResponseType } from "services/shared-models/sharedTypes";

export type ResponseTypesOfProgramOrderUpdate =
  //! Update  Request Actions
  | "RejectUpdateRequest"
  | "ApproveUpdateRequest"
  | "ReturnUpdateRequest"
  | "ResearcherSendProgramOrderUpdateAfterReturn"

  //! Apologies Request Actions
  | "RejectApologiesRequest"
  | "ApproveApologiesRequest"
  | "ReturnApologiesRequest"
  | "RejectApologiesRequestByDeanOfScientificResearch"
  | "ApproveApologiesRequestByDeanOfScientificResearch"
  | "ReturnApologiesRequestByDeanOfScientificResearch"
  | "ResearcherSendApologiesAfterReturn"

  //! Extend Request Actions
  | "RejectExtendRequest"
  | "ApproveExtendRequest"
  | "ReturnExtendRequest"
  | "EditExtendDurationRequest"
  | "ResearcherSendExtendAfterReturn"
  | "ReturnExtendRequestByDeanOfScientificResearch"

  //! Query Request Actions
  | "CenterManagerSendQueryResponse"
  | "ResearcherSendQueryResponse"

  //! ProgramOrderUpdate Actions
  | "ReturnProgramOrderUpdate"
  | "RejectProgramOrderUpdate";

export type EvaluateProgramUpdateRef = {
  acceptProgramOrderUpdate: (serialGuid: string) => Promise<BaseResponseType<ProgramOrderUpdateResult>>;
  sendProgramOrderUpdateResponse: (props: {
    note: string;
    serialGuid: string;
    response: ResponseTypesOfProgramOrderUpdate;
    fileIds: string[];
  }) => Promise<BaseResponseType<OrderResult | boolean>>;
  editProgramOrderUpdate: () => Promise<void>;
};

const useEvaluateResearcherUpdate = ({
  programOrderUpdateId,
  programUpdateHandlersRef: evaluateProgramUpdateRef,
  orderId,
  onEditProgramOrderUpdate,
}: {
  orderId: number;
  programOrderUpdateId: number;
  programUpdateHandlersRef: React.MutableRefObject<EvaluateProgramUpdateRef | null>;
  onEditProgramOrderUpdate: (e?: BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
}) => {
  const { showNotification } = useNotification();
  const { t } = useTranslation("Orders");

  const [extendedTime, setExtendedTime] = useState(0);
  const { handleSubmit, setValue, getValues } = useForm<EvaluateProgramOrderUpdateRequest>({
    defaultValues: {
      centerManagerNotes: "",
      evaluationType: 0,
      workFlowTaskRequest: {
        actionName: "",
        notes: "",
        serialGuid: "",
      },
    },
  });

  const checkValidationOnAccept = (values: EvaluateProgramOrderUpdateRequest) => {
    if (values.evaluationType === 0) {
      showNotification({
        description: t("pleaseSelectEvaluationType"),
        type: "error",
      });

      return false;
    }

    return true;
  };

  const { mutateAsync: evaluateProgramOrderUpdate } = useMutation({
    queryFn: async (req: EvaluateProgramOrderUpdateRequest) => {
      return await ProgramOrderUpdateService.evaluateProgramOrderUpdate(programOrderUpdateId, req);
    },
  });

  const onSendProgramUpdateResponse = async (
    req: /* WorkFlowTaskWithFilesRequest */ any,
    responseType: ResponseTypesOfProgramOrderUpdate,
  ) => {
    /*  try { switch (responseType) {
        //! Update Actions
        case "RejectUpdateRequest":
          req.actionName = "Reject";
          return await OrdersService.rejectProgramOrderUpdateRequest(orderId, req);
        case "ApproveUpdateRequest":
          req.actionName = "Approve";
          return await OrdersService.acceptProgramOrderUpdateRequest(orderId, req);
        case "ReturnUpdateRequest":
          req.actionName = "Return to Researcher";
          return await OrdersService.returnOrRejectProgramOrderUpdateRequest(orderId, req);
        case "ResearcherSendProgramOrderUpdateAfterReturn":
          req.actionName = "Complete";
          req.notes = getValues("workFlowTaskRequest.notes");
          return await OrdersService.sendProgramOrderUpdateRequest(orderId, req);

        //! Apologies Actions
        case "ResearcherSendApologiesAfterReturn":
          req.actionName = "Complete";
          req.notes = getValues("workFlowTaskRequest.notes");
          return await OrdersService.sendProgramOrderApologiesRequest(orderId, req);
        case "RejectApologiesRequestByDeanOfScientificResearch":
          req.actionName = "Reject";
          return await OrdersService.rejectProgramOrderApologiesRequest(orderId, req);
        case "ApproveApologiesRequestByDeanOfScientificResearch":
          req.actionName = "Approve";
          return await OrdersService.acceptProgramOrderApologiesRequestByDeanOfScientificResearch(orderId, req);
        case "ReturnApologiesRequestByDeanOfScientificResearch":
          req.actionName = "Return to Center";
          return await OrdersService.returnProgramOrderApologiesRequest(orderId, req);
        case "RejectApologiesRequest":
          req.actionName = "Reject";
          return await OrdersService.rejectProgramOrderApologiesRequest(orderId, req);
        case "ApproveApologiesRequest":
          req.actionName = "Approve";
          return await OrdersService.centralManagerAcceptProgramOrderApologiesRequest(orderId, req);
        case "ReturnApologiesRequest":
          req.actionName = "Return to Researcher";
          return await OrdersService.returnProgramOrderApologiesRequest(orderId, req);

        //! Extend Actions
        case "RejectExtendRequest":
          req.actionName = "Reject";
          return await OrdersService.rejectProgramOrderExtendRequest(orderId, req);
        case "ReturnExtendRequest":
          req.actionName = "Return to Researcher";
          return await OrdersService.returnProgramOrderExtendRequest(orderId, req);
        case "ReturnExtendRequestByDeanOfScientificResearch":
          req.actionName = "Return to Center";
          return await OrdersService.returnProgramOrderExtendRequest(orderId, req);
        case "ResearcherSendExtendAfterReturn":
          return await OrdersService.sentProgramOrderExtendRequest(orderId, {
            workFlowTaskRequest: {
              actionName: "Complete",
              notes: getValues("workFlowTaskRequest.notes"),
              serialGuid: req.serialGuid,
            },
            notes: getValues("workFlowTaskRequest.notes"),
            extendedTime: extendedTime,
          });
        case "ReturnProgramOrderUpdate":
          req.actionName = "Return to Researcher";
          return await ProgramOrderUpdateService.returnOrRejectProgramOrderUpdate(programOrderUpdateId, req);

        //! Query Actions
        case "CenterManagerSendQueryResponse":
          req.actionName = "Complete";
          req.notes = getValues("workFlowTaskRequest.notes");
          return await OrdersService.centerManagerProgramOrderQueryRequest(orderId, req);
        case "ResearcherSendQueryResponse":
          req.actionName = "Noted";
          req.notes = getValues("workFlowTaskRequest.notes");
          return await OrdersService.researcherProgramOrderQueryRequest(orderId, req);
      }

      req.actionName = "Reject";
      return await ProgramOrderUpdateService.returnOrRejectProgramOrderUpdate(programOrderUpdateId, req);
    } catch (error) {
      showNotification({
        description: t("Common:internalServerError"),
        type: "error",
      });} */

    return { hasError: true, data: false, description: t("Common:internalServerError"), code: 500 };
  };

  const onAcceptEvaluation = async (values: EvaluateProgramOrderUpdateRequest, serialGuid: string) => {
    values.workFlowTaskRequest.actionName = "Approve";
    values.workFlowTaskRequest.serialGuid = serialGuid;
    if (!checkValidationOnAccept(values)) return;

    return await evaluateProgramOrderUpdate(values);
  };

  useImperativeHandle(evaluateProgramUpdateRef, () => ({
    acceptProgramOrderUpdate: async (serialGuid: string) => {
      let res: any;
      await handleSubmit(async (values) => {
        res = await onAcceptEvaluation(values, serialGuid);
      })();

      return res;
    },
    sendProgramOrderUpdateResponse: async (props: {
      note: string;
      serialGuid: string;
      response: ResponseTypesOfProgramOrderUpdate;
      fileIds: string[];
    }) => {
      const res = await onSendProgramUpdateResponse(
        {
          actionName: "",
          notes: props.note,
          serialGuid: props.serialGuid,
          filesIds: props.fileIds ?? [],
        },
        props.response,
      );
      return res;
    },

    editProgramOrderUpdate: async () => {
      await onEditProgramOrderUpdate();
    },
  }));

  return {
    setValue,
    setExtendedTime,
    extendedTime,
  };
};

export default useEvaluateResearcherUpdate;
