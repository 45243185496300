import Accordion from "components/blocks/Accordion";
import AccordionItem from "components/blocks/Accordion/AccordionItem";
import FileUploadWithPreview from "components/blocks/FileUploadWithPreview";
import FormInput from "components/blocks/FormInput";
import LoadingOverlay from "components/blocks/LoadingOverlay";
import { useBreadcrumb } from "contexts/breadcrumb/BreadcrumbContext";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import LookupDropdown from "components/blocks/LookupDropdown";
import useOrderForm from "./useOrderForm";
import PersonalInfo from "./components/PersonalInfo";
import RulesAndConditionSection from "./components/RulesAndConditionSection";
import useSystemDateTheme from "hooks/useSystemDateTheme";
import FormViewComponent from "components/formBuilder/FormViewComponent";
import { ScientificClassificationResult } from "services/scientificPublicationsService/models/scientificPublicationsTypes";

const OrderForm = () => {
  // Hooks
  const { toSystemThemeDateFormat } = useSystemDateTheme();
  const { t, i18n } = useTranslation("Orders");
  const { setBreadcrumbs } = useBreadcrumb();

  const {
    errors,
    onSubmit,
    register,
    watch,
    setValue,
    courseData,
    isLoading,
    isSubmitting,
    researcherAttachmentWithoutInfo,
    researcherAttachments,
    isDraftSubmit,
    formSchema,
    setFormSchema,
    setResearcherAttachmentWithoutInfo,
    setResearcherAttachments,
  } = useOrderForm();

  // EFFECTS
  useEffect(() => {
    setBreadcrumbs([
      {
        localizationKey: courseData ? "myAllOrders" : "allCourses",
        path: courseData ? "/home/authorOrders/MyAllOrders" : "/home/courses",
        menuItemId: "allCourses",
      },
      {
        localizationKey: courseData ? "editCourse" : "addCourse",
        path: `/home/courses/${courseData ? `edit/${courseData.id}` : "add"}`,
      },
    ]);
  }, [courseData]);

  const isArabic = i18n.language === "ar";

  return (
    <form className="position-relative" noValidate onSubmit={onSubmit}>
      <LoadingOverlay visible={isLoading} className="position-fixed" />

      {/*** Form Page Header - Start ***/}
      <div className="d-flex gap-2">
        <img
          src={courseData?.courseImageUrl}
          alt="logo"
          width={115}
          className="d-none d-sm-block rounded-4 ms-3"
          style={{ objectFit: "cover", borderRadius: "1rem", marginBottom: "24px" }}
        />
        <div className=" d-flex flex-column justify-content-between gap-3 flex-1">
          <div className="d-flex justify-content-between align-items-start">
            <div className="d-flex gap-2 align-items-center">
              <div>
                <div className="fs-6 fw-bold">
                  {
                    courseData?.courseDetails.find((x) => x.locale.toLowerCase() === i18n.language.toLowerCase())
                      ?.displayName
                  }
                </div>
                <div className=" text-gray">{courseData?.serialNumber}</div>
              </div>
            </div>

            {/* Actions */}
            <div className="d-flex flex-wrap gap-2 pe-4">
              <button
                className="btn btn-primary px-3"
                type="submit"
                disabled={isSubmitting}
                onClick={() => {
                  isDraftSubmit.current = false;
                }}
              >
                {isSubmitting && (
                  <span className="spinner-border spinner-border-sm mx-2" role="status" aria-hidden="true" />
                )}
                {t("submit")}
              </button>
              {watch("isDraft") && (
                <button
                  className="btn btn-secondary px-3"
                  type="submit"
                  disabled={isSubmitting}
                  onClick={() => {
                    isDraftSubmit.current = true;
                  }}
                >
                  {isSubmitting && (
                    <span className="spinner-border spinner-border-sm mx-2" role="status" aria-hidden="true" />
                  )}
                  {t("saveAsDraft")}
                </button>
              )}
              <button className="btn btn-light px-3" disabled={isSubmitting}>
                {t("cancel")}
              </button>
            </div>
          </div>

          {/* Page Headers */}
          <div className={`d-flex flex-wrap  pb-4 pt-2`}>
            <div className="d-flex align-items-center">
              <div>
                <div className="fs-14px text-gray">{t("startDate")}:</div>
                <div className="fs-14px fw-bold">{toSystemThemeDateFormat(courseData?.startDate)}</div>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className="header-circle text-gray mx-3"></div>
              <div>
                <div className="fs-14px text-gray">{t("applyCloseDate")}:</div>
                <div className="fs-14px fw-bold">{toSystemThemeDateFormat(courseData?.applyCloseDate)}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*** Form Page Header - End ***/}

      <div className="divider" />

      <Accordion negatePadding>
        <AccordionItem initiallyOpen title={t("applicantInfo")} elementId={1}>
          <PersonalInfo />
        </AccordionItem>
      </Accordion>

      <div className="divider" />

      <Accordion negatePadding>
        <AccordionItem initiallyOpen title={t("applicationForm")} elementId={2}>
          <div className="d-flex flex-column gap-2">
            <div className="row">
              <LookupDropdown
                wrapperClassName="col-lg-3 col-md-6 col-sm-12 mb-2"
                label={t("scientificSpecializationOfThePublishedMaterial")}
                service="lookupService"
                endpoint="getAllScientificSpecializationDropDown"
                noSelectionPlaceholder={t("scientificSpecializationOfThePublishedMaterial")}
                onChange={(value) => {
                  setValue("scientificSpecializationId", +value);
                }}
                value={watch("scientificSpecializationId")}
                textValueKey={`details.${isArabic ? 0 : 1}.displayName`}
                idValueKey="id"
                error={t(errors.scientificSpecializationId?.message ?? "")}
                useReactSelect
              />
              <LookupDropdown
                wrapperClassName="col-lg-3 col-md-6 col-sm-12 mb-2"
                label={t("languageOfScientificPublishing")}
                service="lookupService"
                endpoint="getAllLanguageOfScientificDropDown"
                onChange={(value) => {
                  setValue("languageOfScientificId", +value);
                }}
                value={watch("languageOfScientificId")}
                textValueKey={`details.${isArabic ? 0 : 1}.displayName`}
                idValueKey="id"
                error={t(errors.languageOfScientificId?.message ?? "")}
                useReactSelect
              />
              <LookupDropdown
                wrapperClassName="col-lg-3 col-md-6 col-sm-12 mb-2"
                label={t("typeOfScientificPublishing")}
                service="lookupService"
                endpoint="getAllTypeOfScientificDropDown"
                onChange={(value) => {
                  setValue("typeOfScientificId", +value);
                }}
                value={watch("typeOfScientificId")}
                textValueKey={`details.${isArabic ? 0 : 1}.displayName`}
                idValueKey="id"
                error={t(errors.typeOfScientificId?.message ?? "")}
                useReactSelect
              />
              <LookupDropdown
                wrapperClassName="col-lg-3 col-md-6 col-sm-12 mb-2"
                label={t("scientificClassification")}
                service="lookupService"
                endpoint="getAllScientificClassificationDropDown"
                onChange={(value, item, dbRes) => {
                  dbRes = dbRes as ScientificClassificationResult[];
                  const selectedItem = dbRes?.find((x) => x.id === +value)?.jsonSchema;

                  setFormSchema(selectedItem ? JSON.parse(selectedItem) : {});
                  setValue("scientificClassificationId", +value);
                  //setValue("formValue", "{}");
                }}
                value={watch("scientificClassificationId")}
                textValueKey={`scientificClassificationDetail.displayName`}
                idValueKey="id"
                error={t(errors.scientificClassificationId?.message ?? "")}
                useReactSelect
              />
            </div>

            {/* TODO: not ready  */}
            {formSchema && (
              <div className="d-flex flex-column">
                <FormViewComponent
                  data={watch("formValue") ? JSON.parse(watch("formValue")!) : {}}
                  formDefinition={formSchema}
                  onChange={(values, isInitialLoad) => {
                    setValue("isFormValueReady", values.isValid);
                    if (!isInitialLoad) {
                      setValue("formValue", JSON.stringify(values.data));
                    }
                  }}
                />
                {errors.isFormValueReady?.message && (
                  <span className="invalid-feedback my-2 d-block">{errors.isFormValueReady?.message}</span>
                )}
              </div>
            )}

            <div className="row">
              <FormInput
                type="text"
                label={t("numberOfPages")}
                wrapperClassName="col-lg-3 col-md-6 col-sm-12 mb-2"
                inputClassName="form-control"
                placeholder={t("numberOfPages")}
                leftElement={<span className="text-primary">{t("page")}</span>}
                error={t(errors.numberOfPages?.message ?? "")}
                {...register("numberOfPages", {
                  valueAsNumber: true,
                })}
              />
              <LookupDropdown
                wrapperClassName="col-lg-3 col-md-6 col-sm-12 mb-2"
                label={t("scientificClassification")}
                service="courseOrderService"
                endpoint="getManagementCentersDropdown"
                onChange={(value) => {
                  setValue("centreManagementId", +value);
                }}
                value={watch("centreManagementId")}
                textValueKey="centreManagementDetail.displayName"
                idValueKey="id"
                error={t(errors.centreManagementId?.message ?? "")}
                useReactSelect
                isPaginated
              />
            </div>

            <FileUploadWithPreview
              label={t("uploadCompleteFile")}
              files={researcherAttachments}
              setFiles={setResearcherAttachments}
              onChange={(files) => {
                setValue(
                  "researcherAttachmentIds",
                  files.map((x) => x.uuid),
                );
              }}
            />
            <div className="divider" />
            <FileUploadWithPreview
              label={t("uploadFileWithoutAuthorInformation")}
              files={researcherAttachmentWithoutInfo}
              setFiles={setResearcherAttachmentWithoutInfo}
              onChange={(files) => {
                setValue(
                  "researcherAttachmentWithoutInfoIds",
                  files.map((x) => x.uuid),
                );
              }}
            />
          </div>
        </AccordionItem>
      </Accordion>

      <div className="divider" />
      <FormInput
        label={t("notes")}
        wrapperClassName="col-md-3 col-sm-6 mb-2"
        inputClassName="form-control"
        placeholder={t("notes")}
        error={t(errors.researcherNotes?.message ?? "")}
        {...register("researcherNotes")}
      />
      <div className="divider" />

      <RulesAndConditionSection
        errors={errors}
        register={register}
        courseDetails={courseData?.courseDetails.find((x) => x.locale.toLowerCase() === i18n.language.toLowerCase())}
      />
    </form>
  );
};

export default OrderForm;
