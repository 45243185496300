import FormInput from "components/blocks/FormInput";
import { useCookieContext } from "contexts";
import { getCountryCodeAndPhoneByPhone } from "helpers/country-codes-helper";
import { useQuery } from "hooks/useQuery";
import { useTranslation } from "react-i18next";
import { AccountService } from "services/accountService";

const PersonalInfo = () => {
  const { t } = useTranslation("UserProfile");
  const { userSession } = useCookieContext();

  const { data: user } = useQuery({
    queryFn: async () => {
      const parsedUserSession = userSession;

      return await AccountService.getUserByIdAsync(parsedUserSession?.id || "");
    },
    options: {
      enabled: !!userSession,
    },
    triggers: [userSession],
  });

  const { code, phone } = getCountryCodeAndPhoneByPhone(user?.phoneNumber);

  return (
    <div className=" d-flex flex-column gap-4 ">
      <div className="row g-1">
        <FormInput
          label={t("nationalId")}
          value={user?.nationalId}
          disabled={true}
          wrapperClassName="col-lg-3 col-md-4 col-sm-6 col-12"
        />
        <FormInput
          label={t("degree")}
          value={user?.degreeNavigation?.degreeDetail.displayName}
          disabled={true}
          wrapperClassName="col-lg-3 col-md-4 col-sm-6 col-12"
        />
        <FormInput
          label={t("college")}
          value={user?.college}
          disabled={true}
          wrapperClassName="col-lg-3 col-md-4 col-sm-6 col-12"
        />
        <FormInput
          label={t("department")}
          value={user?.department}
          disabled={true}
          wrapperClassName="col-lg-3 col-md-4 col-sm-6 col-12"
        />
      </div>

      <div className="row g-1">
        <FormInput
          label={t("specialization")}
          value={user?.specialization}
          disabled={true}
          wrapperClassName="col-lg-3 col-md-4 col-sm-6 col-12"
        />
        <FormInput
          label={t("employer")}
          value={user?.employer}
          disabled={true}
          wrapperClassName="col-lg-3 col-md-4 col-sm-6 col-12"
        />
        <FormInput
          label={t("email")}
          value={user?.email}
          disabled={true}
          wrapperClassName="col-lg-3 col-md-4 col-sm-6 col-12"
        />
        <FormInput
          label={t("phoneNumber")}
          value={phone}
          disabled={true}
          wrapperClassName="col-lg-2 col-md-3 col-sm-9"
        />
        <FormInput label={" "} value={code} disabled={true} wrapperClassName="col-lg-1 col-md-1 col-sm-3" />
      </div>
    </div>
  );
};

export default PersonalInfo;
