import React, { useEffect } from "react";
import { Form } from "@formio/react";
import { useCookieContext } from "../../contexts";
import FormBuilderComponent from "./FormBuilderComponent";

type FormViewComponentProps = {
  formDefinition: any;
  data?: Record<string, any>;
  onChange?: (data: any, isInitialLoad: boolean) => void;
  onError?: (errors: any) => void;
  readOnly?: boolean;
};

const FormViewComponent: React.FC<FormViewComponentProps> = ({
  formDefinition,
  data,
  onChange,
  onError,
  readOnly: isReadOnly = false,
}) => {
  const isInitialLoad = React.useRef(true);
  const [form, setForm] = React.useState<any>({});

  const { localization, isAr } = useCookieContext();
  useEffect(() => {
    if (formDefinition) {
      var res = JSON.parse(JSON.stringify(traverseAndModify(formDefinition, isAr ? "ar" : "en")));
      setForm(res);
    }
  }, [localization, formDefinition]);

  const traverseAndModify = (obj: any, lang: string): any => {
    // Check if the current object has a `components` key
    if (Array.isArray(obj?.components)) {
      // Iterate over the components array and modify the labels if needed
      obj.components = obj.components.map((component: any) => {
        // If the component has a label and language is 'ar', replace it with `attributes.Ar` only if Ar exists
        if (lang === "ar" && component.attributes?.Ar) {
          component.label = component.attributes.Ar;
        }
        if (lang === "en" && component.attributes?.En) {
          component.label = component.attributes.En;
        }

        // Recursively check for nested components inside this component
        if (component.components) {
          component.components = traverseAndModify(component, lang).components;
        }

        return component;
      });
    }

    // If it's an object, recursively check its properties
    for (const key in obj) {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        obj[key] = traverseAndModify(obj[key], lang);
      }
    }

    return obj;
  };

  return (
    <Form
      form={formDefinition ? form : {}}
      onChange={(data: any) => {
        if (onChange) {
          onChange(data, isInitialLoad.current);
          isInitialLoad.current = false;
        }
      }}
      onError={onError}
      options={{
        renderMode: "form",
        readOnly: isReadOnly,
      }}
      submission={{ data }}
    />
  );
};

export default FormViewComponent;
