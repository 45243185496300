import { GridSchema } from "components/GridView/GridView.types";
import Grid from "components/GridView/components/Grid";
import Accordion from "components/blocks/Accordion";
import AccordionItem from "components/blocks/Accordion/AccordionItem";
import AttachmentCard from "components/blocks/AttachmentCard";
import FilePreview from "components/blocks/FileInput/FilePreview";
import IconButton from "components/blocks/IconButton";
import { useQuery } from "hooks/useQuery";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
// import { ExaminersService } from "services/examiners";
// import { AccreditationTypes, ExaminerListResult } from "services/examiners/Model/ExaminerTypes";
// import { OrderLocalItemResult } from "services/orders/models/OrdersTypes";
import EvaluationFormPreviewModal from "views/home/evaluationForms/EvaluationFormPreviewModal";
import { EXAMINER_TASK_STATUS } from "../ExaminersSection/ExaminersTable";
import ViewExaminerEvaluationModal, {
  ViewExaminerEvaluationModalProps,
} from "../ExaminersSection/ExaminersTable/ViewExaminerEvaluationModal";
import ExaminersTableModal from "./JudgesTableModal";
import { CourseOrderLocalItemResult } from "services/courseOrder/models/CourseOrderType";
import { ExaminerListResult } from "services/examinersService/Model/ExaminerTypes";
import { ExaminersService } from "services/examinersService";

// TODO: types and labels and endpoint will change to judges when the backend is ready

const Judges = ({ order }: { order: CourseOrderLocalItemResult | null }) => {
  const { t } = useTranslation("Orders");

  const [viewExaminerEvaluationModalProps, setViewExaminerEvaluationModalProps] =
    useState<ViewExaminerEvaluationModalProps | null>(null);
  const [examinerTableVisible, showExaminerTable] = useState(false);
  const [evaluationTemplateModalVisible, setEvaluationTemplateModalVisible] = useState(false);
  const { data } = useQuery({
    queryFn: async () => {
      const data = await ExaminersService.getAllExaminersByOrderId(order?.id ?? 0);
      const sortedExaminers = data?.data.sort((a, b) => a.id - b.id);

      const uniqueExaminers = sortedExaminers?.reduce((acc, examiner) => {
        if (examiner.status === EXAMINER_TASK_STATUS.Submitted.value) {
          acc[examiner.examinerName] = examiner;
        }

        return acc;
      }, {} as { [key: string]: ExaminerListResult });

      return {
        data: {
          groupedExaminers: uniqueExaminers ? Object.values(uniqueExaminers) : [],
          examiners: sortedExaminers,
        },
        code: data?.code,
        hasError: data?.hasError,
        description: data?.description,
      };
    },
    triggers: [order],
    options: {
      // enabled: !!order,
      enabled: false,
    },
  });

  const researchesCentersTableHeaders = useMemo<GridSchema[]>(
    () => [
      {
        accessor: "round",
        field: "round",
        displayName: t("round"),
        columnClassName: "w-50",
      },
      {
        accessor: "examinerName",
        field: "examinerName",
        displayName: t("judgeName"),
        columnClassName: "w-50",
      },
      {
        field: "evaluationPercentage",
        displayName: t("evaluation"),
        render: (row: ExaminerListResult) => {
          return row.evaluationPercentage.toFixed(3);
        },
      },
      {
        accessor: "recommendations",
        field: "recommendations",
        displayName: t("recommendations"),
      },

      {
        field: "accreditationType",
        displayName: t("confirmJudgesResult"),
        render: (row: ExaminerListResult) => {
          return "-";
          /* return !row.accreditationType
            ? "-"
            : row.accreditationType === AccreditationTypes.Accreditation.label
            ? t("approved")
            : t("rejected"); */
        },
      },
      {
        field: "actions",
        displayName: t("actions"),
        render: (row: ExaminerListResult) => {
          return (
            <div className="d-flex ">
              <IconButton
                icon="icon-view"
                variant="transparent"
                innerIconColor="primary"
                onClick={() => {
                  onViewExaminerEvaluation(row);
                }}
              />
            </div>
          );
        },
      },
    ],
    [],
  );

  const gridFooter = useMemo(() => {
    return [
      [
        {
          content: <span className="fw-bold p-2">{t("total")}</span>,
        },
        {
          content: data?.groupedExaminers ? (
            <span className="fw-bold p-2">
              {(
                (data?.groupedExaminers.reduce((acc, examiner) => (acc += Number(examiner.evaluationPercentage)), 0) ??
                  0) / (data?.groupedExaminers.length ? data?.groupedExaminers.length : 1)
              ).toFixed(3)}
              %
            </span>
          ) : (
            <span className="fw-bold p-2">0%</span>
          ),
        },
        {
          content: "",
          colSpan: 3,
        },
      ],
    ];
  }, [data]);

  const onViewExaminerEvaluation = (row: ExaminerListResult) => {
    setViewExaminerEvaluationModalProps({
      evaluationPercentage: row.evaluationPercentage,
      onClose: () => {
        setViewExaminerEvaluationModalProps(null);
      },
      examinerEvaluationFormValue: row.examinerEvaluationFormValue,
      selectedTemplate: {
        id: /* order?.programOrderEvaluation.evaluationForm.id ?? */ 0,
        title: /* order!?.programOrderEvaluation.evaluationForm.evaluationFormDetail.displayName ?? */ "",
      },
      formDefinition: row.evaluationForm.jsonSchema,
    });
  };

  return (
    <Accordion>
      <AccordionItem
        initiallyOpen
        title={t("judges")}
        elementId={5}
        mainHeaderClassName="ps-4"
        bodyClassName="py-0 my-0"
        extraHeaders={
          <button className="btn btn-link text-decoration-none fw-bold" onClick={() => showExaminerTable(true)}>
            {t("showAll")}
          </button>
        }
      >
        <div className="d-flex flex-column gap-2 pb-2 border-bottom border-1">
          <Grid
            data={data?.groupedExaminers ?? []}
            gridSchema={researchesCentersTableHeaders}
            gridFooter={gridFooter}
          />

          <div className="d-flex flex-column my-2">
            <h6 className="fw-bold">{t("evaluationTemplate")}</h6>
            <FilePreview
              title={/* order?.programOrderEvaluation?.evaluationForm?.evaluationFormDetail?.displayName */ ""}
              callbackValue={/* order?.programOrderEvaluation?.evaluationForm?.id */ 0}
              onShow={() => {
                setEvaluationTemplateModalVisible(true);
              }}
              showFileSize={false}
              hideDeleteButton
            />
          </div>
          <div className="align-self-start">
            {/* {order?.programOrderEvaluation.files.map((file) => (
              <AttachmentCard
                key={file.id}
                attachment={{
                  extension: file.extension ?? "",
                  fileName: file.name ?? "",
                  id: file.id,
                  size: file.size ?? "",
                }}
              />
            ))} */}
          </div>
          <div className="my-2">
            <h6 className="fw-bold">{t("researchesCenterRecommendations")}</h6>
            <div className="fs-14px ">{order?.notes ? order.notes : t("Common:noData")}</div>
          </div>
        </div>
      </AccordionItem>

      <EvaluationFormPreviewModal
        evaluationFormId={/* order?.programOrderEvaluation?.evaluationForm?.id ?? */ 0}
        title={/* order?.programOrderEvaluation?.evaluationForm?.evaluationFormDetail?.displayName */ ""}
        isOpen={evaluationTemplateModalVisible}
        onClose={() => setEvaluationTemplateModalVisible(false)}
        size="lg"
      />

      <ExaminersTableModal
        isOpen={examinerTableVisible}
        onClose={() => showExaminerTable(false)}
        examiners={data?.examiners ?? []}
        onViewExaminerEvaluation={onViewExaminerEvaluation}
      />
      <ViewExaminerEvaluationModal props={viewExaminerEvaluationModalProps} />
    </Accordion>
  );
};

export default Judges;
