import OrdersGridView from "views/home/orders/OrdersGridView";

type OrdersTabProps = {
  courseId: number;
};

const OrdersTab: React.FC<OrdersTabProps> = ({ courseId }) => {
  return <OrdersGridView courseId={courseId} />;
};

export default OrdersTab;
