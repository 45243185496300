import IconButton from "components/blocks/IconButton";
import LoadingOverlay from "components/blocks/LoadingOverlay";
import Switch from "components/blocks/Switch";
import { useCookieContext } from "contexts";
import { useBreadcrumb } from "contexts/breadcrumb/BreadcrumbContext";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import "./ScientificPublicationForm.scss";
import SPFormAttachmentsTable from "./components/SPFormAttachmentsTable";
import SPFormMainInfo from "./components/SPFormMainInfo";
import SPFormDetails from "./components/SPFormDetails";
import { CreateScientificPublicationRequest, ScientificPublicationStatus } from "./ScientificPublicationForm.types";
import { zodResolver } from "@hookform/resolvers/zod";
import getScientificPublicationValidationSchema from "./ScientificPublicationValidationSchema";
import useMutation from "hooks/useMutation";
import { ScientificPublicationsService } from "services/scientificPublicationsService";
import { useNotification } from "hooks/useNotification";
import { useQuery } from "hooks/useQuery";
import { scientificPublicationToCreateDto } from "./helper";

const now = new Date();

const ScientificPublicationForm: React.FC = () => {
  // Hooks
  const { t } = useTranslation("ScientificPublication");
  const navigate = useNavigate();
  const { isRTL, isAr } = useCookieContext();
  const { setBreadcrumbs } = useBreadcrumb();
  const { showNotification } = useNotification();
  const { id } = useParams();
  const isUpdateForm = !!id;

  // STATES
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [publicationSerialNumber, setPublicationSerialNumber] = useState("");

  // EFFECTS
  useEffect(() => {
    setBreadcrumbs([
      {
        localizationKey: "scientificPublication",
        path: "/home/scientificPublication",
        menuItemId: "scientificPublication",
      },
      {
        localizationKey: "addScientificPublication",
        path: `/home/scientificPublication/form`,
      },
    ]);
  }, []);

  const { data: scientificPublicationData, loading: isScientificPublicationLoading } = useQuery({
    queryFn: () => {
      return ScientificPublicationsService.getScientificPublicationById(Number(id));
    },
    options: {
      enabled: isUpdateForm,
    },
    onError: (error) => {
      showNotification({
        description: t("Common:internalServerError"),
        type: "error",
      });
      navigate(`/home/scientificPublication/${id}`);
    },
  });

  const scientificPublicationValidationSchema = getScientificPublicationValidationSchema(t);

  const {
    formState: { errors },
    handleSubmit,
    register,
    watch,
    getValues,
    setValue,
    reset,
  } = useForm<CreateScientificPublicationRequest>({
    resolver: zodResolver(scientificPublicationValidationSchema),
    defaultValues: {
      status: ScientificPublicationStatus.NotPublishing,
      scientificPublishingDetails: [
        {
          locale: "Ar",
          displayName: "",
          description: "",
        },
        {
          locale: "En",
          displayName: "",
          description: "",
        },
      ],
      scientificPublishingAttachments: [],
      searchWords: [],
      librariesIds: [],
      scientificSpecializationId: 0,
      typeOfScientificId: 0,
      scientificClassificationId: 0,
      languageOfScientificId: 0,
      publisherId: 0,
      publisherTypeId: 0,
      numberOfPaper: 0,
      scientificPublicationLink: "",
      scientificPublishingImageId: "",
      metaData: "",
      isHardCopyAvailable: false,
      authorName: "",
      publicationDate: now,
      serialNumber: "",
    },
  });

  useEffect(() => {
    if (scientificPublicationData) {
      reset(scientificPublicationToCreateDto(scientificPublicationData));
    }
  }, [scientificPublicationData]);

  const { mutateAsync: createAsync, loading: isCreateLoading } = useMutation({
    queryFn: (data: CreateScientificPublicationRequest) => {
      return ScientificPublicationsService.createScientificPublication(data);
    },
  });

  const { mutateAsync: updateAsync, loading: isUpdateLoading } = useMutation({
    queryFn: (data: CreateScientificPublicationRequest) => {
      return ScientificPublicationsService.updateScientificPublication(Number(id), data);
    },
  });

  const onSubmit = async (data: CreateScientificPublicationRequest) => {
    try {
      const response = isUpdateForm ? await updateAsync(data) : await createAsync(data);

      if (response?.hasError) {
        showNotification({
          description: t("Common:internalServerError"),
          type: "error",
        });
      } else {
        showNotification({
          description: t("Common:success"),
          type: "success",
        });
        navigate("/home/scientificPublication");
      }
    } catch (error) {
      showNotification({
        description: t("Common:internalServerError"),
        type: "error",
      });
    }
  };

  return (
    <form className="position-relative" noValidate onSubmit={handleSubmit(onSubmit)}>
      <LoadingOverlay visible={loading || isCreateLoading || isUpdateLoading} className="position-fixed" />

      <div className="d-flex flex-wrap justify-content-between align-items-center mb-3">
        <div className="d-flex gap-2 align-items-center">
          <IconButton
            icon={isRTL ? "icon-full-arrow-right" : "icon-full-arrow-left"}
            innerIconColor="black"
            size="lg"
            className="text-dark"
            onClick={() => navigate(-1)}
          />
          <div className="d-flex gap-3 align-items-start">
            <div>
              <h4 className="text-dark mb-0">{t("addScientificPublication")}</h4>
              {publicationSerialNumber && <span>{publicationSerialNumber}</span>}
            </div>
            <Switch
              className="mt-1"
              checked={watch("status") === ScientificPublicationStatus.Publishing}
              onChange={(checked) => {
                setValue(
                  "status",
                  checked ? ScientificPublicationStatus.Publishing : ScientificPublicationStatus.NotPublishing,
                );
              }}
            />
          </div>
        </div>
        <div className="d-flex gap-2">
          <button className="btn btn-primary px-3" type="submit" disabled={submitting}>
            {submitting && <span className="spinner-border spinner-border-sm mx-2" role="status" aria-hidden="true" />}
            {t("Common:save")}
          </button>
          <button className="btn btn-outline-secondary px-3" type="submit" disabled={submitting}>
            {submitting && <span className="spinner-border spinner-border-sm mx-2" role="status" aria-hidden="true" />}
            {t("Common:cancel")}
          </button>
        </div>
      </div>

      <div className="divider" />
      <SPFormMainInfo register={register} setValue={setValue} watch={watch} errors={errors} />
      <div className="divider" />
      <SPFormDetails
        register={register}
        setValue={setValue}
        watch={watch}
        errors={errors}
        getValues={getValues}
        imageFile={scientificPublicationData?.scientificPublishingImage}
      />
      <div className="divider" />
      <SPFormAttachmentsTable
        setValue={setValue}
        attachments={scientificPublicationData?.scientificPublishingAttachments}
      />
    </form>
  );
};

export default ScientificPublicationForm;
