import Accordion from "components/blocks/Accordion";
import AccordionItem from "components/blocks/Accordion/AccordionItem";
import FileInput from "components/blocks/FileInput";
import FormInput from "components/blocks/FormInput";
import { Ref, RefObject, forwardRef, useImperativeHandle, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { getAdditionalAttachments } from "./additionalAttachments.schema";
import { useQuery } from "hooks/useQuery";
import useMutation from "hooks/useMutation";
import { FileService } from "services/fileService";
import FilePreview from "components/blocks/FileInput/FilePreview";
import { SelectedFilesType } from "components/blocks/FileUploadWithPreview";
import { CourseOrderAdditionalAttachmentService } from "services/courseOrderAdditionalAttachmentService";
import { CreateAdditionalAttachmentRequest } from "services/courseOrderAdditionalAttachmentService/Model/CourseOrderAdditionalAttachmentTypes";
import AttachmentCard from "components/blocks/AttachmentCard";
import LoadingOverlay from "components/blocks/LoadingOverlay";
import { AdminTapActionsRef } from "../../UserTypeOrderView/CourseOrderAdminView";

const AdditionalAttachmentsTab = ({
  submitRef,
  courseOrderId,
}: {
  submitRef: RefObject<AdminTapActionsRef>;
  courseOrderId: number;
}) => {
  const formRef = useRef<HTMLFormElement>(null);
  const { t } = useTranslation(["Orders", "Common"]);

  const [file, setFile] = useState<
    SelectedFilesType & {
      isFileAdded?: boolean;
      description?: string;
    }
  >();
  const {
    formState: { errors },
    handleSubmit,
    setValue,
    control,
    getValues,
    clearErrors,
  } = useForm<CreateAdditionalAttachmentRequest>({
    values: {
      description: "",
      fileId: "",
      programOrderId: courseOrderId,
    },
    resolver: getAdditionalAttachments(t),
  });

  const {
    data,
    loading: isCreateAttachmentLoading,
    mutateAsync,
  } = useMutation({
    queryFn: async (request: CreateAdditionalAttachmentRequest) => {
      return await CourseOrderAdditionalAttachmentService.createAdditionalAttachment(request);
    },
  });

  const { data: attachments, loading: isGetAttachmentsLoading } = useQuery({
    queryFn: async () => {
      return await CourseOrderAdditionalAttachmentService.getAllAdditionalAttachmentsByCourseOrderId(courseOrderId);
    },
    triggers: [data],
  });

  const { mutateAsync: uploadFileAsync, loading: fileUploadLoading } = useMutation({
    queryFn: async (values: File) => {
      return await FileService.uploadFile(values);
    },
  });

  const { mutateAsync: fileDownload, loading: fileDownloadLoading } = useMutation({
    queryFn: async (id: string) => {
      return await FileService.downloadFile(id);
    },
  });

  const onFileUpload = async (file: File) => {
    const response = await uploadFileAsync(file);
    if (response && !response.hasError) {
      setFile({
        fileName: response.data!.fileName,
        uuid: response.data!.uuid,
        file,
        isFileAdded: false,
        size: `${(file.size / 1024 / 1024).toFixed(2)}MB`,
      });
      setValue("fileId", response.data!.uuid);
      clearErrors("fileId");
    }
  };

  const onAddAttachment = async () => {
    if (file) {
      setFile({ ...file!, isFileAdded: true, description: getValues("description") });
    }
  };

  const onSubmit = async (values: CreateAdditionalAttachmentRequest) => {
    const response = await mutateAsync(values);
    if (response && !response.hasError) {
      setFile(undefined);
      setValue("description", "");
    }
  };

  useImperativeHandle(submitRef, () => ({
    submit: async () => {
      formRef.current?.classList.add("was-validated");
      await handleSubmit(async (values) => {
        await onSubmit(values);
      })();
    },
  }));

  return (
    <form ref={formRef} noValidate onSubmit={handleSubmit(onSubmit)}>
      <Accordion>
        <AccordionItem initiallyOpen title={t("newAttachment")} elementId={"new-attachment"}>
          <LoadingOverlay visible={isCreateAttachmentLoading} />

          <FileInput
            labelClassName="fw-bold"
            label={t("attachments")}
            clickHereForLabel={t("Common:toAttachFile")}
            containerWrapperClassName="flex-grow-1"
            onChange={(files) => {
              files && onFileUpload(files[0]);
            }}
          />
          {file && !file.isFileAdded && (
            <div className="d-flex align-items-start">
              <FilePreview
                key={file.uuid}
                loading={fileDownloadLoading}
                onDelete={() => {
                  setFile(undefined);
                }}
                onShow={async () => {
                  await fileDownload(file.uuid);
                }}
                file={file.file}
              />
            </div>
          )}
          {errors.fileId && <div className="invalid-feedback d-block">{errors.fileId.message}</div>}
          <div className="mt-2">
            <label className="fw-bold">{t("description")}</label>
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <div className="d-flex flex-wrap gap-2">
                  <FormInput
                    wrapperClassName="flex-grow-1"
                    placeholder={t("description")}
                    error={errors.description?.message as string}
                    labelClassName="fw-bold"
                    disabled={file && file.isFileAdded}
                    {...field}
                  />
                  <button
                    type="button"
                    className="btn btn-primary "
                    style={{ height: "38px" }}
                    onClick={onAddAttachment}
                    disabled={fileUploadLoading || !file || !field.value.length || file.isFileAdded}
                  >
                    {t("add")}
                  </button>
                </div>
              )}
            />
          </div>

          {file && file.isFileAdded && (
            <div className="d-flex flex-column gap-2 align-items-start mt-2 ">
              <FilePreview
                key={file.uuid}
                loading={fileDownloadLoading}
                onDelete={() => {
                  setFile(undefined);
                }}
                onShow={async () => {
                  await fileDownload(file.uuid);
                }}
                file={file.file}
              />
              <div className="d-flex gap-2">
                <h5 className="m-0">{t("description")}</h5>
                <p className="m-0 fs-14px">{file.description}</p>
              </div>
            </div>
          )}
        </AccordionItem>
      </Accordion>

      <Accordion>
        <AccordionItem initiallyOpen title={t("attachments")} elementId={"attachments"} labelClassName="fw-bold">
          <div className="d-flex gap-2 flex-wrap position-relative">
            <LoadingOverlay visible={isGetAttachmentsLoading} />
            {attachments?.result.map((attachment) => (
              <AttachmentCard
                key={attachment.id}
                attachment={{
                  extension: attachment.extension ?? "",
                  fileName: attachment.name ?? "",
                  size: attachment.size ?? "",
                  id: attachment.fileId,
                }}
              />
            ))}
          </div>
        </AccordionItem>
      </Accordion>
    </form>
  );
};

export default AdditionalAttachmentsTab;
