import FormInput from "components/blocks/FormInput";
import IconButton from "components/blocks/IconButton";
import RangeInput from "components/blocks/RangeInput";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { CreateCourseRequest } from "services/courseService/models/CourseType";
import LookupDropdown from "components/blocks/LookupDropdown";

type RewardCalculationItemCreationProps = {
  onAdd: (item: CreateCourseRequest["bonusCalculationTerms"][0]) => void;
};

const RewardCalculationItemCreation: React.FC<RewardCalculationItemCreationProps> = ({ onAdd }) => {
  const { t, i18n } = useTranslation("Courses");
  const [itemData, setItemData] = useState<CreateCourseRequest["bonusCalculationTerms"][0]>({
    bonusTypeId: 0,
    notes: "",
    fromValue: 0,
    toValue: 0,
  });

  const handleOnChange = (value: string) => {
    setItemData({ ...itemData, notes: value });
  };

  const handleRangeChange = (value: [string, string]) => {
    setItemData({ ...itemData, fromValue: +value[0], toValue: +value[1] });
  };

  const onTypeChange = (value: string) => {
    setItemData({
      ...itemData,
      bonusTypeId: +value,
      notes: itemData.notes,
    });
  };

  const onSubmit = () => {
    onAdd(itemData);
    setItemData({
      bonusTypeId: 0,
      fromValue: 0,
      toValue: 0,
      notes: "",
    });
  };

  return (
    <div className="d-flex gap-4 align-items-end mb-3">
      <div className="row flex-grow-1">
        <LookupDropdown
          label={t("rewardType")}
          service="bounceTypeService"
          endpoint="getAllBounceTypeDropDown"
          idValueKey="id"
          noSelectionPlaceholder={t("rewardType")}
          textValueKey={`details.${i18n.language.toLowerCase() == "en" ? 1 : 0}.displayName`}
          wrapperClassName="col-6 col-md-4"
          defaultValue={""}
          onChange={onTypeChange}
          isPaginated={false}
        />
        <FormInput
          wrapperClassName="col-6 col-md-4"
          label={t("Common:notes")}
          value={itemData.notes}
          onChange={(e) => handleOnChange(e.target.value)}
        />
        <RangeInput
          wrapperClassName="col-6 col-md-4"
          label={t("amountFromTo")}
          leftElement={<span className="text-primary">{t("Common:riyal")}</span>}
          placeholderStart={t("Common:from")}
          placeholderEnd={t("Common:to")}
          value={[itemData.fromValue.toString(), itemData.toValue.toString()]}
          onChange={handleRangeChange}
        />
      </div>
      <IconButton size="xl" variant="primary" icon="icon-plus" onClick={onSubmit} />
    </div>
  );
};

export default RewardCalculationItemCreation;
