import { zodResolver } from "@hookform/resolvers/zod";
import { CreateCourseSpeechesRequest } from "services/courseSpeechesService/Model/CourseSpeechesTypes";
import { z } from "zod";

export const getSpeechesSchema = (t: any) => {
  const schema = z.object({
    courseOrderId: z.number().min(1, t("Common:invalidValue")),
    pdfTemplateId: z.number().min(1, t("Common:invalidValue")),
    description: z.string().min(1, t("Common:invalidValue")),
    selectedLocale: z.string().min(1, t("Common:invalidValue")),
  }) as z.ZodType<CreateCourseSpeechesRequest>;

  return zodResolver(schema);
};
