import { HttpClient } from "helpers";
import { BaseResponseType, PagedResult } from "services/shared-models/sharedTypes";
import { CourseOrderMinutesResult, CreateCourseOrderMinuteRequest } from "./Model/CourseSpeechesTypes";

export const CourseMinutesService = {
  createCourseMinute: async (createCourseOrderMinuteRequest: CreateCourseOrderMinuteRequest) => {
    return await HttpClient.post<BaseResponseType<CreateCourseOrderMinuteRequest>>(
      `/api/CourseMinutes/CreateCourseMinute`,
      createCourseOrderMinuteRequest,
    );
  },

  getAllCourseMinutesByCourseOrderId: async (courseOrderId: number) => {
    return await HttpClient.get<BaseResponseType<PagedResult<CourseOrderMinutesResult>>>(
      `/api/CourseMinutes/GetAllCourseMinutesByCourseOrderId`,
      {
        params: {
          courseOrderId,
        },
      },
    );
  },
};
