import { HttpClient } from "helpers";
import { BaseResponseType, PagedResult } from "services/shared-models/sharedTypes";
import {
  CreateAdditionalAttachmentRequest,
  AdditionalAttachmentsResult,
} from "./Model/CourseOrderAdditionalAttachmentTypes";

export const CourseOrderAdditionalAttachmentService = {
  createAdditionalAttachment: async (createAdditionalAttachmentRequest: CreateAdditionalAttachmentRequest) => {
    return await HttpClient.post<BaseResponseType<AdditionalAttachmentsResult>>(
      `/api/CourseOrderAdditionalAttachment/CreateAdditionalAttachment`,
      createAdditionalAttachmentRequest,
    );
  },

  getAllAdditionalAttachmentsByCourseOrderId: async (courseOrderId: number) => {
    return await HttpClient.get<BaseResponseType<PagedResult<AdditionalAttachmentsResult>>>(
      `/api/CourseOrderAdditionalAttachment/GetAllAdditionalAttachmentsByCourseOrderId`,
      {
        params: {
          courseOrderId,
        },
      },
    );
  },
};
