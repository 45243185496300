import IconButton from "components/blocks/IconButton";
import GridView from "components/GridView";
import { GridSchema, GridViewHeaderProps } from "components/GridView/GridView.types";
import { useCookieContext } from "contexts";
import { useBreadcrumb } from "contexts/breadcrumb/BreadcrumbContext";
import useGridData from "hooks/useGridData";
import useSystemDateTheme from "hooks/useSystemDateTheme";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AuthorPaymentResult } from "services/authorsPaymentsService/models/authorsPaymentsTypes";

const AuthorsPaymentsGridView = () => {
  const { t } = useTranslation("Authors");
  const navigate = useNavigate();
  const { setBreadcrumbs } = useBreadcrumb();
  const { toSystemThemeDateFormat } = useSystemDateTheme();
  const { isAr } = useCookieContext();

  const { loading, gridData, totalCount, exportToExcel } = useGridData<"authorsPaymentsService", AuthorPaymentResult>({
    service: "authorsPaymentsService",
    endpoint: "getAllAuthorsPayments",
    exportExcelEndpoint: "exportToExcel",
    exportFileName: "authorsPayments",
    localizationNameSpace: "Authors",
  });

  useEffect(() => {
    setBreadcrumbs([
      {
        localizationKey: "authorsPayments",
        path: "/home/authorsManagement/authorsPayments",
        menuItemId: "authors",
      },
    ]);
  }, []);

  const gridSchema = useMemo<GridSchema[]>(
    () => [
      {
        field: "id",
        displayName: t("authorName"),
        sortable: true,
        filterable: true,
        showOnMobile: true,
        render: (row: AuthorPaymentResult) => {
          return isAr ? row.arabicName : row.englishName;
        },
      },
      {
        field: "orderNumber",
        displayName: t("orderNumber"),
        render: (row: AuthorPaymentResult) => {
          return (
            <button className="btn btn-link" onClick={() => navigate(`/home/orders/${row.orderId}`)}>
              {row.orderNumber}
            </button>
          );
        },
      },
      {
        field: "paymentDate",
        displayName: t("paymentDate"),
        render: (row: AuthorPaymentResult) => {
          return toSystemThemeDateFormat(row.paymentDate);
        },
      },
      {
        field: "paymentAmount",
        displayName: t("paymentAmount"),
      },
      {
        field: "status",
        displayName: t("Common:status"),
        sortable: true,
        filterable: true,
        showOnMobile: true,
        filterOptions: {
          type: "select",
          options: [
            { id: "Paid", value: t("paid") },
            { id: "NotPaid", value: t("notpaid") },
            { id: "Reject", value: t("reject") },
            { id: "Pending", value: t("pending") },
          ],
        },
        render(row: AuthorPaymentResult) {
          const statusClassName =
            row.status === "NotPaid" || row.status === "Reject"
              ? "bg-danger text-danger"
              : row.status === "Paid"
              ? "bg-success text-success"
              : "bg-primary text-primary";
          return (
            <span className={`badge rounded-4 ${statusClassName} bg-opacity-10 py-2`}>
              {t(row.status.toLowerCase())}
            </span>
          );
        },
      },
      {
        field: "id",
        displayName: t("paymentReceipt"),
        showOnMobile: true,
        render: (row: AuthorPaymentResult) => {
          return (
            <IconButton
              title={t("paymentReceipt")}
              icon="icon-receipt"
              fitToIconWidth
              innerIconColor="dark"
              size="md"
              onClick={() => {}}
            />
          );
        },
      },
    ],
    [t, toSystemThemeDateFormat],
  );

  const viewHeader: GridViewHeaderProps = {
    title: t("authorsPayments"),
    singularName: t("Common:result"),
    totalCount: totalCount,
    onExportClick: exportToExcel,
  };

  return (
    <GridView
      loading={loading}
      gridProps={{
        data: gridData ?? [],
        gridSchema,
      }}
      viewHeaderProps={viewHeader}
    />
  );
};

export default AuthorsPaymentsGridView;
