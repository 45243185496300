import FormInput from "components/blocks/FormInput";
import { useTranslation } from "react-i18next";
import { CreateProfileSectionProps } from "./PersonalInformation";
import { Controller, UseFormWatch } from "react-hook-form";
import LookupDropdown from "components/blocks/LookupDropdown";
import { UpdateUserRequest } from "services/accountService/models/AccountType";

const AccountTypes = {
  IBAN: 1,
  SWIFT: 2,
};

const BankingInformation = ({
  errors,
  register,
  setValue,
  control,
  watch,
}: CreateProfileSectionProps & {
  watch: UseFormWatch<UpdateUserRequest>;
}) => {
  const { t } = useTranslation("UserProfile");

  return (
    <div className="d-flex flex-wrap flex-column gap-2">
      <div className="d-flex flex-wrap gap-2">
        <FormInput
          label={t("beneficiaryName")}
          wrapperClassName="flex-1"
          {...register("beneficiaryName")}
          error={errors.beneficiaryName?.message}
        />
        <FormInput
          label={t("bankName")}
          wrapperClassName="flex-1"
          {...register("bankName")}
          error={errors.bankName?.message}
        />
        <Controller
          name="accountTypeId"
          control={control}
          render={({ field }) => (
            <LookupDropdown
              label={t("bankAccountType")}
              wrapperClassName="flex-1"
              service="lookupService"
              endpoint="getAllAccountTypes"
              idValueKey="id"
              textValueKey="acountTypeDetail.displayName"
              noSelectionPlaceholder={t("bankAccountType")}
              value={field.value || ""}
              onChange={(v) => {
                setValue("accountTypeId", v ? +v : 0);
              }}
              error={errors.accountTypeId?.message}
            />
          )}
        />
        <FormInput
          label={t("bankAccountNumber")}
          wrapperClassName="flex-1"
          {...register("accountNumber")}
          error={errors.accountNumber?.message}
        />
      </div>
      <div className="d-flex flex-wrap gap-2">
        {watch("accountTypeId") === AccountTypes.IBAN && (
          <FormInput
            label={t("bankAccountIBAN")}
            wrapperClassName="flex-1"
            {...register("ibanNumber")}
            error={errors.ibanNumber?.message}
          />
        )}
        {watch("accountTypeId") === AccountTypes.SWIFT && (
          <FormInput
            label={t("SWIFT")}
            wrapperClassName="flex-1"
            {...register("swift")}
            error={errors.swift?.message}
          />
        )}
        <FormInput
          label={t("bankRoutingNumber")}
          wrapperClassName="flex-1"
          {...register("bankRoutingNumber")}
          error={errors.bankRoutingNumber?.message}
        />
        <Controller
          name="currencyId"
          control={control}
          render={({ field }) => (
            <LookupDropdown
              label={t("currency")}
              wrapperClassName="flex-1"
              service="lookupService"
              endpoint="getAllCurrencies"
              idValueKey="id"
              textValueKey="currencyDetail.displayName"
              value={field.value || ""}
              noSelectionPlaceholder={t("currency")}
              onChange={(v) => {
                setValue("currencyId", v ? +v : 0);
              }}
              error={errors.bankName?.message}
            />
          )}
        />
      </div>
    </div>
  );
};

export default BankingInformation;
