import { UserResult } from "services/accountService/models/AccountType";
import { CourseDetailsResult } from "services/courseService/models/CourseType";
import {
  ScientificClassificationResult,
  ScientificSpecializationResult,
} from "services/scientificPublicationsService/models/scientificPublicationsTypes";
import { CentreManagementDetailsResult, FileResult } from "services/shared-models/sharedTypes";

export type CourseOrderResult = {
  id: number;
  courseId: number;
  centreManagementId: number;
  formSubject: string;
  formValue: string;
  isAcceptedConditionAndRules: boolean;
  isAcceptedCommitments: boolean;
  appliedDate: Date;
};

export type CreateCourseOrderRequest = {
  courseId: number;
  appliedProcessInstanceId?: number;
  formValue?: string;
  centreManagementId: number;
  scientificSpecializationId: number;
  languageOfScientificId: number;
  typeOfScientificId: number;
  scientificClassificationId: number;
  numberOfPages: number;
  acceptTermsAndCondition: boolean;
  acceptAmendments: boolean;
  researcherAttachmentIds: string[];
  researcherAttachmentWithoutInfoIds: string[];
  researcherNotes?: string;
  isDraft: boolean;
  serialGuid?: string | null;
  actionName?: string | null;
};

export type TypeOfScientificResult = {
  id: number;
  typeOfScientificDetail: TypeOfScientificDetailsResult;
};

export type TypeOfScientificDetailsResult = {
  displayName: string;
  locale: string;
};

export type ScientificClassificationDetailsResult = {
  displayName: string;
  locale: string;
};

export type CourseOrderLocalResult = {
  id: number;
  courseId: number;
  courseSerialNumber: string;
  serialNumber: string;
  numberOfPages: number;
  courseDetail: CourseDetailsResult;
  typeOfScientific: TypeOfScientificResult;
  scientificClassification: ScientificClassificationResult;
  appliedUserName: string;
  createdOn: Date;
  centreManagementDetail: CentreManagementDetailsResult;
  centreManagerName: string;
  courseOrderPhase: string;
  courseOrderStatusId: number;
  courseOrderStatus: string;
};

export type LanguageOfScientificResult = {
  id: number;
  languageOfScientificDetail: LanguageOfScientificDetailsResult;
};

export type LanguageOfScientificDetailsResult = {
  displayName: string;
  locale: string;
};

export type CourseOrderLocalItemResult = {
  id: number;
  courseId: number;
  serialNumber: string;
  courseOrderStatusId: number;
  courseOrderStatus: string;
  processInstanceId: number;
  submissionDate: string;
  notes: string;
  isInspectMode: boolean;
  researcherNotes: string;
  numberOfPages: number;
  acceptTermsAndCondition: boolean;
  acceptAmendments: boolean;
  centreManagementId: number;
  centreManagement: CentreManagementDetailsResult;
  researcherAttachment: FileResult[];
  researcherAttachmentWithoutInfo: FileResult[];
  appliedUser: UserResult;
  formValue: string;
  scientificSpecialization: ScientificSpecializationResult;
  languageOfScientific: LanguageOfScientificResult;
  typeOfScientific: TypeOfScientificResult;
  scientificClassification: ScientificClassificationResult;
};

export const CourseOrderStatus = {
  Pending: {
    id: 1,
    label: "Pending", // TODO: check
    color: "primary",
  },
  Completed: {
    id: 2,
    label: "Completed",
    color: "success",
  },
  Rejected: {
    id: 3,
    label: "Rejected",
    color: "danger",
  },
  Draft: {
    id: 4,
    label: "Draft",
    color: "secondary",
  },
};
