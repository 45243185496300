import AttachmentCard from "components/blocks/AttachmentCard";
import { useTranslation } from "react-i18next";
import { CourseOrderLocalItemResult } from "services/courseOrder/models/CourseOrderType";
import { CourseLocalItemResult } from "services/courseService/models/CourseType";
import { OrderUserTypes, UserTypes } from "../../../orderPage.types";
import FormViewComponent from "components/formBuilder/FormViewComponent";

const OrderDetails = ({
  order,
  userType,
  course,
}: {
  order?: CourseOrderLocalItemResult | null;
  course?: CourseLocalItemResult | null;
  userType?: UserTypes;
}) => {
  const { t, i18n } = useTranslation("Orders");

  return (
    <div
      className={`d-flex d-flex flex-column gap-3 ${
        userType === OrderUserTypes.StudentForPublishedOrder ? "p-3" : ""
      } pb-2 `}
    >
      <div>
        <h6 className="fw-bold">{t("suggestedTitle")}</h6>
        <p className="fs-14px">
          {
            course?.courseDetails.find((detail) => detail.locale.toLowerCase() === i18n.language.toLowerCase())
              ?.displayName
          }
          {/* TODO: course order details needed */}
        </p>
      </div>

      <div>
        <h6 className="fw-bold">{t("publishingDetails")}</h6>
        <FormViewComponent
          readOnly
          data={order?.formValue ? JSON.parse(order.formValue) : {}}
          formDefinition={
            order?.scientificClassification.jsonSchema ? JSON.parse(order?.scientificClassification.jsonSchema) : {}
          }
          onChange={() => {}}
        />
      </div>

      {userType === OrderUserTypes.StudentForPublishedOrder && (
        <div className="striped d-flex flex-column gap-2">
          <div className="d-flex p-3">
            <span className="flex-1 fw-bold">{t("scientificSpecializationOfThePublishedMaterial")}</span>
            <span className="flex-2">{order?.scientificSpecialization.scientificSpecializationDetail.displayName}</span>
          </div>
          <div className="d-flex p-3">
            <span className="flex-1 fw-bold"> {t("publishingEntity")}</span>
            <span className="flex-2"> {t("publishingEntity")}</span>
            {/* TODO: publisher */}
          </div>
          <div className="d-flex p-3">
            <span className="flex-1 fw-bold"> {t("publishingEntityType")} </span>
            <span className="flex-2"> {t("publishingEntityType")}</span>
            {/* TODO: publisher Type */}
          </div>
          <div className="d-flex p-3">
            <span className="flex-1 fw-bold">{t("scientificPublicationLinkInThePublishingEntity")}</span>
            <span className="flex-2"> {"https://link.com"}</span>
            {/* TODO: publisher url */}
          </div>
          <div className="d-flex p-3">
            <span className="flex-1 fw-bold">{t("serialNumberIssn")}</span>
            <span className="flex-2"> {order?.serialNumber}</span>
            {/* TODO: Issn */}
          </div>
          <div className="d-flex p-3">
            <span className="flex-1 fw-bold">{t("numberOfPages")}</span>
            <span className="flex-2"> {order?.numberOfPages}</span>
          </div>
        </div>
      )}

      <div>
        {userType === OrderUserTypes.StudentForPublishedOrder && <div className="divider"></div>}
        <h6 className="fw-bold">{t("completedFileAttachments")}</h6>
        <div className="d-flex gap-3 mt-2 ">
          {order?.researcherAttachment?.length ? (
            order?.researcherAttachment.map((attachment) => (
              <AttachmentCard
                key={attachment.id}
                attachment={{
                  id: attachment.id,
                  fileName: attachment.name ?? "",
                  size: attachment.size ?? "",
                  extension: attachment.extension ?? "",
                }}
              />
            ))
          ) : (
            <span>{t("Common:noData")}</span>
          )}
        </div>
        {userType === OrderUserTypes.StudentForPublishedOrder && <div className="divider"></div>}
      </div>

      <div>
        <h6 className="fw-bold">{t("fileWithoutAuthorInformationAttachments")}</h6>
        <div className="d-flex gap-3 mt-2 ">
          {order?.researcherAttachmentWithoutInfo?.length ? (
            order?.researcherAttachmentWithoutInfo.map((attachment) => (
              <AttachmentCard
                key={attachment.id}
                attachment={{
                  id: attachment.id,
                  fileName: attachment.name ?? "",
                  size: attachment.size ?? "",
                  extension: attachment.extension ?? "",
                }}
              />
            ))
          ) : (
            <span>{t("Common:noData")}</span>
          )}
        </div>
        {userType === OrderUserTypes.StudentForPublishedOrder && <div className="divider"></div>}
      </div>
      {/* 
          <FilePreview
            key={course?.}
            loading={fileDownloadLoading}
            onDelete={() => {
              setFiles(files.filter((f) => f.uuid !== file.uuid));
            }}
            onShow={async () => {
              await fileDownload(file.uuid);
            }}
            title={file.fileName}
            file={file.file}
            fileSize={file.size}
          /> */}
    </div>
  );
};

export default OrderDetails;
