import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { TaskActionsNames } from "services/shared-models/sharedTypes";
import { OpenPublishingTaskResult } from "services/tasks/models/tasksTypes";

export type GenericActionButtonsProps = {
  loading: boolean;
  openedTask: OpenPublishingTaskResult | null;
  onActionClick: (action: TaskActionsNames, msg: string) => void;
};

const GenericActionButtons = ({ loading, openedTask, onActionClick }: GenericActionButtonsProps) => {
  const { t } = useTranslation("Orders");

  return (
    <>
      {openedTask?.actionName.map((action) => {
        const actionParts = action.split(" ");
        const msg = t(actionParts[0].toLocaleLowerCase(), {
          placeholder:
            actionParts[actionParts.length - 1] && actionParts.length > 1
              ? t(actionParts[actionParts.length - 1].toLocaleLowerCase())
              : "",
        });

        return (
          <button
            className={`btn btn-${
              action.toLowerCase().includes("reject")
                ? "warning"
                : action.toLowerCase().includes("return")
                ? "secondary"
                : "primary"
            } px-3`}
            onClick={() => onActionClick(action.split(" ")[0] as TaskActionsNames, msg)}
            disabled={loading}
          >
            {msg}
          </button>
        );
      })}
    </>
  );
};

export default GenericActionButtons;
