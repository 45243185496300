import MultiDatePicker, { DateObject } from "react-multi-date-picker";
import HijriCalendar from "react-date-object/calendars/arabic";
import GregorianCalendar from "react-date-object/calendars/gregorian";
import HijriArabicCalendar from "react-date-object/locales/arabic_ar";
import HijiryEnglishCalendar from "react-date-object/locales/arabic_en";
import GregorianArabicCalendar from "react-date-object/locales/gregorian_ar";

import "./DatePicker.scss";
import { useCookieContext } from "contexts";

type DatePickerProps = {
  label?: string;
  placeholder?: string;
  value?: string | Date | DateObject;
  wrapperClassName?: string;
  error?: string;
  labelClassName?: string;
  withTimePicker?: boolean;
  onlyTimePicker?: boolean;
  onlyDatePicker?: boolean;
  hideIcon?: boolean;
  inputClassNames?: string;
  range?: boolean;
  disabled?: boolean;
} & (
  | {
      range?: false;
      onChange?: (date: Date | null) => void;
    }
  | {
      range: true;
      onChange?: (date: Date[] | null) => void;
    }
);

// TODO: Icon positioning and Coloring (.icon-tabler-calendar-event)
const DatePicker: React.FC<DatePickerProps> = (props) => {
  const {
    label,
    placeholder,
    value,
    onChange,
    wrapperClassName,
    error,
    labelClassName,
    range,
    disabled = false,
  } = props;

  const { isAr, isHijri } = useCookieContext();

  const onLocalChange = (e: DateObject | DateObject[] | null) => {
    if (!range) {
      const date = e as DateObject | null;
      onChange?.(date?.toDate() ?? null);
    } else {
      const dates = e as DateObject[] | null;
      onChange?.(dates?.map((d) => d.toDate()) ?? null);
    }
  };

  return (
    <div className={`${wrapperClassName}`}>
      <div className={`date-picker-wrapper position-relative ${error ? "is-invalid" : ""}`}>
        <label className={`mb-1 ${labelClassName ?? ""}`}>{label}</label>
        <MultiDatePicker
          range={range}
          placeholder={placeholder ?? label}
          value={typeof value === "string" ? new Date(value) : value}
          inputClass={`form-control ${error ? "is-invalid" : ""}`}
          containerClassName="w-100"
          inputMode="input"
          calendar={isHijri ? HijriCalendar : GregorianCalendar}
          onChange={onLocalChange}
          disabled={disabled}
          locale={
            isAr
              ? isHijri
                ? HijriArabicCalendar
                : GregorianArabicCalendar
              : isHijri
              ? HijiryEnglishCalendar
              : undefined
          }
        />
        <span style={{ pointerEvents: "none" }} className="icon-calendar text-primary fs-4" />
      </div>
      {error && <div className="invalid-feedback">{error}</div>}
    </div>
  );
};

export default DatePicker;
