import { ScientificPublishingLocalItemResult } from "services/scientificPublicationsService/models/scientificPublicationsTypes";
import { CreateScientificPublicationRequest, ScientificPublicationStatus } from "./ScientificPublicationForm.types";

export const scientificPublicationToCreateDto = (
  scientificPublication: ScientificPublishingLocalItemResult,
): CreateScientificPublicationRequest => {
  return {
    scientificPublishingImageId: scientificPublication.scientificPublishingImage.id,
    serialNumber: scientificPublication.serialNumber,
    status:
      scientificPublication.status === "Publishing"
        ? ScientificPublicationStatus.Publishing
        : ScientificPublicationStatus.NotPublishing,
    scientificSpecializationId: scientificPublication.scientificSpecialization.id,
    typeOfScientificId: scientificPublication.typeOfScientific?.id ?? 0,
    scientificClassificationId: scientificPublication.scientificClassification?.id ?? 0,
    languageOfScientificId: scientificPublication.languageOfScientific?.id ?? 0,
    publisherId: scientificPublication.publisher?.id ?? 0,
    publisherTypeId: scientificPublication.publisherType?.id ?? 0,
    numberOfPaper: scientificPublication.numberOfPaper,
    scientificPublicationLink: scientificPublication.scientificPublicationLink,
    metaData: scientificPublication.metaData,
    isHardCopyAvailable: scientificPublication.isHardCopyAvailable,
    authorName: scientificPublication.authorName,
    publicationDate: new Date(scientificPublication.publicationDate),
    searchWords: scientificPublication.searchWords,
    librariesIds: scientificPublication.libraries.map((library) => library.id),
    scientificPublishingDetails: scientificPublication.scientificPublishingDetails,
    scientificPublishingAttachments: scientificPublication.scientificPublishingAttachments,
  };
};
