import IconButton from "components/blocks/IconButton";
import ActionModal from "components/blocks/Modal/ActionModal";
import { useNotification } from "hooks/useNotification";
import { t } from "i18next";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CourseService } from "services/courseService";
import { CourseLocalItemResult } from "services/courseService/models/CourseType";

export const DeleteButton = ({ course }: { course: CourseLocalItemResult | null }) => {
  const { i18n } = useTranslation("Courses");
  const { showNotification } = useNotification();

  const [deleteModalProps, setDeleteModalProps] = useState({
    isOpen: false,
    name: "",
    id: 0,
  });

  const onDeleteConfirm = async () => {
    const res = await CourseService.deleteCourse(deleteModalProps.id);
    if (res.hasError) {
      showNotification({
        type: "error",
        description: t("Common:internalServerError"),
      });
    } else {
      showNotification({
        type: "success",
        description: t("Common:deletedSuccessfully"),
      });
      setDeleteModalProps({ ...deleteModalProps, isOpen: false });
    }
  };

  return (
    <>
      <IconButton
        variant="light"
        innerIconColor="danger"
        icon="icon-delete"
        onClick={() => {
          setDeleteModalProps({
            isOpen: true,
            name:
              course!.courseDetails?.find((x) => x.locale.toLowerCase() === i18n.language.toLowerCase())?.displayName ??
              "",
            id: course!.id,
          });
        }}
      />

      <ActionModal
        isOpen={deleteModalProps.isOpen}
        onClose={() => {
          setDeleteModalProps({
            isOpen: false,
            name: "",
            id: 0,
          });
        }}
        headerMsg={t("Common:areYouSure")}
        subMsg={t("deleteCourseModalSubMsg", {
          name: deleteModalProps.name,
        })}
        actionIcon="icon-delete"
        confirmBtnText={t("Common:yesDelete")}
        iconWrapperColor={"danger"}
        onActionConfirm={onDeleteConfirm}
      />
    </>
  );
};
