import { FileResult } from "services/shared-models/sharedTypes";

export type CourseLocalResult = {
  id: number;
  courseDetail: CourseDetailsResult;
  startDate: string;
  applyCloseDate: string;
  numberOfApply: number;
  status: keyof typeof CourseStatus;
};

export type CreateCourseRequest = {
  status: keyof typeof CourseStatus;
  programCycleId: number;
  startDate: string | Date;
  applyCloseDate: string | Date;
  courseImageId: string;
  bonusCalculationTerms: {
    bonusTypeId: number;
    notes: string;
    fromValue: number;
    toValue: number;
  }[];
  filesIds: string[];
  courseDetails: CourseDetailsResult[];
};

export type BonusCalculationTermItemRequest = {
  id: number;
  bonusType: BonusTypeResult;
  notes: string;
  fromValue: number;
  toValue: number;
};

export type CourseResult = {
  id: number;
  programCycleId: number;
  startDate: string;
  applyCloseDate: string;
};

export type CourseLocalItemResult = {
  id: number;
  serialNumber: string;
  applicantsCount: number;
  courseDetails: CourseDetailsResult[];
  status: keyof typeof CourseStatus;
  startDate: string;
  applyCloseDate: string;
  files: FileResult[];
  courseImage: FileResult;
  bonusCalculationTerms: BonusCalculationTermItemRequest[];
  programCycleDetail: ProgramCycleDetailsResult[];
  programCycleYearDetail: ProgramCycleYearDetailsResult[];
  courseImageUrl?: string;
};

export type ProgramCycleDetailsResult = {
  programCycleId: number;
  displayName: string;
  locale: string;
};

export type ProgramCycleYearDetailsResult = {
  programCycleYearId: number;
  displayName: string;
  locale: string;
};

export type CourseDetailsResult = {
  displayName: string;
  description: string;
  ruleAndConditionName: string;
  ruleAndConditionTemplate: string;
  commitmentName: string;
  commitmentTemplate: string;
  locale: string;
};

export type CoursePublicLocalItemResult = {
  serialNumber: string;
  courseDetail: CourseDetailsResult;
  startDate: string;
  applyCloseDate: string;
  files: FileResult[];
  courseImageUrl?: string;
};

export type BonusTypeResult = {
  id: number;
  bonusTypesDetail: {
    displayName: string;
    locale: string;
  };
};

export const CourseStatus = {
  Completed: {
    id: 1,
    label: "Completed" as const,
  },
  Draft: {
    id: 2,
    label: "Draft" as const,
  },
};
