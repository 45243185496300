import { useQuery } from "hooks/useQuery";
import { useNavigate, useParams } from "react-router-dom";
import { CourseOrderService } from "services/courseOrder";
import { CourseService } from "services/courseService";
import { OrderUserTypes, UserTypes } from "./orderPage.types";
import { useEffect } from "react";
import { BreadcrumbsParamsType, useBreadcrumb } from "contexts/breadcrumb/BreadcrumbContext";
import { TasksService } from "services/tasks";

export const useViewCourseOrder = ({
  userType,
  breadcrumbs,
}: {
  userType: UserTypes;
  breadcrumbs: (props: {
    orderId?: number | string;
    serialNumber?: string;
    serialGuid?: string;
  }) => BreadcrumbsParamsType[];
}) => {
  const navigate = useNavigate();
  const { setBreadcrumbs } = useBreadcrumb();
  const { orderId, serialGID } = useParams();

  //Open Task Api
  const { data: openedTask, loading: isTaskLoading } = useQuery({
    queryFn: async (_: string) => {
      return await TasksService.openPublishingOrderTask(serialGID ?? "");
    },
    options: {
      enabled: !!serialGID && userType !== OrderUserTypes.Admin,
    },
    triggers: [serialGID],
  });

  //Order Api
  const { data: order, loading: isOrderLoading } = useQuery({
    queryFn: async () => {
      const id = orderId ? +orderId : openedTask?.courseOrderId ? openedTask.courseOrderId : 0;

      return await CourseOrderService.getCourseOrderById(id);
    },
    options: {
      enabled: !!openedTask?.courseOrderId || !!orderId,
    },
    triggers: [openedTask?.courseOrderId, orderId],
    /*  onError: () => {
      navigate("/home/tasks");
    }, */
    initialLoading: !!orderId,
  });

  //Course Api
  const { data: course, loading: courseLoading } = useQuery({
    queryFn: async () => {
      const id = order?.courseId ? order.courseId : 0;

      return await CourseService.getCourseById(id);
    },
    triggers: [order?.courseId],
    options: { enabled: !!order?.courseId },
  });

  useEffect(() => {
    if ((openedTask && !openedTask.courseOrderId) || (orderId && !order && !isOrderLoading)) {
      navigate(!!serialGID ? "/home/tasks" : "/home/orders");
    }
  }, [openedTask]);

  useEffect(() => {
    setBreadcrumbs(
      breadcrumbs({
        orderId: order?.id,
        serialNumber: order?.serialNumber,
        serialGuid: serialGID,
      }),
    );
  }, [order]);

  return {
    orderId,
    order,
    course,
    openedTask,
    courseLoading: courseLoading,
    isOrderLoading: isOrderLoading || isTaskLoading,
  };
};
