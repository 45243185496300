import Accordion from "components/blocks/Accordion";
import AccordionItem from "components/blocks/Accordion/AccordionItem";
import Switch from "components/blocks/Switch";
import Grid from "components/GridView/components/Grid";
import { GridSchema } from "components/GridView/GridView.types";
import useSystemDateTheme from "hooks/useSystemDateTheme";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SPFAttachmentModal from "./SPFAttachmentModal";
import { UseFormSetValue } from "react-hook-form";
import {
  CreateScientificPublicationRequest,
  ScientificPublishingAttachment,
  ScientificPublishingAttachmentFileStatus,
} from "../ScientificPublicationForm.types";
import { SelectedFilesType } from "components/blocks/FileUploadWithPreview";
import ActionModal from "components/blocks/Modal/ActionModal";
import { FileService } from "services/fileService";
import useMutation from "hooks/useMutation";
import { ScientificPublishingLocalItemResult } from "services/scientificPublicationsService/models/scientificPublicationsTypes";

export const extensionsMapper = {
  pdf: "pdf",
  doc: "doc",
  docx: "doc",
};

type SPFormAttachmentsTableProps = {
  viewOnly?: boolean;
  setValue?: UseFormSetValue<CreateScientificPublicationRequest>;
  previewData?: ScientificPublishingLocalItemResult["scientificPublishingAttachments"];
  attachments?: ScientificPublishingAttachment[];
};

const SPFormAttachmentsTable: React.FC<SPFormAttachmentsTableProps> = (props) => {
  const { viewOnly, setValue, previewData, attachments } = props;

  const { mutateAsync: downloadFile, loading: fileDownloadLoading } = useMutation({
    queryFn: async (id: string) => {
      await FileService.downloadFile(id);
      return { hasError: false, data: null, description: "", code: 200 };
    },
  });

  const [deleteModalProps, setDeleteModalProps] = useState({
    isOpen: false,
    fileId: "",
  });

  const { toSystemThemeDateFormat } = useSystemDateTheme();
  const { t } = useTranslation("ScientificPublication");
  const [fileModalProps, setFileModalProps] = useState<{
    isOpen: boolean;
    data?: ScientificPublishingAttachment;
  }>({
    isOpen: false,
  });

  const [data, setData] = useState<ScientificPublishingAttachment[]>(previewData || []);

  useEffect(() => {
    if (attachments && setValue) {
      attachments.forEach((attachment) => {
        attachment.status =
          (attachment.status as any) === "Publishing"
            ? ScientificPublishingAttachmentFileStatus.Publishing
            : ScientificPublishingAttachmentFileStatus.NotPublishing;
      });
      setData(attachments);
      setValue("scientificPublishingAttachments", attachments);
    }
  }, [attachments]);

  useEffect(() => {
    if (setValue) {
      setValue("scientificPublishingAttachments", data);
    }
  }, [data]);

  const onFileDownload = (fileId: string) => {
    if (fileDownloadLoading) return;
    downloadFile(fileId);
  };

  const onStatusChange = (row: ScientificPublishingAttachment) => {
    const temp = data.map((item) => {
      if (item.fileId === row.fileId) {
        return { ...item, status: row.status === 1 ? 0 : 1 };
      }
      return item;
    });
    setData(temp);
  };

  const gridSchema: GridSchema[] = [
    {
      field: "status",
      displayName: "",
      showOnMobile: true,
      isHidden: !!viewOnly,
      render(row) {
        return <Switch checked={row.status === 1} onChange={() => onStatusChange(row)} />;
      },
    },
    {
      field: "description",
      displayName: t("Common:description"),
      showOnMobile: true,
    },
    {
      field: "translatedName",
      displayName: t("translatorName"),
    },
    {
      field: "createdOn",
      displayName: t("createdOn"),
      render(row: ScientificPublishingAttachment) {
        return toSystemThemeDateFormat(row.createdOn);
      },
    },
    {
      field: "fileLangId",
      displayName: t("language"),
      render(row: ScientificPublishingAttachment) {
        return row.fileLang?.fileLangDetail.displayName ?? "";
      },
    },
    {
      field: "isAllowedFileToDownload",
      displayName: t("canDownload"),
      render(row: ScientificPublishingAttachment) {
        return row.isAllowedFileToDownload ? t("allowed") : t("notAllowed");
      },
    },
    {
      field: "status",
      displayName: t("Common:status"),
      showOnMobile: true,
      isHidden: !viewOnly,
      render(row: ScientificPublishingAttachment) {
        const statusClassName =
          row.status === 1 || (row.status as any) === "Publishing"
            ? "bg-primary bg-opacity-10 text-primary"
            : "bg-danger bg-opacity-10 text-danger";
        return (
          <span className={`badge rounded-4 ${statusClassName} py-2`}>
            {typeof row.status === "number"
              ? t(row.status === 1 ? "published" : "notpublished")
              : t((row.status as string).toLowerCase())}
          </span>
        );
      },
    },
    {
      field: "fileId",
      displayName: t("file"),
      showOnMobile: true,
      render(row: ScientificPublishingAttachment) {
        const extensionIcon = extensionsMapper[row.file?.extension as keyof typeof extensionsMapper];
        if (!extensionIcon)
          return <span onClick={() => onFileDownload(row.file!.id)} className="icon-document fs-5 cursor-pointer" />;

        return (
          <span onClick={() => onFileDownload(row.file!.id)} className={`icon-${extensionIcon}-big cursor-pointer`} />
        );
      },
    },
    {
      field: "actions",
      displayName: t("Common:actions"),
      showOnMobile: true,
      isHidden: !!viewOnly,
      render(row) {
        return (
          <div className="d-flex gap-2">
            <span role="button" className="icon-edit fs-5" onClick={() => onEdit(row)} />
            <span role="button" className="icon-delete text-danger fs-6" onClick={() => onDelete(row, row.fileId)} />
          </div>
        );
      },
    },
  ];

  const onFileModalClose = () => {
    setFileModalProps({ isOpen: false });
  };

  const onFileModalSubmit = (data: ScientificPublishingAttachment, file: SelectedFilesType) => {
    data.file = {
      id: file.uuid,
      name: file.fileName,
      size: file.size!,
      type: file.file.type,
      extension: file.fileName.split(".").pop()!,
    };
    if (data.id === 0) {
      setData((prev) => [...prev, data]);
    } else {
      setData((prev) => prev.map((item) => (item.id === data.id ? data : item)));
    }
    onFileModalClose();
  };

  const onEdit = (row: ScientificPublishingAttachment) => {
    setFileModalProps({ isOpen: true, data: row });
  };

  const onDelete = (row: ScientificPublishingAttachment, fileId: string) => {
    // TODO: modify on edit (works for add scenario)
    setDeleteModalProps({ isOpen: true, fileId });
  };

  return viewOnly ? (
    <Grid data={data} gridSchema={gridSchema} />
  ) : (
    <>
      <Accordion negatePadding>
        <AccordionItem
          initiallyOpen
          title={t("Common:attachments")}
          elementId="attachments"
          extraHeaders={
            <button
              type="button"
              onClick={() => setFileModalProps((pre) => ({ ...pre, isOpen: true }))}
              className="btn btn-primary align-self-center px-4 mx-4"
            >
              {t("addFile")}
            </button>
          }
        >
          <Grid data={data} gridSchema={gridSchema} />
        </AccordionItem>
      </Accordion>
      <SPFAttachmentModal
        isOpen={fileModalProps.isOpen}
        onClose={onFileModalClose}
        onSubmit={onFileModalSubmit}
        data={fileModalProps.data}
      />
      <ActionModal
        actionIcon="icon-delete"
        headerMsg={t("deleteFile")}
        subMsg={t("deleteFileConfirmation")}
        iconWrapperColor="danger"
        onActionConfirm={() => {
          // TODO: modify on edit (works for add scenario)
          setData((prev) => prev.filter((item) => item.fileId !== deleteModalProps.fileId));
          setDeleteModalProps({ isOpen: false, fileId: "" });
        }}
        isOpen={deleteModalProps.isOpen}
        onClose={() => setDeleteModalProps({ isOpen: false, fileId: "" })}
        confirmBtnText={t("Common:delete")}
      />
    </>
  );
};

export default SPFormAttachmentsTable;
