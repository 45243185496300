import GridView from "components/GridView";
import { GridSchema, GridViewHeaderProps } from "components/GridView/GridView.types";
import { BreadcrumbsParamsType, useBreadcrumb } from "contexts/breadcrumb/BreadcrumbContext";
import useGridData from "hooks/useGridData";
import useSystemDateTheme from "hooks/useSystemDateTheme";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CourseOrderLocalResult } from "services/courseOrder/models/CourseOrderType";

type OrdersGridViewProps = {
  courseId?: string | number;
  userId?: string;
  columnsToHide?: (
    | "id"
    | "courseName"
    | "applicantName"
    | "publicationType"
    | "applicationDate"
    | "centreManagerName"
    | "courseOrderPhase"
    | "responsibleEntity"
    | "courseOrderStatus"
    | "courseSerialNumber"
    | "actions"
  )[];
  breadcrumbs?: BreadcrumbsParamsType[];
};

const defaultColumnsToHide: OrdersGridViewProps["columnsToHide"] = ["actions", "courseSerialNumber"];
const defaultBreadcrumbs: BreadcrumbsParamsType[] = [
  {
    localizationKey: "allOrders",
    path: "/home/orders",
    menuItemId: "allOrders",
  },
];

const OrdersGridView: React.FC<OrdersGridViewProps> = ({ courseId, userId, columnsToHide, breadcrumbs }) => {
  breadcrumbs = breadcrumbs ? breadcrumbs : defaultBreadcrumbs;
  columnsToHide = columnsToHide ? columnsToHide : defaultColumnsToHide;

  const { t } = useTranslation("Orders");
  const navigate = useNavigate();
  const { setBreadcrumbs } = useBreadcrumb();
  const { toSystemThemeDateFormat } = useSystemDateTheme();

  const { loading, gridData, totalCount, exportToExcel } = useGridData<"courseOrderService", CourseOrderLocalResult>({
    service: "courseOrderService",
    endpoint: "getAllCourseOrders",
    exportExcelEndpoint: "exportMyCourseOrderToExcel",
    exportFileName: "courseOrder",
    localizationNameSpace: "CourseOrders",
    defaultFilters: [
      ...(courseId ? [{ field: "CourseId", value: courseId, operator: "equal" }] : []),
      ...(userId ? [{ field: "CreatedBy", value: userId, operator: "equal" }] : []),
    ],
  });

  useEffect(() => {
    setBreadcrumbs(breadcrumbs ?? []);
  }, []);

  const gridSchema = useMemo<GridSchema[]>(
    () => [
      {
        field: "id",
        displayName: t("orderNumber"),
        sortable: true,
        filterable: true,
        showOnMobile: true,
        isHidden: columnsToHide?.includes("id"),
        render: (row: CourseOrderLocalResult) => {
          return (
            <button className="btn btn-link" onClick={() => navigate(`/home/orders/${row.id}`)}>
              {row.serialNumber}
            </button>
          );
        },
      },
      {
        field: "CourseDetail.DisplayName",
        displayName: t("courseName"),
        isHidden: columnsToHide?.includes("courseName"),
        render: (row: CourseOrderLocalResult) => {
          return <div>{row.courseDetail.displayName}</div>;
        },
      },
      {
        field: "appliedUserName",
        displayName: t("applicantName"),
        isHidden: columnsToHide?.includes("applicantName"),
      },
      {
        field: "courseSerialNumber",
        displayName: t("courseNumber"),
        isHidden: columnsToHide?.includes("courseSerialNumber"),
      },
      {
        field: "publicationType",
        displayName: t("publicationTypeCategory"),
        isHidden: columnsToHide?.includes("publicationType"),
        render(row: CourseOrderLocalResult) {
          return `${row.typeOfScientific?.typeOfScientificDetail.displayName ?? ""} - ${
            row.scientificClassification?.scientificClassificationDetail?.displayName ?? ""
          }`;
        },
      },
      {
        field: "applicationDate",
        displayName: t("applicationDate"),
        isHidden: columnsToHide?.includes("applicationDate"),
        render: (row: CourseOrderLocalResult) => {
          return toSystemThemeDateFormat(row.createdOn);
        },
      },
      {
        field: "centreManagerName",
        displayName: t("researchCenter"),
        isHidden: columnsToHide?.includes("centreManagerName"),
      },
      {
        field: "courseOrderPhase",
        displayName: t("orderPhase"),
        isHidden: columnsToHide?.includes("courseOrderPhase"),
      },
      {
        field: "responsibleEntity",
        displayName: t("responsibleEntity"),
        isHidden: columnsToHide?.includes("responsibleEntity"),
      },
      {
        field: "courseOrderStatus",
        displayName: t("orderStatus"),
        showOnMobile: true,
        isHidden: columnsToHide?.includes("courseOrderStatus"),
        render: (row: CourseOrderLocalResult) => {
          const statusClassName =
            row.courseOrderStatus === "Completed" ? "bg-success text-success " : "bg-primary text-primary";
          return (
            <span className={`badge bg-opacity-10  rounded-4 ${statusClassName} py-2`}>{t(row.courseOrderStatus)}</span>
          );
        },
      },
    ],
    [t, toSystemThemeDateFormat, columnsToHide],
  );

  const viewHeader: GridViewHeaderProps = {
    title: t("orders"),
    singularName: t("order"),
    totalCount: totalCount,
    onExportClick: exportToExcel,
  };

  return (
    <GridView
      loading={loading}
      gridProps={{
        data: gridData ?? [],
        gridSchema,
      }}
      viewHeaderProps={viewHeader}
    />
  );
};

export default OrdersGridView;
