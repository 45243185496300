import { DataGridRequest } from "services/shared-models/sharedTypes";
import { PagedResponseType } from "services/shared-models/sharedTypes";
import { AuthorResult } from "./models/authorsTypes";
import { HttpClient } from "helpers";
import { AxiosResponse } from "axios";

export const AuthorsService = {
  getAllAuthors: async (dataGridRequest: DataGridRequest) => {
    return await HttpClient.post<PagedResponseType<AuthorResult>>(`/api/Authors/GetAllAuthors`, dataGridRequest);
  },
  exportToExcel: async (dataGridRequest: DataGridRequest) => {
    return await HttpClient.post<AxiosResponse<Blob>>(`/api/Authors/ExportAuthorsToExcel`, dataGridRequest, {
      responseType: "blob",
    });
  },
};
