import { PageHeaderProps, TabProps } from "components/PageWithTabs";
import { BreadcrumbsParamsType } from "contexts/breadcrumb/BreadcrumbContext";
import { Dispatch, ReactNode, SetStateAction } from "react";
import { CourseOrderLocalItemResult } from "services/courseOrder/models/CourseOrderType";
import { CourseLocalItemResult } from "services/courseService/models/CourseType";
import { OpenPublishingTaskResult } from "services/tasks/models/tasksTypes";

export const sections = {
  applicantInfoWithDetails: "applicantInfoWithDetails",
  orderDerails: "orderDerails",
  updates: "updates",
  achievementFile: "achievementFile",
  researchesCenter: "researchesCenter",
  procedures: "procedures",
  examinerSection: "examinerSection",
  userBankingInfo: "userBankingInfo",
  updateNotesSection: "updateNotesSection",
  progressCertificate: "ProgressCertificate",
  rewards: "rewards",
  publishingHouse: "publishingHouse",
  judges: "judges",
  scientificPublication: "scientificPublication",
  scientificPublicationLibraries: "scientificPublicationLibraries",
  assignPermission: "assignPermission",
};

export type SectionsIds = keyof typeof sections;

export const tabsIds = {
  info: "info",
  minutesOfMeeting: "minutesOfMeeting",
  letters: "letters",
  attachments: "attachments",
  history: "history",
  courseInfo: "courseInfo",
} as const;

export type TapsIds = keyof typeof tabsIds;

export type ViewOrderPageProps = {
  headersToView?: (props: {
    courseOrderData: CourseOrderLocalItemResult | null;
    courseData: CourseLocalItemResult | null;
    selectedTabId: string;
  }) => PageHeaderProps[];
  tapsToView: (props: {
    courseOrderData: CourseOrderLocalItemResult | null;
    courseData: CourseLocalItemResult | null;
    openedTask: OpenPublishingTaskResult | null;
    userType: UserTypes;
    activePage: ActivePages;
    setActivePage: Dispatch<SetStateAction<ActivePages>>;
  }) => TabProps[];
  breadcrumbs: (props: {
    orderId?: number | string;
    serialNumber?: string;
    serialGuid?: string;
  }) => BreadcrumbsParamsType[];
  leftSection?: (props: {
    selectedTabId: string;
    courseOrderData: CourseOrderLocalItemResult | null;
    courseData: CourseLocalItemResult | null;
    openedTask: OpenPublishingTaskResult | null;
  }) => ReactNode;
  userType: UserTypes;

  title?: ReactNode;
  withImage?: boolean;
  subTitle?: string | ReactNode | ReactNode[];
  headerContainerClassName?: string;
  backButtonCallback?: (activeTabId: string) => void;
  tapContentContainerClassName?: string;
  keepTabsMounted?: boolean;
  wrapperClassName?: string;
  defaultTabId?: TapsIds;
};

export const OrderUserTypes = {
  Admin: "Admin",
  Student: "Student",
  StudentForPublishedOrder: "StudentForPublishedOrder",
} as const;

export type UserTypes = keyof typeof OrderUserTypes;

export const OrderActivePages = {
  Default: "Default",
} as const;

export type ActivePages = keyof typeof OrderActivePages;
