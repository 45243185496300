import { useTranslation } from "react-i18next";
import FormInput from "components/blocks/FormInput";
import { useState } from "react";
import PublicationBookCard from "./PublicationBookCard";
import { useQuery } from "hooks/useQuery";
import { LookupService } from "services/lookupService";
import { ScientificPublicationsService } from "services/scientificPublicationsService";
import { DataGridRequest } from "services/shared-models/sharedTypes";
import { OperatorFilter } from "services/shared-models/sharedEnums";

const PublicationsGallery = () => {
  const { t, i18n } = useTranslation("WelcomePage");

  const [search, setSearch] = useState("");
  const [triggerSearchOnClick, setTriggerSearchOnClick] = useState(false);
  const [selectedPublicationType, setSelectedPublicationType] = useState(-1);

  const { data, loading } = useQuery({
    queryFn: async () => {
      return await LookupService.getAllTypeOfScientificDropDown();
    },
  });

  const { data: allPublicScientificPublishing, loading: allPublicScientificPublishingLoading } = useQuery({
    queryFn: async () => {
      const filter: DataGridRequest["filter"] =
        selectedPublicationType === -1
          ? []
          : [
              {
                field: "TypeOfScientificId",
                value: selectedPublicationType,
                operator: OperatorFilter.equal,
              },
            ];

      return await ScientificPublicationsService.getAllPublicScientificPublishing({
        search: search ? search : undefined,
        pageIndex: 1,
        pageSize: 10,
        filter: filter,
      });
    },
    triggers: [selectedPublicationType, triggerSearchOnClick],
  });

  return (
    <div>
      {/* Search box */}
      <div className="d-flex gap-3">
        <FormInput
          placeholder={t("Common:search")}
          wrapperClassName="flex-1"
          onChange={(e) => setSearch(e.target.value)}
        />
        <button className="btn btn-primary px-4" onClick={() => setTriggerSearchOnClick(!triggerSearchOnClick)}>
          {t("Common:search")}
        </button>
      </div>
      {/* Type Filter */}
      <div className="d-flex gap-3 my-4 flex-wrap">
        {!loading ? (
          [
            {
              id: -1,
              details: [
                {
                  displayName: "all",
                  locale: "En",
                },
                {
                  displayName: "الكل",
                  locale: "Ar",
                },
              ],
            },
          ]
            .concat(data ?? [])
            ?.map((type) => (
              <button
                key={type.id}
                className={`btn ${
                  selectedPublicationType === type.id ? "btn-primary" : "btn-outline-secondary"
                } px-4 rounded-5`}
                onClick={() => setSelectedPublicationType(type.id)}
              >
                {type.details.find((x) => x.locale.toLowerCase() === i18n.language.toLowerCase())?.displayName}
              </button>
            ))
        ) : (
          <div className="custom-loader"></div>
        )}
      </div>

      {/* Publications List */}
      <div className="d-flex gap-4 flex-wrap">
        {allPublicScientificPublishingLoading ? (
          <div className="custom-loader"></div>
        ) : (
          allPublicScientificPublishing?.map((ele, index) => (
            <PublicationBookCard key={index} scientificPublishing={ele} />
          ))
        )}
      </div>
    </div>
  );
};

export default PublicationsGallery;
