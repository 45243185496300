import { HttpClient } from "helpers";
import { BaseResponseType, DataGridRequest } from "services/shared-models/sharedTypes";
import { GetAllTasksResponse, OpenPublishingTaskResult } from "./models/tasksTypes";
import { AxiosResponse } from "axios";

export const TasksService = {
  getMyTasks: async (data: DataGridRequest) => {
    const dummy: GetAllTasksResponse = {
      code: 200,
      description: "ok",
      hasError: false,
      data: {
        totalCount: 1,
        result: [
          {
            activityName: "sample data",
            description: "sample data",
            folio: "sample data",
            isOpen: true,
            originatorUserName: "sample data",
            serialGID: "sample data",
            startDateTime: new Date().toISOString(),
            taskUrl: "sample data",
            workflowName: "sample data",
          },
        ],
      },
    };
    return dummy;

    // return await HttpClient.post<GetAllTasksResponse>("/api/Tasks/GetMyTasks", data);
  },
  exportTasksToExcel: async (data: DataGridRequest) => {
    return await HttpClient.post<AxiosResponse<Blob>>("/api/Tasks/ExportTasksToExcel", data, {
      responseType: "blob",
    });
  },

  openPublishingOrderTask: async (serialGID: string) => {
    return await HttpClient.get<BaseResponseType<OpenPublishingTaskResult>>(
      `/api/Tasks/OpenPublishingOrderTask/${serialGID}`,
    );
  },
};
