import Accordion from "components/blocks/Accordion";
import AccordionItem from "components/blocks/Accordion/AccordionItem";
import Dropdown from "components/blocks/Dropdown";
import FilePreview from "components/blocks/FileInput/FilePreview";
import FormInput from "components/blocks/FormInput";
import { Ref, RefObject, forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import SpeechesTable from "./SpeechesTable";
import { useForm } from "react-hook-form";
import { getSpeechesSchema } from "./speeches.schema";
import PaginatedLookupDropdown, { DropdownItemType } from "components/blocks/PaginatedLookupDropdown";
import useMutation from "hooks/useMutation";
import Modal from "components/blocks/Modal";
import RichTextEditor from "components/RichTextEditor";
import LoadingOverlay from "components/blocks/LoadingOverlay";
import { OrdersService } from "services/ordersService";
import { PdfTemplateLocalResult } from "services/templatesService/models/templatesTypes";
import { CreateCourseSpeechesRequest } from "services/courseSpeechesService/Model/CourseSpeechesTypes";
import { CourseSpeechesService } from "services/courseSpeechesService";
import { AdminTapActionsRef } from "../../UserTypeOrderView/CourseOrderAdminView";

const SpeechesTab = ({
  submitRef,
  courseOrderId,
}: {
  submitRef: RefObject<AdminTapActionsRef>;
  courseOrderId: number;
}) => {
  const formRef = useRef<HTMLFormElement>(null);
  const { t } = useTranslation(["Orders", "Common"]);

  const [isTemplateModalOpened, setIsTemplateModalOpened] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<{
    isExported: boolean;
    template?: PdfTemplateLocalResult;
  }>();
  const {
    formState: { errors },
    handleSubmit,
    register,
    setValue,
    reset,
  } = useForm<CreateCourseSpeechesRequest>({
    values: {
      courseOrderId: courseOrderId,
      pdfTemplateId: 0,
      description: "",
      selectedLocale: "En",
    },
    resolver: getSpeechesSchema(t),
  });

  const { data, mutateAsync, loading } = useMutation({
    queryFn: async (values: CreateCourseSpeechesRequest) => {
      return await CourseSpeechesService.createCourseSpeeches(values);
    },
  });

  const onSelectTemplate = (value: string, _: DropdownItemType, options: PdfTemplateLocalResult[]) => {
    const template = options.find((o) => o.id === +value);

    setValue("pdfTemplateId", +value);
    setSelectedTemplate({
      isExported: false,
      template: template,
    });
  };

  const onSubmit = async (values: CreateCourseSpeechesRequest) => {
    await mutateAsync(values);
    setSelectedTemplate(undefined);
    reset();
  };

  const onExportResult = () => {
    setSelectedTemplate({
      ...selectedTemplate,
      isExported: true,
    });
  };

  useImperativeHandle(submitRef, () => ({
    submit: async () => {
      formRef.current?.classList.add("was-validated");
      await handleSubmit(async (values) => {
        await onSubmit(values);
      })();
    },
  }));

  return (
    <form ref={formRef} className="position-relative" noValidate onSubmit={handleSubmit(onSubmit)}>
      <LoadingOverlay visible={loading} />
      <Accordion>
        <AccordionItem initiallyOpen title={t("newSpeech")} elementId={"new-speech"}>
          <div className="d-flex flex-column gap-2 pb-4 border-bottom border-1">
            <div className="d-flex flex-wrap gap-2 align-items-start">
              <PaginatedLookupDropdown
                label={t("speechesTemplates")}
                wrapperClassName="flex-1"
                service="templateService"
                endpoint="getAllPdfTemplates"
                queryKey="search"
                isPaginated
                idValueKey="id"
                textValueKey="pdfTemplateDetail.displayName"
                {...register("pdfTemplateId")}
                onChange={onSelectTemplate}
                error={errors.pdfTemplateId?.message}
              />
              <FormInput
                label={t("description")}
                wrapperClassName="flex-2"
                {...register("description")}
                error={errors.description?.message}
              />
              <Dropdown
                data={[
                  { id: "En", value: "En" },
                  { id: "Ar", value: "Ar" },
                ]}
                label={t("templateLanguage")}
                wrapperClassName="flex-1"
                {...register("selectedLocale")}
                onChange={(value) => {
                  setValue("selectedLocale", value as any);
                }}
              />
              <button
                type="button"
                className={`btn btn-primary rounded p-10px align-self-${Object.keys(errors).length ? "center" : "end"}`}
                onClick={onExportResult}
                disabled={!selectedTemplate}
              >
                {t("release")}
              </button>
            </div>
            <div className="d-flex ">
              {selectedTemplate?.isExported && (
                <FilePreview
                  callbackValue={selectedTemplate?.template?.id}
                  title={selectedTemplate?.template?.pdfTemplateDetail.displayName}
                  showButtonLabel={t("Common:show")}
                  onDelete={() => {
                    setSelectedTemplate(undefined);
                  }}
                  onShow={() => {
                    setIsTemplateModalOpened(true);
                  }}
                />
              )}
            </div>
          </div>
        </AccordionItem>
      </Accordion>

      <Accordion>
        <AccordionItem initiallyOpen title={t("speeches")} elementId={"previous-speeches"}>
          <SpeechesTable courseOrderId={courseOrderId} createdSpeech={data} />
        </AccordionItem>
      </Accordion>

      <Modal
        isOpen={isTemplateModalOpened}
        onClose={() => setIsTemplateModalOpened(false)}
        title={selectedTemplate?.template?.pdfTemplateDetail.displayName}
        containerClassName="bg-white"
        size="lg"
      >
        <RichTextEditor isReadOnly initialData={selectedTemplate?.template?.pdfTemplateDetail.templateContent ?? ""} />
      </Modal>
    </form>
  );
};

export default SpeechesTab;
