import i18next from "i18next";
import Cookies from "js-cookie";
import { TranslationModel } from "./models/translationModel";
import LoginArView from "../../views/account/login/i18n/ar.json";
import LoginEnView from "../../views/account/login/i18n/en.json";
import RegisterEnView from "../../views/account/register/i18n/en.json";
import RegisterArView from "../../views/account/register/i18n/ar.json";
import SharedEnView from "../../views/home/shared/i18n/en.json";
import SharedArView from "../../views/home/shared/i18n/ar.json";
import SetPasswordEnView from "../../views/account/setPassword/i18n/en.json";
import SetPasswordArViewAr from "../../views/account/setPassword/i18n/ar.json";
import CommonAr from "./common/ar.json";
import CommonEn from "./common/en.json";
import TasksAr from "../../views/home/tasks/i18n/ar.json";
import TasksEn from "../../views/home/tasks/i18n/en.json";
import WelcomePageAr from "../../views/welcomePage/i18n/ar.json";
import WelcomePageEn from "../../views/welcomePage/i18n/en.json";
import CourseAr from "../../views/home/courses/i18n/ar.json";
import CourseEn from "../../views/home/courses/i18n/en.json";
import OrdersEn from "../../views/home/orders/i18n/en.json";
import OrdersAr from "../../views/home/orders/i18n/ar.json";
import AuthorsAr from "../../views/home/authorsManagement/i18n/ar.json";
import AuthorsEn from "../../views/home/authorsManagement/i18n/en.json";
import ExaminersEn from "../../views/home/examinersManagement/i18n/en.json";
import ExaminersAr from "../../views/home/examinersManagement/i18n/ar.json";
import JudgesEn from "../../views/home/judgesManagement/i18n/en.json";
import JudgesAr from "../../views/home/judgesManagement/i18n/ar.json";
import FormTemplatesAr from "../../views/home/formTemplates/i18n/ar.json";
import FormTemplatesEn from "../../views/home/formTemplates/i18n/en.json";
import ScientificPublicationAr from "../../views/home/scientificPublication/i18n/ar.json";
import ScientificPublicationEn from "../../views/home/scientificPublication/i18n/en.json";
import UserProfileAr from "../../views/home/userProfile/i18n/ar.json";
import UserProfileEn from "../../views/home/userProfile/i18n/en.json";
import EvaluationFormsAr from "../../views/home/evaluationForms/i18n/ar.json";
import EvaluationFormsEn from "../../views/home/evaluationForms/i18n/en.json";
import OrderActionsGridEn from "../../views/home/orders/OrderActionsGrid/i18n/en.json";
import OrderActionsGridAr from "../../views/home/orders/OrderActionsGrid/i18n/ar.json";

export function localizationInit() {
  i18next.init({
    interpolation: { escapeValue: false }, // React already does escaping
    fallbackLng: ["ar", "en"],
    lng: "ar",
    resources: {
      ar: {
        LoginView: LoginArView,
        RegisterView: RegisterArView,
        Shared: SharedArView,
        SetPasswordView: SetPasswordArViewAr,
        Common: CommonAr,
        Tasks: TasksAr,
        WelcomePage: WelcomePageAr,
        Courses: CourseAr,
        Orders: OrdersAr,
        Authors: AuthorsAr,
        Examiners: ExaminersAr,
        Judges: JudgesAr,
        FormTemplates: FormTemplatesAr,
        ScientificPublication: ScientificPublicationAr,
        UserProfile: UserProfileAr,
        EvaluationForms: EvaluationFormsAr,
        OrderActionsGrid: OrderActionsGridAr,
      },
      en: {
        LoginView: LoginEnView,
        Shared: SharedEnView,
        RegisterView: RegisterEnView,
        SetPasswordView: SetPasswordEnView,
        Common: CommonEn,
        Tasks: TasksEn,
        WelcomePage: WelcomePageEn,
        Courses: CourseEn,
        Orders: OrdersEn,
        Authors: AuthorsEn,
        Examiners: ExaminersEn,
        Judges: JudgesEn,
        FormTemplates: FormTemplatesEn,
        ScientificPublication: ScientificPublicationEn,
        UserProfile: UserProfileEn,
        EvaluationForms: EvaluationFormsEn,
        OrderActionsGrid: OrderActionsGridEn,
      },
    },
  });
  const localizationStr = Cookies.get("localization");
  if (localizationStr) {
    const localization: TranslationModel = JSON.parse(localizationStr);
    i18next.changeLanguage(localization.CurrentLanguage);
    if (localization.isRTL) {
      document.body.classList.add("rtl");
    } else {
      document.body.classList.remove("rtl");
    }
    document.documentElement.lang = localization.CurrentLanguage;
  } else {
    const localization: TranslationModel = {
      CurrentLanguage: "ar",
      isRTL: true,
    };
    Cookies.set("localization", JSON.stringify(localization));

    i18next.changeLanguage(localization.CurrentLanguage);
    if (localization.isRTL) {
      document.body.classList.add("rtl");
    } else {
      document.body.classList.remove("rtl");
    }
  }
}

export const languageChange = (currentLanguage: string) => {
  const isRtl = currentLanguage === "ar";
  // const direction = currentLanguage === "ar" ? "rtl" : "";
  const locale: TranslationModel = {
    CurrentLanguage: currentLanguage,
    isRTL: isRtl,
  };
  localStorage.setItem("localization", JSON.stringify(locale));
  if (isRtl) document.body.classList.add("rtl");
  else document.body.classList.remove("rtl");
  document.documentElement.lang = currentLanguage;
  i18next.changeLanguage(currentLanguage);
};

export const toArabicNumbers = (value: string) => {
  if (!value) {
    return "";
  }
  return value.replace(/\d/g, (d: any) => "٠١٢٣٤٥٦٧٨٩"[d]).replace(/\./g, "٫");
};
