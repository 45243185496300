import IconButton from "components/blocks/IconButton";
import GridView from "components/GridView";
import { GridSchema, GridViewHeaderProps } from "components/GridView/GridView.types";
import { useBreadcrumb } from "contexts/breadcrumb/BreadcrumbContext";
import useGridData from "hooks/useGridData";
import { useNotification } from "hooks/useNotification";
import useSystemDateTheme from "hooks/useSystemDateTheme";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FormTemplateResult } from "services/formTemplatesService/models/formTemplatesTypes";

const FormTemplatesGridView = () => {
  const { t } = useTranslation("FormTemplates");
  const { setBreadcrumbs } = useBreadcrumb();
  const { toSystemThemeDateFormat } = useSystemDateTheme();
  const { showNotification } = useNotification();

  const { loading, gridData, totalCount, exportToExcel } = useGridData<"formTemplatesService", FormTemplateResult>({
    service: "formTemplatesService",
    endpoint: "getAllFormTemplates",
    exportExcelEndpoint: "exportToExcel",
    exportFileName: "formTemplates",
    localizationNameSpace: "FormTemplates",
  });

  useEffect(() => {
    setBreadcrumbs([
      {
        localizationKey: "formTemplates",
        path: "/home/formTemplates",
        menuItemId: "formTemplates",
      },
    ]);
  }, []);

  const onRowDragEnd = ({ from, to }: { from: number; to: number }) => {
    showNotification({
      description: `نقل من ${from + 1} الى ${to + 1}`,
      type: "notification",
    });
  };

  const gridSchema = useMemo<GridSchema[]>(
    () => [
      {
        field: "order",
        displayName: "#",
        showOnMobile: true,
      },
      {
        field: "templateName",
        displayName: t("templateName"),
        filterable: true,
        showOnMobile: true,
      },
      {
        field: "publicationType",
        displayName: t("publicationType"),
      },
      {
        field: "publicationCategory",
        displayName: t("publicationCategory"),
      },
      {
        field: "createdOn",
        displayName: t("createdOn"),
        render: (row: FormTemplateResult) => {
          return toSystemThemeDateFormat(row.createdOn);
        },
      },
      {
        field: "status",
        displayName: t("Common:status"),
        render: (row: FormTemplateResult) => {
          return t(row.status.toLocaleLowerCase());
        },
      },
      {
        field: "lastModifiedBy",
        displayName: t("lastModifiedBy"),
        render: (row: FormTemplateResult) => {
          return `${row.lastModifiedBy} - ${toSystemThemeDateFormat(row.lastModifiedOn)}`;
        },
      },
      {
        field: "id",
        displayName: t("Common:actions"),
        showOnMobile: true,
        render: (row: FormTemplateResult) => {
          return (
            <div className="d-flex gap-2">
              <IconButton
                title={t("Common:edit")}
                icon="icon-edit"
                fitToIconWidth
                innerIconColor="dark"
                size="md"
                onClick={() => {
                }}
              />
              <IconButton
                title={t("Common:delete")}
                icon="icon-delete"
                fitToIconWidth
                innerIconColor="danger"
                size="md"
                onClick={() => {
                }}
              />
            </div>
          );
        },
      },
    ],
    [t, toSystemThemeDateFormat],
  );

  const viewHeader: GridViewHeaderProps = {
    title: t("formTemplates"),
    singularName: t("formTemplate"),
    totalCount: totalCount,
    onExportClick: exportToExcel,
    addPageRoute: "/home/formTemplates/add",
  };

  return (
    <GridView
      loading={loading}
      gridProps={{
        data: gridData ?? [],
        gridSchema,
        draggable: true,
        onRowDragEnd: onRowDragEnd,
      }}
      viewHeaderProps={viewHeader}
    />
  );
};

export default FormTemplatesGridView;
