import { FileResponse } from "services/shared-models/sharedTypes";

export interface CreateScientificPublicationRequest {
  serialNumber: string;
  authorName: string;
  publicationDate: Date;
  scientificSpecializationId: number;
  typeOfScientificId: number;
  scientificClassificationId: number;
  languageOfScientificId: number;
  publisherId: number;
  publisherTypeId: number;
  numberOfPaper: number;
  scientificPublicationLink: string;
  scientificPublishingImageId: string;
  metaData: string;
  isHardCopyAvailable: boolean;
  status: ScientificPublicationStatus;
  scientificPublishingDetails: ScientificPublishingDetail[];
  searchWords: SearchWord[];
  librariesIds: number[];
  scientificPublishingAttachments: ScientificPublishingAttachment[];
}

export interface SearchWord {
  searchWord: string;
}

export interface ScientificPublishingAttachment {
  id?: number;
  fileId: string;
  description: string;
  translatedName: string;
  fileLangId: number;
  isAllowedFileToDownload: boolean;
  file?: FileResponse;
  fileLang?: FileLangResult;
  status: ScientificPublishingAttachmentFileStatus;
  createdOn?: string;
}

export type FileLangResult = {
  id: number;
  fileLangDetail: {
    displayName: string;
    locale?: string;
  };
};

interface ScientificPublishingDetail {
  displayName: string;
  description: string;
  locale: string;
}

export enum ScientificPublicationStatus {
  Publishing = 1,
  NotPublishing = 2,
}

export enum ScientificPublishingAttachmentFileStatus {
  Publishing = 1,
  NotPublishing = 2,
}
