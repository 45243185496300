import LoadingOverlay from "components/blocks/LoadingOverlay";
import PageWithTabs from "components/PageWithTabs";
import { useTranslation } from "react-i18next";
import { useViewCourseOrder } from "./useViewCourseOrder";
import { ActivePages, ViewOrderPageProps } from "./orderPage.types";
import { useState } from "react";

const OrderView = (props: ViewOrderPageProps) => {
  const { t, i18n } = useTranslation("Orders");

  const [activePage, setActivePage] = useState<ActivePages>("Default");
  const { isOrderLoading, orderId, courseLoading, course, order, openedTask } = useViewCourseOrder({
    userType: props.userType,
    breadcrumbs: props.breadcrumbs,
  });

  const subTitle = order ? `${t("Cycles:cycleNumber")}: ${"12-2024"} • ${t("Cycles:theCycle")}: ${"1-2024"}` : "";

  return (
    <>
      <LoadingOverlay visible={isOrderLoading || courseLoading} position="fixed" />
      <PageWithTabs
        title={
          props.title ?? (
            <div className="d-flex gap-3 align-items-center">
              {course?.courseDetails.find((c) => c.locale.toLowerCase() === i18n.language.toLowerCase())?.displayName ??
                ""}
              <span role="button" className="text-primary icon-view" />
            </div>
          )
        }
        backButtonCallback={props.backButtonCallback}
        headerContainerClassName={props.headerContainerClassName}
        tapContentContainerClassName={props.tapContentContainerClassName}
        keepTabsMounted={props.keepTabsMounted}
        wrapperClassName={props.wrapperClassName}
        titleImgSrc={props.withImage ? course?.courseImageUrl : ""}
        subTitle={props.subTitle ?? subTitle}
        defaultTabId={props.defaultTabId ? props.defaultTabId : "info"}
        pageHeaders={
          props.headersToView
            ? (selectedTabId) =>
                props.headersToView!({
                  courseData: course,
                  courseOrderData: order,
                  selectedTabId,
                })
            : []
        }
        actions={
          props.leftSection
            ? (selectedTapId) => {
                return props.leftSection!({
                  courseData: course,
                  courseOrderData: order,
                  selectedTabId: selectedTapId,
                  openedTask: openedTask,
                });
              }
            : undefined
        }
        tabs={props.tapsToView({
          courseData: course,
          courseOrderData: order,
          openedTask: openedTask,
          userType: props.userType,
          activePage,
          setActivePage,
        })}
      />
    </>
  );
};

export default OrderView;
