import { HttpClient } from "helpers/http/httpClient";
import { DataGridRequest, PagedResult, FileDownload, BaseResponseType } from "services/shared-models/sharedTypes";
import {
  CourseLocalItemResult,
  CourseLocalResult,
  CoursePublicLocalItemResult,
  CourseResult,
  CreateCourseRequest,
} from "./models/CourseType";

export const CourseService = {
  getAllCourses: async (request: DataGridRequest) => {
    return await HttpClient.post<BaseResponseType<PagedResult<CourseLocalResult>>>(
      "/api/Course/GetAllCourses",
      request,
    );
  },

  getCourseById: async (id: number) => {
    return await HttpClient.get<BaseResponseType<CourseLocalItemResult>>(`/api/Course/GetCourseById/${id}`);
  },

  createCourse: async (request: CreateCourseRequest) => {
    return await HttpClient.post<BaseResponseType<CourseResult>>("/api/Course/CreateCourse", request);
  },

  updateCourse: async (id: number, request: CreateCourseRequest) => {
    return await HttpClient.put<BaseResponseType<CourseResult>>(`/api/Course/UpdateCourse/${id}`, request);
  },

  deleteCourse: async (id: number) => {
    return await HttpClient.delete<BaseResponseType<CourseResult>>(`/api/Course/DeleteCourse/${id}`);
  },

  exportCourseToExcel: async (request: DataGridRequest) => {
    return await HttpClient.post<FileDownload>("/api/Course/ExportCourseToExcel", request, {
      responseType: "blob",
    });
  },

  getPublicCourseById: async (id: number) => {
    return await HttpClient.get<BaseResponseType<CoursePublicLocalItemResult>>(`/api/Course/GetPublicCourseById/${id}`);
  },

  getActiveCourse: async () => {
    return await HttpClient.get<BaseResponseType<number | undefined>>("/api/Course/GetActiveCourse");
  },
};
