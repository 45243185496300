import GridView from "components/GridView";
import { GridSchema, GridViewHeaderProps } from "components/GridView/GridView.types";
import IconButton from "components/blocks/IconButton";
import ActionModal from "components/blocks/Modal/ActionModal";
import { useBreadcrumb } from "contexts/breadcrumb/BreadcrumbContext";
import useGridData from "hooks/useGridData";
import { useNotification } from "hooks/useNotification";
import useSystemDateTheme from "hooks/useSystemDateTheme";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CourseOrderService } from "services/courseOrder";
import { CourseOrderLocalResult, CourseOrderStatus } from "services/courseOrder/models/CourseOrderType";

type MyAllOrdersGridViewProps = {
  courseId?: string | number;
  userId?: string;
  columnsToHide?: string[];
};

const MyAllOrdersGridView: React.FC<MyAllOrdersGridViewProps> = ({ courseId, userId, columnsToHide }) => {
  const { t } = useTranslation("Orders");
  const navigate = useNavigate();
  const { showNotification } = useNotification();
  const { setBreadcrumbs } = useBreadcrumb();
  const { toSystemThemeDateFormat } = useSystemDateTheme();

  const [deleteModalProps, setDeleteModalProps] = useState({
    isOpen: false,
    name: "",
    id: 0,
  });

  const { loading, gridData, totalCount, exportToExcel, fetchGridData } = useGridData<
    "courseOrderService",
    CourseOrderLocalResult
  >({
    service: "courseOrderService",
    endpoint: "getAllMyCourseOrder",
    exportExcelEndpoint: "exportMyCourseOrderToExcel",
    exportFileName: "courseOrder",
    localizationNameSpace: "CourseOrders",
  });

  useEffect(() => {
    setBreadcrumbs([
      {
        localizationKey: "myAllOrders",
        path: "/home/authorOrders/MyAllOrders",
        menuItemId: "allOrders",
      },
    ]);
  }, []);

  const gridSchema = useMemo<GridSchema[]>(
    () => [
      {
        field: "id",
        displayName: t("orderNumber"),
        sortable: true,
        filterable: true,
        showOnMobile: true,
        isHidden: columnsToHide?.includes("id"),
        render: (row: CourseOrderLocalResult) => {
          return (
            <button className="btn btn-link" onClick={() => navigate(`/home/orders/unpublishedOrder/${row.id}`)}>
              {row.serialNumber}
            </button>
          );
        },
      },
      {
        field: "CourseDetail.DisplayName",
        displayName: t("courseName"),
        isHidden: columnsToHide?.includes("courseName"),
        render: (row: CourseOrderLocalResult) => {
          return <div>{row.courseDetail.displayName}</div>;
        },
      },
      {
        field: "courseSerialNumber",
        displayName: t("courseNumber"),
        isHidden: columnsToHide?.includes("courseSerialNumber"),
      },
      {
        field: "applicationDate",
        displayName: t("applicationDate"),
        isHidden: columnsToHide?.includes("applicationDate"),
        render: (row: CourseOrderLocalResult) => {
          return toSystemThemeDateFormat(row.createdOn);
        },
      },
      {
        field: "courseOrderStatus",
        displayName: t("orderStatus"),
        showOnMobile: true,
        isHidden: columnsToHide?.includes("courseOrderStatus"),
        render: (row: CourseOrderLocalResult) => {
          const status = Object.values(CourseOrderStatus).find((s) => s.id === row.courseOrderStatusId);
          const className = status ? `bg-${status?.color} text-${status?.color}` : "bg-primary text-primary";

          return <span className={`badge bg-opacity-10 ${className} rounded-4  py-2`}>{t(row.courseOrderStatus)}</span>;
        },
      },
      {
        field: "actions",
        displayName: t("Common:actions"),
        showOnMobile: true,
        isHidden: columnsToHide?.includes("actions"),
        render: (row: CourseOrderLocalResult) => {
          return (
            <span className="d-flex gap-2">
              {row.courseOrderStatusId === CourseOrderStatus.Draft.id && (
                <>
                  <IconButton
                    fitToIconWidth
                    icon="icon-edit"
                    innerIconColor="primary"
                    size="md"
                    onClick={() => {
                      navigate(`/home/orders/edit/${row.id}`);
                    }}
                  />
                  <IconButton
                    fitToIconWidth
                    icon="icon-delete"
                    innerIconColor="danger"
                    size="md"
                    onClick={() => {
                      setDeleteModalProps({
                        isOpen: true,
                        name: row.courseDetail.displayName,
                        id: row.id,
                      });
                    }}
                  />
                </>
              )}
            </span>
          );
        },
      },
    ],
    [t, toSystemThemeDateFormat, columnsToHide],
  );

  const viewHeader: GridViewHeaderProps = {
    title: t("orders"),
    singularName: t("order"),
    totalCount: totalCount,
    onExportClick: exportToExcel,
  };

  const onDeleteConfirm = async () => {
    const res = await CourseOrderService.deleteCourseOrder(deleteModalProps.id);
    if (res.hasError) {
      showNotification({
        type: "error",
        description: t("Common:internalServerError"),
      });
    } else {
      showNotification({
        type: "success",
        description: t("Common:deletedSuccessfully"),
      });
      setDeleteModalProps({ ...deleteModalProps, isOpen: false });
      fetchGridData();
    }
  };

  return (
    <>
      <GridView
        loading={loading}
        gridProps={{
          data: gridData ?? [],
          gridSchema,
        }}
        viewHeaderProps={viewHeader}
      />
      <ActionModal
        isOpen={deleteModalProps.isOpen}
        onClose={() => {
          setDeleteModalProps({
            isOpen: false,
            name: "",
            id: 0,
          });
        }}
        headerMsg={t("Common:areYouSure")}
        subMsg={t("deleteCourseOrderModalSubMsg", {
          name: deleteModalProps.name,
        })}
        actionIcon="icon-delete"
        confirmBtnText={t("Common:yesDelete")}
        iconWrapperColor={"danger"}
        onActionConfirm={onDeleteConfirm}
      />
    </>
  );
};

export default MyAllOrdersGridView;
