import { GridSchema } from "components/GridView/GridView.types";
import Grid from "components/GridView/components/Grid";
import Accordion from "components/blocks/Accordion";
import AccordionItem from "components/blocks/Accordion/AccordionItem";
import AttachmentCard from "components/blocks/AttachmentCard";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { OrderUserTypes, UserTypes } from "../../../orderPage.types";
import { CourseOrderLocalItemResult } from "services/courseOrder/models/CourseOrderType";
import { CourseLocalItemResult } from "services/courseService/models/CourseType";
import OrderDetails from "./OrderDetails";

const ApplicantInfo = ({
  order,
  userType,
  course,
}: {
  order?: CourseOrderLocalItemResult | null;
  course?: CourseLocalItemResult | null;
  userType?: UserTypes;
}) => {
  const { t, i18n } = useTranslation("Orders");

  const applicantTableHeaders = useMemo<GridSchema[]>(
    () => [
      {
        accessor: "applicantName",
        field: "applicantName",
        displayName: t("applicantName"),
      },
      {
        accessor: "degree",
        field: "degree",
        displayName: t("academicDegree"),
      },
      {
        accessor: "collage",
        field: "collage",
        displayName: t("collage"),
        render: (row) => <>{row.college ?? row.collegeNavigation?.collegeDetail.displayName}</>,
      },
      {
        accessor: "specialization",
        field: "specialization",
        displayName: t("specialization"),
      },
      {
        accessor: "email",
        field: "email",
        displayName: t("email"),
      },
      {
        accessor: "phoneNumber",
        field: "phoneNumber",
        displayName: t("phone"),
        render: (row) => <span className="phone-number">{row.phoneNumber}</span>,
      },
    ],
    [],
  );

  return (
    <Accordion>
      <AccordionItem initiallyOpen title={t("applicantMainInfo")} elementId={1}>
        <div className="d-flex flex-column gap-3  ">
          {userType !== OrderUserTypes.Student && (
            <Grid
              gridSchema={applicantTableHeaders}
              data={[
                {
                  applicantName:
                    i18n.language === "en" ? order?.appliedUser.englishName : order?.appliedUser.arabicName,
                  degree: order?.appliedUser.degree,
                  college: order?.appliedUser.college,
                  specialization: order?.appliedUser.specialization,
                  email: order?.appliedUser.email,
                  phoneNumber: order?.appliedUser.phoneNumber,
                },
              ]}
            />
          )}

          <OrderDetails order={order} course={course} />
        </div>
      </AccordionItem>
    </Accordion>
  );
};

export default ApplicantInfo;
