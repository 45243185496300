import { AccountService } from "./accountService";
import { FileService } from "./fileService";
import { SettingsService } from "./settingsService";
import { TasksService } from "./tasks";
import { OrdersService } from "./ordersService";
import { AuthorsService } from "./authorsService";
import { AuthorsPaymentsService } from "./authorsPaymentsService";
import { ExaminersService } from "./examinersService";
import { ExaminersRewardsService } from "./examinersRewardsService";
import { JudgesService } from "./judgesService";
import { JudgesRewardsService } from "./judgesRewardsService";
import { FormTemplatesService } from "./formTemplatesService";
import { ScientificPublicationsService } from "./scientificPublicationsService";
import { LookupService } from "./lookupService";
import { CourseService as CoursesService } from "./courseService";
import { TemplateService } from "./templatesService";
import { BounceTypeService } from "./bounceTypeService";
import { CourseOrderService } from "./courseOrder";
import { EvaluationFormsService } from "./evaluationFormsService";

export const BackendServices = {
  accountService: AccountService,
  settingsService: SettingsService,
  fileService: FileService,
  tasksService: TasksService,
  ordersService: OrdersService,
  authorsService: AuthorsService,
  authorsPaymentsService: AuthorsPaymentsService,
  examinersService: ExaminersService,
  examinersRewardsService: ExaminersRewardsService,
  judgesService: JudgesService,
  judgesRewardsService: JudgesRewardsService,
  formTemplatesService: FormTemplatesService,
  scientificPublicationsService: ScientificPublicationsService,
  lookupService: LookupService,
  coursesService: CoursesService,
  templateService: TemplateService,
  bounceTypeService: BounceTypeService,
  courseOrderService: CourseOrderService,
  evaluationFormsService: EvaluationFormsService,
} as const;
