import Updates from "./components/Updates";
import AchievementFile from "./components/AchievementFile";
import ResearchesCenter from "./components/ResearchesCenter";
// import Procedures from "./components/Procedures";
import ExaminersSection from "./components/ExaminersSection";
import BankingInformation from "./components/BankingInformation";
import { ActivePages, SectionsIds, UserTypes, sections } from "../../orderPage.types";
import { CourseLocalItemResult } from "services/courseService/models/CourseType";
import { CourseOrderLocalItemResult } from "services/courseOrder/models/CourseOrderType";
import { Dispatch, SetStateAction } from "react";
import ApplicantInfo from "./components/ApplicantInfo";
import Rewards from "./components/Rewards";
import PublishingHouse from "./components/PublishingHouse";
import Procedures from "./components/Procedures";
import Judges from "./components/Judges";
import ScientificPublication from "./components/ScientificPublication";
import ScientificPublicationLibraries from "./components/ScientificPublicationLibraries";
import OrderDetails from "./components/OrderDetails";
import AssignPermission from "./components/AssignPermission";

export const OrderInfoTab = ({
  courseOrder,
  course,
  userType,
  activePage,
  setActivePage,
  openedTask,
  submitRef,
  activeSectionsIds,
}: {
  openedTask: any;
  courseOrder: CourseOrderLocalItemResult | null;
  course?: CourseLocalItemResult | null;
  userType: UserTypes;
  activePage: ActivePages;
  setActivePage: Dispatch<SetStateAction<ActivePages>>;
  activeSectionsIds: SectionsIds[];
  submitRef?: any;
}) => {
  return (
    <div className="position-relative">
      {activeSectionsIds.map((sectionId) => {
        switch (sectionId) {
          /* case sections.updateNotesSection:
            return <UpdateNotes key={sectionId} order={order} userType={userType} />; */
          case sections.applicantInfoWithDetails:
            return <ApplicantInfo key={sectionId} course={course} order={courseOrder} userType={userType} />;
          case sections.orderDerails:
            return <OrderDetails key={sectionId} course={course} order={courseOrder} userType={userType} />;
          case sections.updates:
            return (
              <Updates
                key={sectionId}
                userType={userType}
                setActivePage={setActivePage}
                programOrderUpdateId={openedTask?.programOrderUpdateId ?? 0}
                order={courseOrder}
                programUpdateHandlersRef={submitRef}
              />
            );
          case sections.achievementFile:
            return (
              <AchievementFile
                key={sectionId}
                userType={userType}
                activePage={activePage}
                closeOrderFormProps={submitRef}
                order={courseOrder}
              />
            );
          case sections.rewards:
            return <Rewards key={sectionId} />;
          case sections.publishingHouse:
            return <PublishingHouse key={sectionId} />;
          case sections.researchesCenter:
            return <ResearchesCenter key={sectionId} order={courseOrder} />;
          case sections.procedures:
            return <Procedures key={sectionId} orderId={courseOrder?.id ?? 0} userType={userType} />;
          case sections.judges:
            return <Judges key={sectionId} order={courseOrder} />;
          case sections.examinerSection:
            return <ExaminersSection order={courseOrder} key={sectionId} />;
          case sections.userBankingInfo:
            return <BankingInformation key={sectionId} closeOrderFormProps={submitRef} />; // to be changed
          case sections.scientificPublication:
            return <ScientificPublication key={sectionId} courseOrder={courseOrder} />;
          case sections.scientificPublicationLibraries:
            return <ScientificPublicationLibraries key={sectionId} courseOrder={courseOrder} />;
          case sections.assignPermission:
            return <AssignPermission key={sectionId} />;
          /* case sections.progressCertificate:
            return (
              <ProgressCertificate
                key={sectionId}
                approveByCenterManagerSubmitHandlerRef={approveByCenterManagerSubmitHandlerRef}
                closeOrder={order?.closeOrder}
                isForEdit={userType === OrderUserTypes.ResearchCenterManagerForCloseRequest}
              />
            ); */
          default:
            return null;
        }
      })}
    </div>
  );
};
