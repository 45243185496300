import IconButton from "components/blocks/IconButton";
import ActionModal from "components/blocks/Modal/ActionModal";
import GridView from "components/GridView";
import { GridSchema, GridViewHeaderProps } from "components/GridView/GridView.types";
import { useBreadcrumb } from "contexts/breadcrumb/BreadcrumbContext";
import useGridData from "hooks/useGridData";
import { useNotification } from "hooks/useNotification";
import useSystemDateTheme from "hooks/useSystemDateTheme";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { CourseService } from "services/courseService";
import { CourseLocalResult, CourseStatus } from "services/courseService/models/CourseType";

const CoursesGridView = () => {
  const { t } = useTranslation("Courses");
  const { showNotification } = useNotification();
  const navigate = useNavigate();
  const { setBreadcrumbs } = useBreadcrumb();
  const { toSystemThemeDateFormat } = useSystemDateTheme();
  const [deleteModalProps, setDeleteModalProps] = useState({
    isOpen: false,
    name: "",
    id: 0,
  });

  const { loading, gridData, totalCount, exportToExcel, fetchGridData } = useGridData<
    "coursesService",
    CourseLocalResult
  >({
    service: "coursesService",
    endpoint: "getAllCourses",
    exportExcelEndpoint: "exportCourseToExcel",
    exportFileName: "courses",
    localizationNameSpace: "Courses",
  });

  useEffect(() => {
    setBreadcrumbs([
      {
        localizationKey: "allCourses",
        path: "/home/courses",
        menuItemId: "allCourses",
      },
    ]);
  }, []);

  const onDeleteConfirm = async () => {
    const res = await CourseService.deleteCourse(deleteModalProps.id);
    if (res.hasError) {
      showNotification({
        type: "error",
        description: t("Common:internalServerError"),
      });
    } else {
      showNotification({
        type: "success",
        description: t("Common:deletedSuccessfully"),
      });
      setDeleteModalProps({ ...deleteModalProps, isOpen: false });
      fetchGridData();
    }
  };

  const gridSchema = useMemo<GridSchema[]>(
    () => [
      {
        field: "CourseDetail.DisplayName",
        displayName: t("courseName"),
        sortable: true,
        filterable: true,
        showOnMobile: true,
        render: (row: CourseLocalResult) => {
          return <Link to={`${row.id}`}>{row.courseDetail.displayName}</Link>;
        },
      },
      {
        field: "startDate",
        displayName: t("courseStartDate"),
        render: (row: CourseLocalResult) => {
          return toSystemThemeDateFormat(row.startDate);
        },
      },
      {
        field: "endDate",
        displayName: t("courseEndDate"),
        render: (row: CourseLocalResult) => {
          return toSystemThemeDateFormat(row.applyCloseDate);
        },
      },
      {
        field: "applicantsCount",
        accessor: "applicantsCount",
        displayName: t("applicantsCount"),
      },
      {
        field: "status",
        displayName: t("Common:status"),
        render: (row: CourseLocalResult) => {
          const statusClassName =
            row.status === CourseStatus.Completed.label ? "bg-success text-success " : "bg-primary text-primary";
          return <span className={`badge bg-opacity-10  rounded-4 ${statusClassName} py-2`}>{t(row.status)}</span>;
        },
      },
      {
        field: "actions",
        displayName: t("Common:actions"),
        showOnMobile: true,
        render: (row: CourseLocalResult) => {
          return (
            <span className="d-flex gap-2">
              <IconButton
                fitToIconWidth
                icon="icon-edit"
                innerIconColor="primary"
                size="md"
                onClick={() => {
                  navigate(`/home/courses/form?id=${row.id}`);
                }}
              />
              <IconButton
                fitToIconWidth
                icon="icon-delete"
                innerIconColor="danger"
                size="md"
                onClick={() => {
                  setDeleteModalProps({
                    isOpen: true,
                    name: row.courseDetail.displayName,
                    id: row.id,
                  });
                }}
              />
            </span>
          );
        },
      },
    ],
    [t, toSystemThemeDateFormat],
  );

  const viewHeader: GridViewHeaderProps = {
    title: t("courses"),
    singularName: t("course"),
    totalCount: totalCount,
    onExportClick: exportToExcel,
    addPageRoute: "/home/courses/form",
  };

  return (
    <>
      <GridView
        loading={loading}
        gridProps={{
          data: gridData ?? [],
          gridSchema,
        }}
        viewHeaderProps={viewHeader}
      />
      <ActionModal
        isOpen={deleteModalProps.isOpen}
        onClose={() => {
          setDeleteModalProps({
            isOpen: false,
            name: "",
            id: 0,
          });
        }}
        headerMsg={t("Common:areYouSure")}
        subMsg={t("deleteCourseModalSubMsg", {
          name: deleteModalProps.name,
        })}
        actionIcon="icon-delete"
        confirmBtnText={t("Common:yesDelete")}
        iconWrapperColor={"danger"}
        onActionConfirm={onDeleteConfirm}
      />
    </>
  );
};

export default CoursesGridView;
