import { useBreadcrumb } from "contexts/breadcrumb/BreadcrumbContext";
import useMutation from "hooks/useMutation";
import { useQuery } from "hooks/useQuery";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { CourseService } from "services/courseService";
import { FileService } from "services/fileService";

const useCourseView = () => {
  const { setBreadcrumbs } = useBreadcrumb();
  const { courseId } = useParams();

  useEffect(() => {
    setBreadcrumbs([
      {
        localizationKey: "allCourses",
        path: "/home/courses",
        menuItemId: "allCourses",
      },
      {
        localizationKey: "course-number",
        path: `/home/courses/${courseId}`,
        localizationParameters: { courseNumber: "2014-22" },
      },
    ]);
  }, []);

  const { data: courseData, loading } = useQuery({
    queryFn: async () => await CourseService.getCourseById(+courseId!),
    triggers: [courseId],
    options: { enabled: !!courseId },
  });

  const {
    loading: imageLoading,
    data: courseImage,
    mutateAsync,
  } = useMutation({
    queryFn: async (id: string) => {
      const response = await FileService.getFileById(id);
      const blob = response.data;
      const imageUrl = URL.createObjectURL(blob);

      return {
        code: response.status,
        data: imageUrl,
        description: "",
        hasError: response.status !== 200,
      };
    },
  });

  useEffect(() => {
    const fetchImage = async () => {
      await mutateAsync(courseData!.courseImage.id);
    };

    if (!!courseData?.courseImage.id) {
      fetchImage();
    }
  }, [courseData]);

  return {
    courseData,
    courseImage,
    imageLoading,
    loading,
  };
};

export default useCourseView;
