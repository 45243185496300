import useSystemDateTheme from "hooks/useSystemDateTheme";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AboutCourseTab from "views/home/courses/CourseView/tabs/AboutCourseTab";
import OrderView from "views/home/orders/OrderView";
import { tabsIds } from "views/home/orders/OrderView/orderPage.types";
import { OrderInfoTab } from "views/home/orders/OrderView/tabs/OrderInfoTab";

const ViewPublishedCourseOrder = () => {
  const { t } = useTranslation("Orders");
  const navigate = useNavigate();
  const { toSystemThemeDateFormat } = useSystemDateTheme();

  return (
    <OrderView
      withImage
      breadcrumbs={({ orderId, serialNumber }) => [
        {
          localizationKey: "myPublishedWork",
          path: "/home/authorOrders/MyPublishedWork",
          menuItemId: "allOrders",
        },
        {
          localizationKey: "order-number",
          path: `/home/orders/unpublishedOrder/${orderId}`,
          localizationParameters: { orderNumber: serialNumber },
        },
      ]}
      defaultTabId="info"
      tapsToView={({ courseData, courseOrderData, openedTask, activePage, setActivePage, userType }) => {
        return [
          {
            id: tabsIds.info,
            title: t("info"),
            content: (
              <OrderInfoTab
                setActivePage={setActivePage}
                activePage={activePage}
                activeSectionsIds={["orderDerails", "scientificPublication", "scientificPublicationLibraries"]}
                userType={userType}
                courseOrder={courseOrderData}
                course={courseData}
                openedTask={openedTask}
              />
            ),
          },
          {
            id: tabsIds.courseInfo,
            title: t("aboutCourse"),
            content: <AboutCourseTab course={courseData} />,
          },
        ];
      }}
      userType="StudentForPublishedOrder"
      headersToView={({ courseOrderData }) =>
        !courseOrderData
          ? []
          : [
              {
                title: t("orderNumber"),
                value: courseOrderData?.serialNumber ?? "",
              },
              {
                title: t("applicationDate"),
                value: toSystemThemeDateFormat("2024-01-01"), //TODO": return id
              },
              {
                title: t("publicationTypeCategory"),
                value: `${courseOrderData.typeOfScientific?.typeOfScientificDetail.displayName ?? ""} - ${
                  courseOrderData.scientificClassification?.scientificClassificationDetail?.displayName ?? ""
                }`,
              },
              {
                title: t("researchCenter"),
                value: `${courseOrderData?.centreManagement.displayName ?? ""}`,
              },
              {
                title: t("publicationLanguage"),
                value: `${courseOrderData?.languageOfScientific.languageOfScientificDetail.displayName}`,
              },
              {
                title: t("publicationDate"),
                value: toSystemThemeDateFormat("2024-01-01"), //TODO": return id
              },
              {
                title: t("numberOfPages"),
                value: courseOrderData?.numberOfPages ?? 0,
              },
            ]
      }
    />
  );
};

export default ViewPublishedCourseOrder;
