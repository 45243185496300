import { useTranslation } from "react-i18next";
import ResearchLogo from "assets/image/login/resarchlogo.png";
import Icon from "assets/image/login/icon.png";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { LoginRequestType } from "services/accountService/models/AccountType";
import { AccountService } from "services/accountService";
import { useNotification } from "hooks/useNotification";
import { useCookieContext } from "../../../contexts";
import LanguageToggleBtn from "components/blocks/LanguageToggleBtn";
import { GzipProvider } from "../../../helpers/compress/gzipProvider";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

function LoginView() {
  const { t } = useTranslation(["LoginView", "Common"]);
  const { setCookie, isRTL } = useCookieContext();
  const navigate = useNavigate();
  const { showNotification } = useNotification();
  const gzipProvider = new GzipProvider();
  const { executeRecaptcha } = useGoogleReCaptcha();

  //! get isFromSignUp from the param
  const search = window.location.search;
  const params = new URLSearchParams(search);

  const [isSignUpToastMsg] = useState(!!params.get("isFromSignUp"));
  const [isFormValidated, setIsFormValidated] = useState(false);
  const [form, setForm] = useState<LoginRequestType>({
    identity: "",
    password: "",
    rememberMe: false,
  });
  const [formSubmissionStates, setFormSubmissionStates] = useState({
    isLoading: false,
    error: "",
  });

  useEffect(() => {
    document.body.classList.add("show-recaptcha");
    return () => {
      document.body.classList.remove("show-recaptcha");
    };
  }, []);

  useEffect(() => {
    const rememberMe = localStorage.getItem("RememberMe");
    if (rememberMe) {
      setForm(JSON.parse(atob(rememberMe)));
      document.getElementById("remember")?.setAttribute("checked", "true");
    }
  }, []);
  useEffect(() => {
    if (isSignUpToastMsg) {
      showNotification({
        description: t("registerSuccessMsg"),
        type: "success",
      });
    }
  }, [isSignUpToastMsg]);

  const onRegisterLinkClicked = () => {
    navigate("/account/register");
  };

  const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (form.identity && form.password) {
      setFormSubmissionStates({ isLoading: true, error: "" });

      try {
        if (!executeRecaptcha) {
          setFormSubmissionStates({
            isLoading: false,
            error: t("Common:ReCaptchaInitializationError"),
          });
          return;
        }
        const reCaptchaToken = await executeRecaptcha("Login");
        const res = await AccountService.login({ ...form, reCaptchaToken });

        if (!res.hasError) {
          if (form.rememberMe) {
            localStorage.setItem("RememberMe", btoa(JSON.stringify(form)));
          } else {
            localStorage.removeItem("RememberMe");
          }
          setCookie("Session", gzipProvider.compress(JSON.stringify(res.data)), {
            sameSite: "strict",
            secure: true,
            expires: 7,
          });
          if (params.get("courseId")) {
            navigate(`/home/orders/add?courseId=${params.get("courseId")}`);
          } else {
            navigate("/home");
          }
        } else {
          setFormSubmissionStates({
            isLoading: false,
            error: t(res.description),
          });
        }
      } catch (error) {
        setFormSubmissionStates({
          isLoading: false,
          error: t("Common:internalServerError"),
        });
      }
    }

    setIsFormValidated(true);
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  return (
    <div className="container-fluid position-relative">
      <LanguageToggleBtn
        style={{ position: "absolute", top: 20, left: isRTL ? 20 : "auto", right: isRTL ? "auto" : 20 }}
      />
      <div className="row login-container p-2">
        <div className="col-md-6  col-sm-12  login-image ">
          <div className="overlay p-4  ">
            <div>
              <img src={ResearchLogo} width="181" height="88" alt="Logo" className="mb-3" />
            </div>

            <div>
              <h1>{t("universityName")}</h1>
              <p>{t("universityDescription")}</p>
            </div>
          </div>
        </div>

        <div className="col-md-6  col-sm-12  d-flex justify-content-center h-100 ">
          <div className="login-form   rounded">
            <div className=" text-center mb-4">
              <img src={Icon} width="92" height="147" alt="Logo" className="mb-3" />
            </div>
            <div>
              <div className=" d-flex flex-column align-items-center">
                <h2> {t("welcome")}</h2>
                <p className="text-darkGray">{t("loginDescription")}</p>
              </div>
              <form noValidate className={isFormValidated ? "was-validated" : ""} onSubmit={onFormSubmit}>
                <div className="form-group my-2">
                  <label className="my-1">{t("username")}</label>
                  <input
                    className="form-control"
                    placeholder={t("usernamePlaceholder")}
                    type="text"
                    name="identity"
                    value={form.identity}
                    required
                    onChange={onInputChange}
                  />
                  <div className="invalid-feedback">{t("invalidValue")}</div>
                </div>
                <div className="form-group my-2">
                  <label className="my-1">{t("password")}</label>
                  <input
                    className="form-control"
                    value={form.password}
                    type="password"
                    required
                    name="password"
                    onChange={onInputChange}
                    placeholder={t("passwordPlaceholder")}
                  />
                  <div className="invalid-feedback">{t("invalidValue")}</div>
                </div>
                <div className="row my-2">
                  <div className="col-md-6 col-sm-12">
                    <div className=" form-group d-flex gap-2">
                      <div>
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="remember"
                          onChange={(e) => setForm({ ...form, rememberMe: !form.rememberMe })}
                          onClick={(e) => setForm({ ...form, rememberMe: !form.rememberMe })}
                        />
                      </div>
                      <div>
                        <label className="form-check-label">{t("rememberMe")}</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div className="text-center text-dark ">
                      <a href="#">{t("forgetPassword")}</a>
                    </div>
                  </div>
                </div>

                <button
                  type="submit"
                  className="btn btn-dark btn-block w-100 my-3"
                  disabled={formSubmissionStates.isLoading}
                >
                  {formSubmissionStates.isLoading ? <span className="custom-loader"></span> : t("loginButton")}
                </button>
                <div className="text-center text-danger">{formSubmissionStates.error}</div>
              </form>
            </div>

            <p className="text-center">
              {t("registerMsg")}
              <button className="btn btn-link p-0 px-1 pt-2  text-decoration-none" onClick={onRegisterLinkClicked}>
                {t("here")}
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginView;
