import { z } from "zod";
import {
  ScientificPublicationStatus,
  ScientificPublishingAttachmentFileStatus,
} from "./ScientificPublicationForm.types";
import { TFunction } from "i18next";

const getScientificPublicationValidationSchema = (t: TFunction) =>
  z.object({
    serialNumber: z.string().min(1, t("Common:requiredField")),
    authorName: z.string().min(1, t("Common:requiredField")),
    publicationDate: z.date({
      required_error: t("Common:requiredField"),
      invalid_type_error: t("Common:invalidDate"),
    }),
    scientificSpecializationId: z.number().positive(t("Common:requiredField")),
    typeOfScientificId: z.number().positive(t("Common:requiredField")),
    scientificClassificationId: z.number().positive(t("Common:requiredField")),
    languageOfScientificId: z.number().positive(t("Common:requiredField")),
    publisherId: z.number().positive(t("Common:requiredField")),
    publisherTypeId: z.number().positive(t("Common:requiredField")),
    numberOfPaper: z.number().positive(t("Common:invalidPositiveNumber")),
    scientificPublicationLink: z.string().url(t("Common:invalidUrlFormat")),
    scientificPublishingImageId: z.string().min(1, t("Common:requiredField")),
    metaData: z.string().min(1, t("Common:requiredField")),
    isHardCopyAvailable: z.boolean(),
    status: z.nativeEnum(ScientificPublicationStatus, {
      required_error: t("Common:requiredField"),
      invalid_type_error: t("Common:invalidValue"),
    }),

    scientificPublishingDetails: z
      .array(
        z.object({
          displayName: z.string().min(1, t("Common:requiredField")),
          description: z.string().min(1, t("Common:requiredField")),
          locale: z.string().min(1, t("Common:requiredField")),
        }),
      )
      .min(1, t("Common:minOneItem")),

    searchWords: z.array(
      z.object({
        searchWord: z.string().min(1, t("Common:requiredField")),
      }),
    ),

    librariesIds: z.array(z.number().positive(t("Common:requiredField"))),

    scientificPublishingAttachments: z.array(
      z.object({
        id: z.number().optional(),
        fileId: z.string().min(1, t("Common:requiredField")),
        description: z.string().min(1, t("Common:requiredField")),
        translatedName: z.string().min(1, t("Common:requiredField")),
        fileLangId: z.number().positive(t("Common:requiredField")),
        isAllowedFileToDownload: z.boolean(),
        status: z.nativeEnum(ScientificPublishingAttachmentFileStatus, {
          required_error: t("Common:requiredField"),
          invalid_type_error: t("Common:invalidValue"),
        }),
        createdOn: z.string().optional(),
        file: z.object({
          id: z.string(),
          name: z.string(),
          size: z.string(),
          type: z.string(),
          extension: z.string(),
        }),
        fileLang: z.object({
          id: z.number(),
          fileLangDetail: z.object({
            displayName: z.string(),
            locale: z.string(),
          }),
        }),
      }),
    ),
  });

export default getScientificPublicationValidationSchema;
