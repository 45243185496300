import { BaseResponseType } from "services/shared-models/sharedTypes";
import { HttpClient } from "helpers";
import {
  CountryCodeResultResponse,
  CountryResultResponse,
  DegreeResultResponse,
  GenderResultResponse,
  JobResultResponse,
  LookupResult,
  SystemTypeResultResponse,
  UserTypeResultResponse,
  ProgramCycleYearsResultResponse,
  AllScientificClassificationDropDown,
  ScientificClassificationResult,
  AccountTypeResultResponse,
  GeneralSpecializationResult,
  SubspecialtyByGeneralSpecializationResult,
  CurrencyResultResponse,
} from "./models/LookupsType";
import qs from "qs";

export const LookupService = {
  getCountryCodes: async () => {
    return HttpClient.get<BaseResponseType<CountryCodeResultResponse[]>>("/api/lookup/GetAllCountryCodes");
  },
  getGenders: async () => {
    return HttpClient.get<BaseResponseType<GenderResultResponse[]>>("/api/lookup/GetAllGenders");
  },
  getDegrees: async () => {
    return HttpClient.get<BaseResponseType<DegreeResultResponse[]>>("/api/lookup/GetAllDegrees");
  },
  getJobs: async () => {
    return HttpClient.get<BaseResponseType<JobResultResponse[]>>("/api/lookup/GetAllJobs");
  },
  getNationalities: async () => {
    return HttpClient.get<BaseResponseType<CountryResultResponse[]>>("/api/lookup/GetAllCountries");
  },
  getAllSystemTypes: async () => {
    return HttpClient.get<BaseResponseType<SystemTypeResultResponse[]>>("/api/lookup/GetAllSystemTypes");
  },
  getAllUserTypes: async () => {
    return HttpClient.get<BaseResponseType<UserTypeResultResponse[]>>("/api/lookup/GetAllUserTypes");
  },
  getAllCurrencies: async () => {
    return HttpClient.get<BaseResponseType<CurrencyResultResponse[]>>("/api/lookup/GetAllCurrencies");
  },
  getAllFileLangDropDown: async () => {
    return HttpClient.get<BaseResponseType<LookupResult[]>>("/api/lookup/GetAllFileLangDropDown");
  },
  getAllPublisherDropDown: async () => {
    return HttpClient.get<BaseResponseType<LookupResult[]>>("/api/lookup/GetAllPublisherDropDown");
  },
  getAllPublisherTypeDropDown: async () => {
    return HttpClient.get<BaseResponseType<LookupResult[]>>("/api/lookup/GetAllPublisherTypeDropDown");
  },
  getAllScientificClassificationDropDown: async () => {
    return HttpClient.get<BaseResponseType<ScientificClassificationResult[]>>(
      "/api/lookup/GetAllScientificClassificationDropDown",
    );
  },
  getAllScientificSpecializationDropDown: async () => {
    return HttpClient.get<BaseResponseType<LookupResult[]>>("/api/lookup/GetAllScientificSpecializationDropDown");
  },
  getAllTypeOfScientificDropDown: async () => {
    return HttpClient.get<BaseResponseType<LookupResult[]>>("/api/Lookup/GetAllTypeOfScientificDropDown");
  },
  getAllLanguageOfScientificDropDown: async () => {
    return HttpClient.get<BaseResponseType<LookupResult[]>>("/api/lookup/GetAllLanguageOfScientificDropDown");
  },
  getAllLibrariesDropDown: async () => {
    return HttpClient.get<BaseResponseType<LookupResult[]>>("/api/lookup/GetAllLibrariesDropDown");
  },
  getAllProgramCycleYears: async () => {
    return HttpClient.get<BaseResponseType<ProgramCycleYearsResultResponse[]>>("/api/lookup/GetAllProgramCycleYears");
  },
  getAllProgramCycleByYear: async (query: { year: number }) => {
    return HttpClient.get<BaseResponseType<ProgramCycleYearsResultResponse[]>>(
      `/api/lookup/GetAllProgramCycleByYear?${qs.stringify(query)}`,
    );
  },
  getAllAccountTypes: async () => {
    return HttpClient.get<BaseResponseType<AccountTypeResultResponse[]>>("/api/lookup/GetAllAccountTypes");
  },
  getAllGeneralSpecialization: async () => {
    return HttpClient.get<BaseResponseType<GeneralSpecializationResult[]>>("/api/lookup/GetAllGeneralSpecialization");
  },
  getAllSubspecialtyByGeneralSpecializationId: async ({ id }: { id: number }) => {
    return HttpClient.get<BaseResponseType<SubspecialtyByGeneralSpecializationResult[]>>(
      `/api/lookup/GetAllSubspecialtyByGeneralSpecializationId/${id}`,
    );
  },
} as const;
