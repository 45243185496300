import Accordion from "components/blocks/Accordion";
import AccordionItem from "components/blocks/Accordion/AccordionItem";
import AttachmentCard from "components/blocks/AttachmentCard";
import FormInput from "components/blocks/FormInput";
import { useTranslation } from "react-i18next";

const Rewards = () => {
  const { t } = useTranslation("Orders");

  return (
    <Accordion>
      <AccordionItem initiallyOpen title={t("rewards")} elementId={"rewards"}>
        <div className="d-flex gap-2 flex-wrap">
          <FormInput
            label={t("authorRewardAmount")}
            disabled
            leftElement={<span className="text-primary">{t("ryal")}</span>}
            value={1500}
          />
          <FormInput
            label={t("examinerRewardAmount")}
            disabled
            leftElement={<span className="text-primary">{t("ryal")}</span>}
            value={1500}
          />
          <FormInput
            label={t("judgeRewardAmount")}
            disabled
            leftElement={<span className="text-primary">{t("ryal")}</span>}
            value={1500}
          />
        </div>

        <div className="pt-4">
          <h6 className="fw-bold">{t("attachments")}</h6>
          <div className="d-flex gap-3 mt-2 ">
            {[1, 2]?.length ? (
              [
                { id: 1, name: "file1", size: "1MB", extension: "pdf" },
                { id: 2, name: "file2", size: "2MB", extension: "pdf" },
              ].map((attachment) => (
                <AttachmentCard
                  key={attachment.id}
                  attachment={{
                    id: attachment.id,
                    fileName: attachment.name ?? "",
                    size: attachment.size ?? "",
                    extension: attachment.extension ?? "",
                  }}
                />
              ))
            ) : (
              <span>{t("Common:noData")}</span>
            )}
          </div>
        </div>

        <div className="divider mt-4 "></div>
      </AccordionItem>
    </Accordion>
  );
};

export default Rewards;
