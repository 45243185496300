import { ReactNode, useEffect, useRef, useState } from "react";
import HeadersSection from "./HeadersSection";
import LoadingOverlay from "components/blocks/LoadingOverlay";
import IconButton from "components/blocks/IconButton";

export type TabProps = {
  id: string;
  title: string;
  content: ReactNode;
};

export type PageHeaderProps = {
  title: string;
  value: ReactNode;
  renderCondition?: (selectedTabId: string) => boolean;
};

export type PageWithTabsProps = {
  title: string | ReactNode;
  titleImgSrc?: string;
  subTitle?: string | ReactNode | ReactNode[];
  headerContainerClassName?: string;
  backButtonCallback?: (activeTabId: string) => void;
  actions?: (selectedTabId: string) => ReactNode;
  pageHeaders?: ((selectedTapId: string) => PageHeaderProps[]) | PageHeaderProps[];
  tapContentContainerClassName?: string;
  keepTabsMounted?: boolean;
  loading?: boolean;
  wrapperClassName?: string;
} & (
  | {
      tapContent?: never;
      defaultTabId: string;
      tabs: (TabProps | null)[];
    }
  | {
      defaultTabId?: never;
      tabs?: never;
      tapContent: ReactNode;
    }
);

const PageWithTabs: React.FC<PageWithTabsProps> = (props) => {
  const [activeTabId, setActiveTabId] = useState(props.defaultTabId ?? "");
  const contentRef = useRef<HTMLDivElement>(null);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const scrollToTop = () => {
    contentRef.current?.scrollTo({ top: 0, behavior: "smooth" });
  };

  const onCollapseIconClick = () => {
    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsCollapsed(window.innerWidth < 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    // Cleanup listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      className={`bg-body position-relative d-flex flex-column h-100 ps-2 ${
        props.wrapperClassName ? props.wrapperClassName : ""
      }`}
      style={{ margin: -20, minHeight: "calc(100% + 40px)" }}
    >
      <LoadingOverlay visible={!!props.loading} />
      <div className={`position-sticky z-1 mb-2 bg-transparent`}>
        <div
          className={` z-1 ${
            props.headerContainerClassName
              ? props.headerContainerClassName
              : "d-flex flex-column bg-white rounded-4 p-4"
          }`}
        >
          <HeadersSection {...props} activeTabId={activeTabId} isCollapsed={isCollapsed} />
          {props.tabs && !isCollapsed && (
            <div className="d-flex gap-2 mt-4 flex-wrap">
              {props.tabs?.map(
                (tab) =>
                  tab && (
                    <button
                      key={tab.id}
                      className={`fs-14px px-3 py-2 rounded-4 btn btn-outline-primary  ${
                        tab.id === activeTabId ? " active fw-bold" : "border-light-300  text-dark"
                      }`}
                      onClick={() => {
                        scrollToTop();
                        setActiveTabId(tab.id);
                      }}
                    >
                      {tab.title}
                    </button>
                  ),
              )}
            </div>
          )}

          {(props.tabs || props.pageHeaders) && (
            <div
              className="position-relative"
              style={{
                bottom: "-20px",
                zIndex: -1,
              }}
            >
              <IconButton
                icon={"icon-arrow-down"}
                fitToIconWidth
                className="px-2 z-1 bg-white"
                style={{
                  position: "absolute",
                  left: "50%",
                  borderTop: 0,
                  borderRadius: "0px 0px 5px 5px !important",
                  zIndex: 0,
                  transform: `rotate(${!isCollapsed ? "180deg" : "0deg"})`, // Toggle rotation
                  transition: "transform 0.3s ease", // Smooth rotation animation
                }}
                onClick={onCollapseIconClick}
                variant="light"
              />
            </div>
          )}
        </div>
      </div>

      <div className="flex-grow-1 overflow-auto" ref={contentRef}>
        <div
          className={`${
            props.tapContentContainerClassName ? props.tapContentContainerClassName : "mt-3 p-4 bg-white rounded-4"
          }`}
        >
          {props.tabs ? (
            props.keepTabsMounted ? (
              props.tabs.map(
                (tab) =>
                  tab && (
                    <div key={tab.id} className={tab.id === activeTabId ? "" : "d-none"}>
                      {tab.content}
                    </div>
                  ),
              )
            ) : (
              props.tabs.find((tab) => tab?.id === activeTabId)?.content
            )
          ) : (
            <>{props.tapContent}</>
          )}
        </div>
      </div>
    </div>
  );
};

export default PageWithTabs;
