import ActionModal from "components/blocks/Modal/ActionModal";
import { useTranslation } from "react-i18next";

type ChangeAuthorStatusModalProps = {
  isOpen: boolean;
  onClose: () => void;
  type: string;
  name: string;
  onModalConfirm: () => void;
  loading: boolean;
};

const ChangeAuthorStatusModal: React.FC<ChangeAuthorStatusModalProps> = (props) => {
  const { t } = useTranslation("Authors");
  const { isOpen, onClose, type, name, onModalConfirm, loading } = props;

  return (
    <ActionModal
      isOpen={isOpen}
      onClose={onClose}
      headerMsg={type === "block" ? t("blockModalHeaderMsg") : t("unblockModalHeaderMsg")}
      subMsg={type === "block" ? t("blockModalSubMsg", { name }) : t("unblockModalSubMsg", { name })}
      actionIcon="icon-block"
      confirmBtnText={type === "block" ? t("Common:block") : t("Common:unblock")}
      iconWrapperColor={type === "block" ? "danger" : "primary"}
      onActionConfirm={onModalConfirm}
      loading={loading}
    />
  );
};

export default ChangeAuthorStatusModal;
