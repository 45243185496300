import useSystemDateTheme from "hooks/useSystemDateTheme";
import { useNavigate } from "react-router-dom";
import { ScientificPublishingLocalPublicResult } from "services/scientificPublicationsService/models/scientificPublicationsTypes";

const PublicationBookCard = ({
  scientificPublishing,
}: {
  scientificPublishing: ScientificPublishingLocalPublicResult;
}) => {
  const navigate = useNavigate();
  const { toSystemThemeDateFormat } = useSystemDateTheme();

  return (
    <div
      role="button"
      className="p-3 bg-white rounded-3 border d-flex gap-3 flex-1 flex-column"
      style={{ minWidth: "200px", maxWidth: "240px" }}
      // navigate to publication details
      onClick={() => navigate(`/PublicationsDetails/${scientificPublishing.id}`)}
    >
      <img
        className="rounded-1"
        src={scientificPublishing.scientificPublishingImageUrl}
        alt="publication"
        style={{ height: "300px" }}
      />
      <div className="flex-1 justify-content-center align-items-center">
        <h5 className="fw-bold text-center"> {scientificPublishing.scientificPublishingDetail.displayName}</h5>
        <p className="text-sm text-gray-500 text-center m-0">
          {scientificPublishing.scientificClassificationDetail?.displayName}
        </p>
        <p className="text-sm text-gray text-center m-0 mt-2">{scientificPublishing.authorName} </p>
        <p className="text-sm text-gray text-center m-0">
          {toSystemThemeDateFormat(scientificPublishing.publicationDate)}
        </p>
      </div>
    </div>
  );
};

export default PublicationBookCard;
