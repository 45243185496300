import useSystemDateTheme from "hooks/useSystemDateTheme";
import OrderView from "../..";
import { tabsIds } from "../../orderPage.types";
import AdditionalAttachmentsTab from "../../tabs/AdditionalAttachmentsTab";
import { OrderInfoTab } from "../../tabs/OrderInfoTab";
import { useTranslation } from "react-i18next";
import Actions from "./Actions";
import CourseMinutesTab from "../../tabs/CourseMinutesTab";
import SpeechesTab from "../../tabs/SpeechesTab";
import { useRef } from "react";
import { AdminTapActionsRef } from "../CourseOrderAdminView";

const CourseOrderResearchCenterManagerView = () => {
  const submitRef = useRef<AdminTapActionsRef>(null);
  const { t } = useTranslation("Orders");
  const { toSystemThemeDateFormat } = useSystemDateTheme();

  return (
    <OrderView
      breadcrumbs={({ serialNumber, orderId }) => [
        {
          localizationKey: "allOrders",
          path: "/home/orders",
          menuItemId: "allOrders",
        },
        {
          localizationKey: "order-number",
          path: `/home/orders/${orderId}`,
          localizationParameters: { orderNumber: serialNumber },
        },
      ]}
      defaultTabId="info"
      leftSection={({ selectedTabId, openedTask }) => {
        return <Actions openTask={openedTask} selectedTabId={selectedTabId} submitRef={submitRef} />;
      }}
      tapsToView={({ courseData, courseOrderData, openedTask, activePage, setActivePage, userType }) => {
        return [
          {
            id: tabsIds.info,
            title: t("info"),
            content: (
              <OrderInfoTab
                setActivePage={setActivePage}
                activePage={activePage}
                activeSectionsIds={["applicantInfoWithDetails", "examinerSection", "assignPermission", "procedures"]}
                userType={userType}
                courseOrder={courseOrderData}
                course={courseData}
                openedTask={openedTask}
                submitRef={submitRef}
              />
            ),
          },
          {
            id: tabsIds.minutesOfMeeting,
            title: t("minutesOfMeeting"),
            content: <CourseMinutesTab courseOrderId={courseOrderData?.id ?? 0} submitRef={submitRef} />,
          },
          {
            id: tabsIds.letters,
            title: t("letters"),
            content: <SpeechesTab submitRef={submitRef} courseOrderId={courseOrderData?.id ?? 0} />,
          },

          {
            id: tabsIds.attachments,
            title: t("attachments"),
            content: <AdditionalAttachmentsTab submitRef={submitRef} courseOrderId={courseOrderData?.id ?? 0} />,
          },
        ];
      }}
      userType="Admin"
      headersToView={({ courseOrderData }) =>
        !courseOrderData
          ? []
          : [
              {
                title: t("orderNumber"),
                value: courseOrderData?.serialNumber ?? "",
              },
              {
                title: t("applicationDate"),
                value: toSystemThemeDateFormat("2024-01-01"), //TODO": return id
              },
              {
                title: t("researchCenter"),
                value: `${courseOrderData?.centreManagement.displayName ?? ""}`,
              },
              {
                title: t("researchField"),
                value: courseOrderData.scientificClassification.scientificClassificationDetail.displayName,
              },
              {
                title: t("subspecialty"),
                value: courseOrderData.scientificSpecialization.scientificSpecializationDetail.displayName,
              },
            ]
      }
    />
  );
};

export default CourseOrderResearchCenterManagerView;
