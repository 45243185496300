import { HttpClient } from "helpers/http/httpClient";
import { BaseResponseType, DataGridRequest, PagedResponseType } from "services/shared-models/sharedTypes";
import {
  ConditionsAndRulesByIDResponse,
  ConditionsAndRulesResultResponse,
  PDFTemplateByIDResponse,
  PdfTemplateLocalResult,
} from "./models/templatesTypes";

export const TemplateService = {
  getAllConditionsAndRules: async (request: { search: string; pageIndex: number; pageSize: number }) => {
    return HttpClient.post<PagedResponseType<ConditionsAndRulesResultResponse[]>>(
      "/api/template/GetAllConditionsAndRules",
      request,
    );
  },

  getAllPdfTemplates: async (data: DataGridRequest) => {
    return await HttpClient.post<BaseResponseType<PdfTemplateLocalResult>>("/api/template/GetAllPdfTemplates", data);
  },

  getConditionAndRuleById: async (id: number) => {
    return HttpClient.get<BaseResponseType<ConditionsAndRulesByIDResponse>>(
      `/api/template/GetConditionAndRuleById/${id}`,
    );
  },

  getPdfTemplateById: async (id: number) => {
    return HttpClient.get<BaseResponseType<PDFTemplateByIDResponse>>(`/api/template/GetPdfTemplateById/${id}`);
  },
};
