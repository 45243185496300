import Accordion from "components/blocks/Accordion";
import AccordionItem from "components/blocks/Accordion/AccordionItem";
import LoadingOverlay from "components/blocks/LoadingOverlay";
import { useQuery } from "hooks/useQuery";
import { useTranslation } from "react-i18next";
import { CourseOrderLocalItemResult } from "services/courseOrder/models/CourseOrderType";
import { ScientificPublicationsService } from "services/scientificPublicationsService";
import { FileCard } from "views/welcomePage/components/PublicationsGallery/PublicationsDetails";

const ScientificPublication = ({ courseOrder }: { courseOrder: CourseOrderLocalItemResult | null }) => {
  const { t } = useTranslation("Orders");
  const { data: scientificPublication, loading: courseLoading } = useQuery({
    queryFn: async () => {
      // TODO: need Scientific PublicationBy Id
      return await ScientificPublicationsService.getScientificPublicationById(4);
    },
    options: { enabled: !!courseOrder },
    triggers: [courseOrder],
  });

  return (
    <Accordion>
      <AccordionItem initiallyOpen title={t("scientificPublication")} elementId={"ScientificPublication"}>
        <div className="position-relative p-0 m-0">
          <LoadingOverlay visible={courseLoading} />
          <div className="d-flex flex-wrap gap-3">
            {scientificPublication?.scientificPublishingAttachments
              ? scientificPublication?.scientificPublishingAttachments.map((ele, index) => {
                  return <FileCard key={index} file={ele} />;
                })
              : t("Common:noData")}
          </div>
        </div>

        <div className="divider mt-4 "></div>
      </AccordionItem>
    </Accordion>
  );
};

export default ScientificPublication;
