import { RouteItemConfig } from "../models";
import DashboardView from "../../views/home/dashboard/dashboardView";
import TasksGridView from "views/home/tasks/TasksGridView";
import { Outlet } from "react-router-dom";
import CoursesGridView from "views/home/courses/CourseGridView";
import OrdersGridView from "views/home/orders/OrdersGridView";
import AuthorsGridView from "views/home/authorsManagement/authors/AuthorsGridView";
import AuthorsPaymentsGridView from "views/home/authorsManagement/authorsPayments/AuthorsPaymentsGridView";
import FormTemplatesGridView from "views/home/formTemplates/FormTemplatesGridView";
import ScientificPublicationGridView from "views/home/scientificPublication/ScientificPublicationGridView";
import JudgesGridView from "views/home/judgesManagement/judges/JudgesGridView";
import JudgesRewardsGridView from "views/home/judgesManagement/judgesRewards/JudgesRewardsGridView";
import ExaminersRewardsGridView from "views/home/examinersManagement/examinersRewards/ExaminersRewardsGridView";
import ExaminersGridView from "views/home/examinersManagement/examiners/ExaminersGridView";
import CourseForm from "views/home/courses/CourseForms";
import CourseView from "views/home/courses/CourseView";
import UserProfile from "views/home/userProfile";
import ScientificPublicationForm from "views/home/scientificPublication/form/ScientificPublicationForm";
import PublicationView from "views/home/scientificPublication/PublicationView";
import EvaluationFormsGridView from "../../views/home/evaluationForms/EvaluationFormsGridView.tsx";
import EvaluationFormAddUpdateView from "../../views/home/evaluationForms/FormView";
import OrderForm from "views/home/orders/OrderForm";
import CourseOrderAdminView from "views/home/orders/OrderView/UserTypeOrderView/CourseOrderAdminView";
import MyPublishedWorkGrid from "views/home/authorOrders/MyPublishedWorkGrid";
import MyAllOrdersGridView from "views/home/authorOrders/MyAllOrdersGridView";
import ViewPublishedCourseOrder from "views/home/authorOrders/MyPublishedWorkGrid/ViewPublishedCourseOrder";
import ViewUnPublishedCourseOrder from "views/home/authorOrders/MyAllOrdersGridView/ViewUnPublishedCourseOrder";
import EditUserProfileForm from "views/home/userProfile/EditUserProfileForm";
import CourseOrderResearchCenterManagerView from "views/home/orders/OrderView/UserTypeOrderView/CourseOrderResearchCenterManagerView";

export const HomeRoutes: RouteItemConfig[] = [
  {
    path: "/home/dashboard",
    name: "dashboard",
    component: DashboardView,
    icon: "icon-dashboard",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: true,
    isRoute: true,
    id: 1,
  },
  {
    path: "/home/courses",
    name: "allCourses",
    component: CoursesGridView,
    icon: "icon-programs",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: true,
    isRoute: true,
    id: 1,
    groupName: "essentials",
  },
  {
    path: "/home/courses/form",
    name: "courseForm",
    component: CourseForm,
    icon: "icon-programs",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: false,
    isRoute: true,
    id: 1,
    groupName: "",
  },
  {
    path: "/home/courses/:courseId",
    name: "courseView",
    component: CourseView,
    icon: "icon-programs",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: false,
    isRoute: true,
    id: 1,
    groupName: "",
  },
  {
    path: "/home/authorOrders",
    name: "authorOrders",
    component: Outlet,
    icon: "icon-orders",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    subMenu: [
      {
        path: "/home/authorOrders/MyPublishedWork",
        name: "myPublishedWork",
        component: MyPublishedWorkGrid,
        layout: "/home",
        default: true,
        isExact: true,
        authorize: true,
        roles: [],
        showInMenu: true,
        isRoute: true,
        id: 1,
        groupName: "essentials",
      },
      {
        path: "/home/authorOrders/MyAllOrders",
        name: "myAllOrders",
        component: MyAllOrdersGridView,
        layout: "/home",
        default: true,
        isExact: true,
        authorize: true,
        roles: [],
        showInMenu: true,
        isRoute: true,
        id: 1,
        groupName: "essentials",
      },
    ],
    showInMenu: true,
    isRoute: true,
    id: 1,
    groupName: "essentials",
  },

  {
    path: "/home/orders",
    name: "allOrders",
    component: OrdersGridView,
    icon: "icon-orders",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: true,
    isRoute: true,
    id: 1,
    groupName: "essentials",
  },
  {
    path: "/home/orders/add",
    name: "orderView",
    component: OrderForm,
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: false,
    isRoute: true,
    id: 1,
    groupName: "",
  },
  {
    path: "/home/orders/edit/:orderId",
    name: "orderView",
    component: OrderForm,
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: false,
    isRoute: true,
    id: 1,
    groupName: "",
  },
  {
    path: "/home/orders/:orderId",
    name: "orderView",
    component: CourseOrderAdminView,
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: false,
    isRoute: true,
    id: 1,
    groupName: "",
  },
  {
    path: "/home/orders/researchCenterManager/:serialGID",
    name: "orderView",
    component: CourseOrderResearchCenterManagerView,
    icon: "icon-orders",
    layout: "/home",
    default: false,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: false,
    isRoute: true,
    id: 1,
  },
  {
    path: "/home/orders/publishedOrder/:orderId",
    name: "orderView",
    component: ViewPublishedCourseOrder,
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: false,
    isRoute: true,
    id: 1,
    groupName: "",
  },
  {
    path: "/home/orders/unpublishedOrder/:orderId",
    name: "orderView",
    component: ViewUnPublishedCourseOrder,
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: false,
    isRoute: true,
    id: 1,
    groupName: "",
  },
  {
    path: "/home/tasks",
    name: "tasks",
    component: TasksGridView,
    icon: "icon-tasks",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: true,
    isRoute: true,
    id: 1,
    groupName: "essentials",
  },
  {
    path: "/home/authorsManagement",
    name: "authorsManagement",
    component: Outlet,
    icon: "icon-researchers",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: true,
    isRoute: true,
    id: 1,
    groupName: "essentials",
    subMenu: [
      {
        path: "/home/authorsManagement/authors",
        name: "authorsInfo",
        component: AuthorsGridView,
        icon: "icon-researchers",
        layout: "/home",
        default: true,
        isExact: true,
        authorize: true,
        roles: [],
        showInMenu: true,
        isRoute: true,
        id: 1,
        groupName: "essentials",
      },
      {
        path: "/home/authorsManagement/authors/view",
        name: "authorInfo",
        component: UserProfile,
        icon: "icon-researchers",
        layout: "/home",
        default: false,
        isExact: true,
        authorize: true,
        roles: [],
        showInMenu: false,
        isRoute: true,
        id: 1,
      },
      {
        path: "/home/authorsManagement/authorPayments",
        name: "authorsPayments",
        component: AuthorsPaymentsGridView,
        icon: "icon-dashboard",
        layout: "/home",
        default: false,
        isExact: true,
        authorize: true,
        roles: [],
        showInMenu: true,
        isRoute: true,
      },
    ],
  },
  {
    path: "/home/examinersManagement",
    name: "examinersManagement",
    component: Outlet,
    icon: "icon-researchers",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: true,
    isRoute: true,
    id: 1,
    groupName: "essentials",
    subMenu: [
      {
        path: "/home/examinersManagement/examiners",
        name: "examinersInfo",
        component: ExaminersGridView,
        icon: "icon-researcher",
        layout: "/home",
        default: false,
        isExact: true,
        authorize: true,
        roles: [],
        showInMenu: true,
        isRoute: true,
      },
      {
        path: "/home/examinersManagement/examiners/view",
        name: "researcherInfo",
        component: UserProfile,
        icon: "icon-researchers",
        layout: "/home",
        default: false,
        isExact: true,
        authorize: true,
        roles: [],
        showInMenu: false,
        isRoute: true,
        id: 1,
      },
      {
        path: "/home/examinersManagement/examinerRewards",
        name: "examinerRewards",
        component: ExaminersRewardsGridView,
        icon: "icon-dashboard",
        layout: "/home",
        default: false,
        isExact: true,
        authorize: true,
        roles: [],
        showInMenu: true,
        isRoute: true,
      },
    ],
  },
  {
    path: "/home/judgesManagement",
    name: "judgesManagement",
    component: Outlet,
    icon: "icon-researchers",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: true,
    isRoute: true,
    id: 1,
    groupName: "essentials",
    subMenu: [
      {
        path: "/home/judgesManagement/judges",
        name: "judgesInfo",
        component: JudgesGridView,
        icon: "icon-researchers",
        layout: "/home",
        default: true,
        isExact: true,
        authorize: true,
        roles: [],
        showInMenu: true,
        isRoute: true,
        id: 1,
        groupName: "essentials",
      },
      {
        path: "/home/judgesManagement/judges/view",
        name: "judgesInfo",
        component: UserProfile,
        icon: "icon-researchers",
        layout: "/home",
        default: false,
        isExact: true,
        authorize: true,
        roles: [],
        showInMenu: false,
        isRoute: true,
        id: 1,
      },
      {
        path: "/home/judgesManagement/judgeRewards",
        name: "judgesRewards",
        component: JudgesRewardsGridView,
        icon: "icon-dashboard",
        layout: "/home",
        default: false,
        isExact: true,
        authorize: true,
        roles: [],
        showInMenu: true,
        isRoute: true,
      },
    ],
  },
  {
    path: "/home/formTemplates",
    name: "formTemplates",
    component: FormTemplatesGridView,
    icon: "icon-form-builder",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: true,
    isRoute: true,
    id: 1,
    groupName: "essentials",
  },
  {
    path: "/home/scientificPublication",
    name: "scientificPublication",
    component: ScientificPublicationGridView,
    // TODO: extract the book icon from FIGMA
    icon: "icon-programs",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: true,
    isRoute: true,
    id: 1,
    groupName: "essentials",
  },
  {
    path: "/home/scientificPublication/form",
    name: "scientificPublicationForm",
    component: ScientificPublicationForm,
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: false,
    isRoute: true,
    id: 1,
    groupName: "",
  },
  {
    path: "/home/scientificPublication/form/:id",
    name: "scientificPublicationForm",
    component: ScientificPublicationForm,
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: false,
    isRoute: true,
    id: 1,
    groupName: "",
  },
  {
    path: "/home/scientificPublication/:id",
    name: "publicationView",
    component: PublicationView,
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: false,
    isRoute: true,
    id: 1,
    groupName: "",
  },
  {
    path: "/home/evaluationForms",
    name: "evaluationForms",
    component: EvaluationFormsGridView,
    icon: "icon-eval-form",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: true,
    isRoute: true,
    id: 1,
    groupName: "essentials",
  },
  {
    path: "/home/evaluationForms/form",
    name: "",
    component: EvaluationFormAddUpdateView,
    icon: "",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: false,
    isRoute: true,
    id: 1,
    groupName: "essentials",
  },
  {
    path: "/home/editUserProfile/:userId",
    name: "researcherInfo",
    component: EditUserProfileForm,
    icon: "icon-researchers",
    layout: "/home",
    default: false,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: false,
    isRoute: true,
    id: 1,
  },
  {
    path: "/home/updateMyProfile",
    name: "updateMyProfile",
    component: EditUserProfileForm,
    icon: "icon-researchers",
    layout: "/home",
    default: false,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: false,
    isRoute: true,
    id: 1,
  },
  {
    path: "/home/myProfile/view",
    name: "myProfile",
    component: UserProfile,
    layout: "/home",
    default: false,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: false,
    isRoute: true,
    id: 1,
  },
];
