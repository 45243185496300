import IconButton from "components/blocks/IconButton";
import GridView from "components/GridView";
import { GridSchema, GridViewHeaderProps } from "components/GridView/GridView.types";
import { useCookieContext } from "contexts";
import { useBreadcrumb } from "contexts/breadcrumb/BreadcrumbContext";
import useGridData from "hooks/useGridData";
import useSystemDateTheme from "hooks/useSystemDateTheme";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AuthorResult } from "services/authorsService/models/authorsTypes";
import ChangeAuthorStatusModal from "./ChangeAuthorStatusModal";

const AuthorsGridView = () => {
  const { t } = useTranslation("Authors");
  const navigate = useNavigate();
  const { setBreadcrumbs } = useBreadcrumb();
  const { toSystemThemeDateFormat } = useSystemDateTheme();
  const { isAr } = useCookieContext();

  const { loading, gridData, totalCount, exportToExcel } = useGridData<"authorsService", AuthorResult>({
    service: "authorsService",
    endpoint: "getAllAuthors",
    exportExcelEndpoint: "exportToExcel",
    exportFileName: "authors",
    localizationNameSpace: "Authors",
  });

  const [modalProps, setModalProps] = useState({
    isOpen: false,
    type: "block",
    id: "",
    name: "",
    loading: false,
  });

  useEffect(() => {
    setBreadcrumbs([
      {
        localizationKey: "authorsInfo",
        path: "/home/authorsManagement/authors",
        menuItemId: "authors",
      },
    ]);
  }, []);

  const gridSchema = useMemo<GridSchema[]>(
    () => [
      {
        field: "ArabicName",
        displayName: t("authorName"),
        sortable: false,
        filterable: false,
        showOnMobile: true,
        render: (row: AuthorResult) => {
          return (
            <button
              className="btn btn-link"
              onClick={() => navigate(`/home/authorsManagement/authors/view?authorId=${row.id}`)}
            >
              {row.authorName}
            </button>
          );
        },
      },
      {
        field: "numberOfOrder",
        displayName: t("fundingOrdersCount"),
      },
      {
        field: "CreatedOn",
        displayName: t("joinDate"),
        filterOptions: {
          type: "date",
        },
        render: (row: AuthorResult) => {
          return toSystemThemeDateFormat(row.joiningDate);
        },
      },
      {
        field: "status",
        displayName: t("Common:status"),
        showOnMobile: true,
        render(row: AuthorResult) {
          const statusClassName =
            row.status === "Active" ? "bg-primary bg-opacity-10 text-primary" : "bg-danger bg-opacity-10 text-danger";
          return (
            <span className={`badge rounded-4 ${statusClassName} py-2`}>
              {t(row.status === "Active" ? "Common:active" : "Common:blocked")}
            </span>
          );
        },
      },
      {
        field: "id",
        displayName: t("Common:actions"),
        render: (row: AuthorResult) => {
          const innerIconColor = row.status === "Active" ? "danger" : "dark";

          return (
            <IconButton
              title={row.status === "Blocked" ? t("Common:unblock") : t("Common:block")}
              icon="icon-block"
              fitToIconWidth
              innerIconColor={innerIconColor}
              size="sm"
              onClick={() => {
                onChangeAuthorStatus(row.status, row.authorName, row.id);
              }}
            />
          );
        },
      },
    ],
    [t, toSystemThemeDateFormat],
  );

  const onChangeAuthorStatus = (status: string, name: string, id: string) => {
    setModalProps({
      isOpen: true,
      type: status === "Blocked" ? "block" : "unblock",
      name: name,
      id: id,
      loading: false,
    });
  };

  const onModalConfirm = async () => {
    // TODO: Implement block/unblock author
    setModalProps({
      isOpen: false,
      type: modalProps.type,
      name: "",
      id: "",
      loading: false,
    });
  };

  const onModalClose = () => {
    setModalProps({
      isOpen: false,
      type: modalProps.type,
      name: "",
      id: "",
      loading: false,
    });
  };

  const viewHeader: GridViewHeaderProps = {
    title: t("authors"),
    singularName: t("author"),
    totalCount: totalCount,
    onExportClick: exportToExcel,
  };

  return (
    <>
      <GridView
        loading={loading}
        gridProps={{
          data: gridData ?? [],
          gridSchema,
        }}
        viewHeaderProps={viewHeader}
      />
      <ChangeAuthorStatusModal {...modalProps} onClose={onModalClose} onModalConfirm={onModalConfirm} />
    </>
  );
};

export default AuthorsGridView;
