import GridView from "components/GridView";
import { GridSchema, GridViewHeaderProps } from "components/GridView/GridView.types";
import { useBreadcrumb } from "contexts/breadcrumb/BreadcrumbContext";
import useGridData from "hooks/useGridData";
import useSystemDateTheme from "hooks/useSystemDateTheme";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CourseOrderLocalResult } from "services/courseOrder/models/CourseOrderType";

const MyPublishedWorkGrid = () => {
  const { t } = useTranslation("Orders");
  const navigate = useNavigate();
  const { setBreadcrumbs } = useBreadcrumb();
  const { toSystemThemeDateFormat } = useSystemDateTheme();

  const { loading, gridData, totalCount, exportToExcel } = useGridData<"courseOrderService", CourseOrderLocalResult>({
    service: "courseOrderService",
    endpoint: "getAllMyCourseOrderPublishingStatus",
    exportExcelEndpoint: "exportMyCourseOrderPublishingStatusToExcel",
    exportFileName: "courseOrder",
    localizationNameSpace: "CourseOrders",
  });

  useEffect(() => {
    setBreadcrumbs([
      {
        localizationKey: "myPublishedWork",
        path: "/home/authorOrders/MyPublishedWork",
        menuItemId: "allOrders",
      },
    ]);
  }, []);

  const gridSchema = useMemo<GridSchema[]>(
    () => [
      {
        field: "id",
        displayName: t("orderNumber"),
        sortable: true,
        filterable: true,
        showOnMobile: true,
        render: (row: CourseOrderLocalResult) => {
          return (
            <button className="btn btn-link" onClick={() => navigate(`/home/orders/publishedOrder/${row.id}`)}>
              {row.serialNumber}
            </button>
          );
        },
      },
      {
        field: "CourseDetail.DisplayName",
        displayName: t("courseName"),
        render: (row: CourseOrderLocalResult) => {
          return <div>{row.courseDetail.displayName}</div>;
        },
      },
      {
        field: "courseSerialNumber",
        displayName: t("courseNumber"),
      },
      {
        field: "publicationType",
        displayName: t("publicationTypeCategory"),
        render(row: CourseOrderLocalResult) {
          return `${row.typeOfScientific?.typeOfScientificDetail.displayName ?? ""} - ${
            row.scientificClassification?.scientificClassificationDetail?.displayName ?? ""
          }`;
        },
      },
      {
        field: "centreManagerName",
        displayName: t("researchCenter"),
      },
    ],
    [t, toSystemThemeDateFormat],
  );

  const viewHeader: GridViewHeaderProps = {
    title: t("myWorks"),
    singularName: t("work"),
    totalCount: totalCount,
    onExportClick: exportToExcel,
  };

  return (
    <GridView
      loading={loading}
      gridProps={{
        data: gridData ?? [],
        gridSchema,
      }}
      viewHeaderProps={viewHeader}
    />
  );
};

export default MyPublishedWorkGrid;
