// TODO: just placeholders to be replaced later

import { AxiosResponse } from "axios";
import { HttpClient } from "helpers";
import { BaseResponseType, DataGridRequest, PagedResponseType } from "services/shared-models/sharedTypes";
import {
  CreateCourseOrderExaminerRequest,
  ExaminerListResult,
  ExaminerOrderEvaluationFormResult,
  ExaminerResult,
  ExaminerUserResult,
  ReferralOfExaminersRequest,
  RejectExaminerOrderEvaluationFormRequest,
  ReturnExaminerOrderEvaluationFormRequest,
  SubmittedExaminerOrderEvaluationFormRequest,
  ExaminerRewardApprovedRequest,
  UpdateExaminerAccreditationTypeRequest,
} from "./Model/ExaminerTypes";

export const ExaminersService = {
  getAllExaminers: async (dataGridRequest: DataGridRequest) => {
    return await HttpClient.post<PagedResponseType<ExaminerUserResult>>(
      `/api/Examiners/GetAllExaminers`,
      dataGridRequest,
    );
  },
  getAllExaminersByOrderId: async (programOrderId: number) => {
    return await HttpClient.get<BaseResponseType<ExaminerListResult[]>>(`/api/Examiners/GetAllExaminersByOrderId`, {
      params: {
        programOrderId,
      },
    });
  },
  exportExaminersToExcelAsync: async (dataGridRequest: DataGridRequest) => {
    return await HttpClient.post<AxiosResponse<Blob>>(`/api/Examiners/ExportExaminersToExcel`, dataGridRequest, {
      responseType: "blob",
    });
  },
  getExaminerOrderEvaluationForm: async (orderId: number, programOrderExaminerId: number) => {
    return await HttpClient.get<BaseResponseType<ExaminerOrderEvaluationFormResult>>(
      `/api/Examiners/GetExaminerOrderEvaluationForm`,
      {
        params: {
          orderId,
          programOrderExaminerId,
        },
      },
    );
  },
  acceptExaminerOrderEvaluationForm: async (programOrderExaminerId: number) => {
    return await HttpClient.put<BaseResponseType<ExaminerOrderEvaluationFormResult>>(
      `/api/Examiners/AcceptExaminerOrderEvaluationForm/${programOrderExaminerId}`,
    );
  },
  rejectExaminerOrderEvaluationForm: async (
    programOrderExaminerId: number,
    { actionName, serialGuid }: RejectExaminerOrderEvaluationFormRequest,
  ) => {
    return await HttpClient.put<BaseResponseType<ExaminerOrderEvaluationFormResult>>(
      `/api/Examiners/RejectExaminerOrderEvaluationForm/${programOrderExaminerId}`,
      {
        actionName,
        serialGuid,
      },
    );
  },
  returnExaminerOrderEvaluationForm: async (
    programOrderExaminerId: number,
    { actionName, serialGuid, recommendations }: ReturnExaminerOrderEvaluationFormRequest,
  ) => {
    return await HttpClient.put<BaseResponseType<ExaminerOrderEvaluationFormResult>>(
      `/api/Examiners/ReturnedExaminerOrderEvaluationForm/${programOrderExaminerId}`,
      {
        actionName,
        serialGuid,
        recommendations,
      },
    );
  },
  submitExaminerOrderEvaluationForm: async (
    programOrderExaminerId: number,
    request: SubmittedExaminerOrderEvaluationFormRequest,
  ) => {
    return await HttpClient.put<BaseResponseType<ExaminerOrderEvaluationFormResult>>(
      `/api/Examiners/SubmittedExaminerOrderEvaluationForm/${programOrderExaminerId}`,
      request,
    );
  },
  referralOfExaminers: async (request: ReferralOfExaminersRequest) => {
    return await HttpClient.put<BaseResponseType<ExaminerResult>>(`/api/Examiners/ReferralOfExaminers`, request);
  },
  addExaminer: async (request: CreateCourseOrderExaminerRequest[]) => {
    return await HttpClient.post<BaseResponseType<ExaminerResult[]>>(`/api/Examiners/AddExaminer`, request);
  },
  deleteExaminer: async (id: number) => {
    return await HttpClient.delete<BaseResponseType<ExaminerResult>>(`/api/Examiners/DeleteExaminer`, {
      params: {
        id,
      },
    });
  },
  stopExaminerWorkflow: async (examinerId: number) => {
    return await HttpClient.put<BaseResponseType<ExaminerResult>>(`/api/Examiners/StopExaminerWorkflow/${examinerId}`);
  },

  examinerRewardApproved: async (programOrderExaminerId: number, request: ExaminerRewardApprovedRequest) => {
    return await HttpClient.put<BaseResponseType<ExaminerResult>>(
      `/api/Examiners/ExaminerRewardApproved/${programOrderExaminerId}`,
      request,
    );
  },
  updateExaminerAccreditationType: async (
    programOrderExaminerId: number,
    request: UpdateExaminerAccreditationTypeRequest,
  ) => {
    return await HttpClient.put<BaseResponseType<ExaminerResult>>(
      `/api/Examiners/UpdateExaminerAccreditationType/${programOrderExaminerId}`,
      request,
    );
  },
  addExaminerAndAssign: async (request: ReferralOfExaminersRequest) => {
    return await HttpClient.post<BaseResponseType<any>>(`/api/Examiners/AddExaminerAndAssign`, request);
  },
};
