import { useTranslation } from "react-i18next";
import SPFormAttachmentsTable from "../../form/components/SPFormAttachmentsTable";
import { useCookieContext } from "contexts";
import { ScientificPublishingLocalItemResult } from "services/scientificPublicationsService/models/scientificPublicationsTypes";

const SPVMainInfo = ({ data }: { data: ScientificPublishingLocalItemResult }) => {
  const { t } = useTranslation("ScientificPublication");
  const { isAr } = useCookieContext();
  const currentLocale = isAr ? "Ar" : "En";

  return (
    <div>
      <h6 className="fw-bold">{t("publicationDescription")}</h6>
      <p>{data?.scientificPublishingDetails.find((detail) => detail.locale === currentLocale)?.description}</p>

      <div className="striped d-flex flex-column position-relative ">
        <div className="d-flex flex-1 p-3">
          <span className="flex-1 fw-bold text-dark">{t("publicationField")}</span>
          <span className="flex-2">{data?.scientificSpecialization?.scientificSpecializationDetail.displayName}</span>
        </div>
        <div className="d-flex flex-1 p-3">
          <span className="flex-1 fw-bold text-dark">{t("publicationOwner")}</span>
          <span className="flex-2">{data?.publisher?.publisherDetail.displayName}</span>
        </div>
        <div className="d-flex flex-1 p-3">
          <span className="flex-1 fw-bold text-dark">{t("publicationOwnerType")}</span>
          <span className="flex-2">{data?.publisherType?.publisherTypeDetail.displayName}</span>
        </div>
        <div className="d-flex flex-1 p-3">
          <span className="flex-1 fw-bold text-dark">{t("publicationLink")}</span>
          <span className="flex-2">{data?.scientificPublicationLink}</span>
        </div>
        <div className="d-flex flex-1 p-3">
          <span className="flex-1 fw-bold text-dark">{t("ISSNSerialNumber")}</span>
          <span className="flex-2">{data?.serialNumber}</span>
        </div>
        <div className="d-flex flex-1 p-3">
          <span className="flex-1 fw-bold text-dark">{t("numberOfPages")}</span>
          <span className="flex-2">{data?.numberOfPaper}</span>
        </div>
        <div className="d-flex flex-1 p-3">
          <span className="flex-1 fw-bold text-dark">{t("prepareHardCopy")}</span>
          <span className="flex-2">{data?.isHardCopyAvailable ? t("available") : t("notAvailable")}</span>
        </div>
      </div>

      <div className="divider my-4" />

      <h6 className="fw-bold">{t("librariesHavingThisPublication")}</h6>
      <div className="d-flex flex-wrap gap-2">
        {data?.libraries.map((library) => (
          <div className="keyword-badge">{library.libraryDetail.displayName}</div>
        ))}
      </div>

      <div className="divider my-4" />

      <h6 className="fw-bold">{t("searchKeywords")}</h6>
      <div className="d-flex flex-wrap gap-2">
        {data?.searchWords.map((keyword) => (
          <div className="keyword-badge">{keyword.searchWord}</div>
        ))}
      </div>

      <h6 className="fw-bold mt-4">{t("metaData")}</h6>
      <p>{data?.metaData}</p>

      <h6 className="fw-bold mt-4">{t("Common:attachments")}</h6>
      <SPFormAttachmentsTable viewOnly previewData={data?.scientificPublishingAttachments} />
    </div>
  );
};

export default SPVMainInfo;
