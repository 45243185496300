import IconButton from "components/blocks/IconButton";
import GridView from "components/GridView";
import { GridSchema, GridViewHeaderProps } from "components/GridView/GridView.types";
import { useCookieContext } from "contexts";
import { useBreadcrumb } from "contexts/breadcrumb/BreadcrumbContext";
import { useTheme } from "contexts/theme/ThemeContext";
import useGridData from "hooks/useGridData";
import useSystemDateTheme from "hooks/useSystemDateTheme";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ExaminerUserResult } from "services/examinersService/Model/ExaminerTypes";

const ExaminersGridView = () => {
  const { t } = useTranslation("Examiners");
  const navigate = useNavigate();
  const { setBreadcrumbs } = useBreadcrumb();
  const { toSystemThemeDateFormat } = useSystemDateTheme();
  const { isAr } = useCookieContext();
  const { setTheme, resetTheme } = useTheme();

  const { loading, gridData, totalCount, exportToExcel } = useGridData<"examinersService", ExaminerUserResult>({
    service: "examinersService",
    endpoint: "getAllExaminers",
    exportExcelEndpoint: "exportExaminersToExcelAsync",
    exportFileName: "examiners",
    localizationNameSpace: "Examiners",
  });

  useEffect(() => {
    setBreadcrumbs([
      {
        localizationKey: "examinersInfo",
        path: "/home/examinersManagement/examiners",
        menuItemId: "examiners",
      },
    ]);
  }, []);

  useEffect(() => {
    setTheme({ homeLayout: { main: { bgColorClass: "", roundedClass: "" } } });

    return () => {
      resetTheme();
    };
  }, []);

  const gridSchema = useMemo<GridSchema[]>(
    () => [
      {
        field: "arabicName",
        displayName: t("examinerName"),
        sortable: false,
        filterable: false,
        showOnMobile: true,
        render: (row: ExaminerUserResult) => {
          return (
            <button
              className="btn btn-link"
              onClick={() => navigate(`/home/examinersManagement/examiners/view?examinerId=${row.id}`)}
            >
              {isAr ? row.arabicName : row.englishName}
            </button>
          );
        },
      },
      {
        field: "username",
        displayName: t("username"),
      },
      {
        field: "taskCount",
        displayName: t("completedTasks"),
      },
      {
        field: "createdOn",
        displayName: t("joinDate"),
        // filterable: true,
        // filterOptions: {
        //   type: "date",
        // },
        render: (row: ExaminerUserResult) => {
          return toSystemThemeDateFormat(row.createdOn ?? undefined);
        },
      },
      {
        field: "IsActive",
        displayName: t("Common:status"),
        render(row: ExaminerUserResult) {
          const statusClassName = row.isActive
            ? "bg-primary bg-opacity-10 text-primary"
            : "bg-danger bg-opacity-10 text-danger";
          return (
            <span className={`badge rounded-4 ${statusClassName} py-2`}>
              {t(row.isActive ? "Common:active" : "Common:inactive")}
            </span>
          );
        },
      },
      {
        field: "id",
        displayName: t("Common:actions"),
        showOnMobile: true,
        render: (row: ExaminerUserResult) => {
          return (
            <IconButton
              title={t("Common:edit")}
              icon="icon-edit"
              fitToIconWidth
              innerIconColor="dark"
              size="md"
              onClick={() => {
                navigate(`/home/editUserProfile/${row.id}`);
              }}
            />
          );
        },
      },
    ],
    [t, toSystemThemeDateFormat],
  );

  const viewHeader: GridViewHeaderProps = {
    title: t("examiners"),
    singularName: t("examiner"),
    totalCount: totalCount,
    onExportClick: exportToExcel,
  };

  return (
    <div className="d-flex flex-column h-100 gap-3">
      <div
        className="flex-1 bg-white rounded-4 overflow-y-auto"
        style={{
          marginInline: -20,
          marginTop: -20,
          padding: 20,
        }}
      >
        <GridView
          loading={loading}
          gridProps={{
            data: gridData ?? [],
            gridSchema,
          }}
          viewHeaderProps={viewHeader}
        />
      </div>
      <div className="bg-white rounded-4 p-3" style={{ marginInline: -20, marginBottom: -20 }}>
        <b className="text-danger px-1">*</b>
        {t("page-note")}
      </div>
    </div>
  );
};

export default ExaminersGridView;
