import FormInput from "components/blocks/FormInput";
import IconButton from "components/blocks/IconButton";
import RangeInput from "components/blocks/RangeInput";
import { useTranslation } from "react-i18next";
import { CreateCourseRequest } from "services/courseService/models/CourseType";
import { FieldErrors, UseFormSetValue, UseFormWatch } from "react-hook-form";
import LookupDropdown from "components/blocks/LookupDropdown";

type RewardCalculationItemProps = {
  index: number;
  onDelete: (index: number) => void;
  setValue: UseFormSetValue<CreateCourseRequest>;
  errors: FieldErrors<CreateCourseRequest>;
  watch: UseFormWatch<CreateCourseRequest>;
};

const RewardCalculationItem: React.FC<RewardCalculationItemProps> = ({ onDelete, setValue, errors, watch, index }) => {
  const { t, i18n } = useTranslation("Courses");
  const handleOnChange = (value: string) => {
    setValue(`bonusCalculationTerms.${index}.notes`, value);
  };

  const handleRangeChange = (value: [string, string]) => {
    setValue(`bonusCalculationTerms.${index}.fromValue`, +value[0]);
    setValue(`bonusCalculationTerms.${index}.toValue`, +value[1]);
  };

  const onTypeChange = (value: string) => {
    setValue(`bonusCalculationTerms.${index}.bonusTypeId`, +value);
  };

  return (
    <div className="d-flex gap-4 align-items-end mb-3">
      <div className="row flex-grow-1">
        <LookupDropdown
          label={t("rewardType")}
          service="bounceTypeService"
          endpoint="getAllBounceTypeDropDown"
          idValueKey="id"
          noSelectionPlaceholder={t("rewardType")}
          textValueKey={`details.${i18n.language.toLowerCase() == "en" ? 1 : 0}.displayName`}
          wrapperClassName="col-6 col-md-4"
          onChange={onTypeChange}
          value={watch(`bonusCalculationTerms.${index}.bonusTypeId`)}
          error={t(errors.bonusCalculationTerms?.[index]?.bonusTypeId?.message ?? "")}
        />
        <FormInput
          wrapperClassName="col-6 col-md-4"
          label={t("Common:notes")}
          error={t(errors.bonusCalculationTerms?.[index]?.notes?.message ?? "")}
          value={watch(`bonusCalculationTerms.${index}.notes`)}
          onChange={(e) => handleOnChange(e.target.value)}
        />
        <RangeInput
          wrapperClassName="col-6 col-md-4"
          label={t("amountFromTo")}
          leftElement={<span className="text-primary">{t("Common:riyal")}</span>}
          placeholderStart={t("Common:from")}
          placeholderEnd={t("Common:to")}
          value={[
            watch(`bonusCalculationTerms.${index}.fromValue`)?.toString() ?? "",
            watch(`bonusCalculationTerms.${index}.toValue`)?.toString() ?? "",
          ]}
          error={t(
            (errors.bonusCalculationTerms?.[index]?.fromValue?.message ||
              errors.bonusCalculationTerms?.[index]?.toValue?.message) ??
              "",
          )}
          onChange={handleRangeChange}
        />
      </div>
      <IconButton size="xl" variant="danger" icon="icon-delete" onClick={() => onDelete(index)} />
    </div>
  );
};

export default RewardCalculationItem;
