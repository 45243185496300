import { zodResolver } from "@hookform/resolvers/zod";
import { CourseStatus, CreateCourseRequest } from "services/courseService/models/CourseType";
import { z } from "zod";

export type CreateCourseRequestFormProps = CreateCourseRequest & {
  cycleYear?: number;
  serialNumber?: string;
};

export const getCreateCourseSchema = (t: any) => {
  const schema = z.object({
    status: z.enum([CourseStatus.Completed.label, CourseStatus.Draft.label]),
    programCycleId: z.number({
      message: t("Common:invalidValue"),
    }),
    startDate: z.date(),
    applyCloseDate: z.date(),
    courseImageId: z.string(),
    bonusCalculationTerms: z.array(
      z.object({
        bonusTypeId: z.number(),
        notes: z.string(),
        fromValue: z.number(),
        toValue: z.number(),
      }),
    ),
    filesIds: z.array(z.string()),
    courseDetails: z.array(
      z.object({
        locale: z.enum(["Ar", "En"]),
        ruleAndConditionTemplate: z.string(),
        commitmentTemplate: z.string(),
        ruleAndConditionName: z.string(),
        commitmentName: z.string(),
        displayName: z.string().min(1, t("Common:invalidValue")),
        description: z.string().min(1, t("Common:invalidValue")),
      }),
    ),
  }) as z.ZodType<CreateCourseRequestFormProps>;

  return zodResolver(schema);
};
