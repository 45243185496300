import { GridSchema } from "components/GridView/GridView.types";
import Grid from "components/GridView/components/Grid";
import Accordion from "components/blocks/Accordion";
import AccordionItem from "components/blocks/Accordion/AccordionItem";
import IconButton from "components/blocks/IconButton";
import useSystemDateTheme from "hooks/useSystemDateTheme";
import { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import FormInput from "components/blocks/FormInput";
import ResearcherActions from "./ResearcherActions";
import ResearcherFileUpdate from "./ResearcherFileUpdate";
import { useQuery } from "hooks/useQuery";
import Dropdown from "components/blocks/Dropdown";
import useAddEditProgramOrderUpdateForm from "./useAddEditProgramOrderUpdateForm";
import useProgramOrderUpdateActions, { EvaluateProgramUpdateRef } from "./useProgramOrderUpdateActions";
import AddEvaluationNoteModal from "./Modals/AddEvaluationNoteModal";
import { UpdateNotes } from "./UpdateNotes";
import { CourseOrderLocalItemResult } from "services/courseOrder/models/CourseOrderType";
import { ActivePages, OrderUserTypes, UserTypes } from "views/home/orders/OrderView/orderPage.types";
import { ProgramOrderUpdateService } from "services/programOrderUpdate";
import { ProgramOrderUpdateResult, UpdateOrderEvaluationType } from "services/programOrderUpdate/models";

const Updates = ({
  userType,
  setActivePage,
  programOrderUpdateId,
  order,
  programUpdateHandlersRef,
}: {
  userType: UserTypes;
  setActivePage: Dispatch<SetStateAction<ActivePages>>;
  programOrderUpdateId: number;
  order: CourseOrderLocalItemResult | null;
  programUpdateHandlersRef: React.MutableRefObject<EvaluateProgramUpdateRef | null>;
}) => {
  const { t } = useTranslation("Orders");
  const { toSystemThemeDateFormat } = useSystemDateTheme();

  const selectedFileIdToDownload = useRef<number>();
  const [isAddEvaluationNoteModalOpen, setIsAddEvaluationNoteModalOpen] = useState(false);
  const {
    isProgramOrderUpdateLoading,
    fileDownload,
    fileDownloadLoading,
    onSubmit,
    selectedFile,
    setSelectedFile,
    errors,
    setValue,
    watch,
    triggerTableChange,
    formRef,
  } = useAddEditProgramOrderUpdateForm({ orderId: order?.id ?? 0, programOrderUpdateId });

  const {
    setValue: setEvaluateValue,
    extendedTime,
    setExtendedTime,
  } = useProgramOrderUpdateActions({
    programOrderUpdateId,
    programUpdateHandlersRef,
    orderId: order?.id ?? 0,
    onEditProgramOrderUpdate: onSubmit,
  });

  const { data } = useQuery({
    queryFn: async () => {
      return await ProgramOrderUpdateService.getAllProgramOrderUpdates(order?.id ?? 0);
    },
    options: {
      enabled: !!order?.id,
    },
    triggers: [triggerTableChange],
  });

  const gridSchema = useMemo<GridSchema[]>(
    () => [
      {
        accessor: "details",
        displayName: t("details"),
        field: "details",
        columnClassName: "w-50",
        showOnMobile: true,
      },
      {
        field: "completionRate",
        accessor: "completionRate",
        displayName: t("completionRate"),
        showOnMobile: true,
      },
      {
        field: "updateOrderEvaluationType",
        accessor: "updateOrderEvaluationType",
        displayName: t("evaluation"),
        render(row: ProgramOrderUpdateResult) {
          /*  if (userType === OrderUserTypes.ResearchCenterManagerForOrderUpdate && row.id === programOrderUpdateId) {
            return (
              <Dropdown
                data={[
                  {
                    id: UpdateOrderEvaluationType.Satisfied.value,
                    value: t(UpdateOrderEvaluationType.Satisfied.label),
                  },
                  {
                    id: UpdateOrderEvaluationType.NotSatisfied.value,
                    value: t(UpdateOrderEvaluationType.NotSatisfied.label),
                  },
                ]}
                onChange={(value) => {
                  setEvaluateValue("evaluationType", +value);
                }}
                noSelectionPlaceholder={t("selectEvaluationType")}
                defaultValue={
                  UpdateOrderEvaluationType.Satisfied.label === row.updateOrderEvaluationType
                    ? UpdateOrderEvaluationType.Satisfied.value
                    : UpdateOrderEvaluationType.NotSatisfied.label === row.updateOrderEvaluationType
                    ? UpdateOrderEvaluationType.NotSatisfied.value
                    : ""
                }
              />
            );
          }
 */
          return <>{t(row.updateOrderEvaluationType ?? "")}</>;
        },
      },
      {
        field: "createdOn",
        accessor: "createdOn",
        displayName: t("date"),
        render(row: ProgramOrderUpdateResult) {
          return <>{toSystemThemeDateFormat(row.createdOn)}</>;
        },
      },
      {
        field: "centerManagerNotes",
        accessor: "centerManagerNotes",
        displayName: t("centerManagerNotes"),
      },
      {
        field: "actions",
        accessor: "actions",
        displayName: t("actions"),
        showOnMobile: true,
        render: (row: ProgramOrderUpdateResult) => (
          <div className="d-flex  gap-2">
            {/* {selectedFileIdToDownload.current === row.id && fileDownloadLoading ? (
              <div className="custom-loader"></div>
            ) : (
              !row.centerManagerNotes &&
              userType === OrderUserTypes.ResearchCenterManagerForOrderUpdate && (
                <IconButton
                  icon="icon-add-document"
                  fitToIconWidth
                  variant="transparent"
                  innerIconColor="primary"
                  size="lg"
                  onClick={async () => {
                    selectedFileIdToDownload.current = row.id;
                    setIsAddEvaluationNoteModalOpen(true);
                  }}
                />
              )
            )} */}
            <IconButton
              icon="icon-document"
              fitToIconWidth
              variant="transparent"
              innerIconColor="primary"
              size="lg"
              onClick={async () => {
                await fileDownload(row.fileId ?? "");
              }}
            />
          </div>
        ),
      },
    ],
    [fileDownloadLoading, data],
  );

  const programOrderUpdateToEdit = data?.result.find((x) => x.id === programOrderUpdateId);
  useEffect(() => {
    if (programOrderUpdateToEdit) {
      setValue("details", programOrderUpdateToEdit.details ?? "");
      setValue("completionRate", programOrderUpdateToEdit.completionRate);
      setValue("fileId", programOrderUpdateToEdit.fileId ?? "");
      setSelectedFile({
        fileName: programOrderUpdateToEdit.file.name ?? "",
        uuid: programOrderUpdateToEdit.fileId ?? "",
        size: programOrderUpdateToEdit.file.size,
        file: new File([], programOrderUpdateToEdit.file.name ?? ""),
      });
    }
  }, [programOrderUpdateToEdit]);

  const getUserPageProps = () => {
    const props: {
      showNotes?: boolean;
      showProgressUpdateForm?: boolean;
      showExtendDuration?: boolean;
      showTable?: boolean;
      showActions?: boolean;
      accordionTitle?: string;
      showNoteTextArea?: boolean;
      hideAccordion?: boolean;
    } = {};

    /* if (userType === OrderUserTypes.ResearcherSendUpdateResponseAfterReturnRequest) {
      props.showNotes = true;
      props.showProgressUpdateForm = true;
      props.showTable = true;
      props.showNoteTextArea = true;
      props.accordionTitle = t("updateRequest");
    } else if (userType === OrderUserTypes.ResearcherSendApologizeResponseAfterReturnRequest) {
      props.showNotes = true;
      props.accordionTitle = t("apologizeRequest");
      props.showNoteTextArea = true;
    } else if (userType === OrderUserTypes.ResearcherSendExtendResponseAfterReturnRequest) {
      props.showNotes = true;
      props.accordionTitle = t("extendRequest");
      props.showNoteTextArea = true;
      props.showExtendDuration = true;
    } else if (userType === OrderUserTypes.Researcher) {
      props.showNotes = true;
      props.showProgressUpdateForm = order?.showProgressUpdateForm;
      props.showTable = true;
      props.showActions = true;
      props.accordionTitle = t("updates");
    } else if (userType === OrderUserTypes.ResearcherEditProgramOrderTask) {
      props.showNotes = true;
      props.showProgressUpdateForm = true;
      props.showTable = true;
      props.accordionTitle = t("updates");
    } else if (userType === OrderUserTypes.ResearchCenterManagerQueryRequest) {
      props.showNotes = true;
      props.showNoteTextArea = true;
      props.accordionTitle = t("queryRequest");
    } else if (userType === OrderUserTypes.ResearcherSendQueryResponse) {
      props.hideAccordion = true; // this only make sure that programUpdateHandlersRef.current is not null
    } else if (userType === OrderUserTypes.DeanOfScientificResearchForResearcherExtendRequest) {
      props.hideAccordion = true; // this only make sure that programUpdateHandlersRef.current is not null
    } else {
      props.showProgressUpdateForm = false;
      props.showTable = true;
      props.accordionTitle = t("updates");
    } */

    props.showProgressUpdateForm = false;
    props.showTable = true;
    props.accordionTitle = t("updates");

    return props;
  };

  const userPageProps = getUserPageProps();

  return !userPageProps.hideAccordion ? (
    <Accordion>
      <AccordionItem initiallyOpen title={userPageProps.accordionTitle ?? t("updates")} elementId={"updatesForm"}>
        <div className="d-flex flex-column gap-2">
          {userPageProps.showNotes && <UpdateNotes programOrderUpdate={programOrderUpdateToEdit} />}
          {userPageProps.showProgressUpdateForm && (
            <form ref={formRef} noValidate className="d-flex gap-2 mt-2" onSubmit={onSubmit}>
              <FormInput
                label={t("details")}
                wrapperClassName="flex-1 fw-bold"
                onChange={(e) => {
                  setValue("details", e.target.value);
                }}
                error={errors.details?.message}
                value={watch("details")}
              />
              <FormInput
                label={t("completionRate")}
                wrapperClassName="flex-1 fw-bold"
                leftElement={<span className="text-primary">%</span>}
                onChange={(e) => {
                  setValue("completionRate", +e.target.value);
                }}
                error={errors.completionRate?.message}
                value={watch("completionRate")}
              />
              <ResearcherFileUpdate selectedFile={selectedFile} setSelectedFile={setSelectedFile} setValue={setValue} />
              <div>
                <label className="mb-1 invisible">{t("Common:save")}</label>
                {/* <button className="btn btn-primary w-100 p-10px" disabled={isProgramOrderUpdateLoading}>
                  {userType === OrderUserTypes.ResearcherEditProgramOrderTask ? t("Common:edit") : t("Common:save")}
                </button> */}
              </div>
            </form>
          )}

          {userPageProps.showTable && (
            <>
              <div className="mt-2">
                <Grid gridSchema={gridSchema} data={data?.result ?? []} />
              </div>
              <div className="divider"></div>
            </>
          )}

          {/*   {userPageProps.showActions && <ResearcherActions orderId={order?.id ?? 0} setActivePage={setActivePage} />} */}
        </div>

        <div className="d-flex flex-column gap-2">
          {userPageProps.showExtendDuration && (
            <FormInput
              type="number"
              label={t("newExtendDuration")}
              placeholder={t("newExtendDuration")}
              wrapperClassName="flex-1 "
              value={extendedTime}
              onChange={(e) => setExtendedTime(+e.target.value)}
            />
          )}
          {userPageProps.showNotes && (
            <div>
              <label className="form-label mt-2 fw-bold">{t("notes")}</label>
              <textarea
                className="form-control "
                rows={5}
                placeholder={t("notes")}
                onChange={(e) => setEvaluateValue("workFlowTaskRequest.notes", e.target.value)}
                style={{ resize: "none" }}
              />
            </div>
          )}
        </div>
      </AccordionItem>

      <AddEvaluationNoteModal
        isOpen={isAddEvaluationNoteModalOpen}
        onClose={() => setIsAddEvaluationNoteModalOpen(false)}
        onConfirm={(note) => {
          setEvaluateValue("centerManagerNotes", note);
          setIsAddEvaluationNoteModalOpen(false);
        }}
      />
    </Accordion>
  ) : (
    <></>
  );
};

export default Updates;
