import useSystemDateTheme from "hooks/useSystemDateTheme";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CourseOrderStatus } from "services/courseOrder/models/CourseOrderType";
import AboutCourseTab from "views/home/courses/CourseView/tabs/AboutCourseTab";
import OrderView from "views/home/orders/OrderView";
import { tabsIds } from "views/home/orders/OrderView/orderPage.types";

const ViewUnPublishedCourseOrder = () => {
  const { t } = useTranslation("Orders");
  const { toSystemThemeDateFormat } = useSystemDateTheme();

  return (
    <OrderView
      withImage
      breadcrumbs={({ orderId, serialNumber }) => [
        {
          localizationKey: "myAllOrders",
          path: "/home/authorOrders/MyAllOrders",
          menuItemId: "allOrders",
        },
        {
          localizationKey: "order-number",
          path: `/home/orders/unpublishedOrder/${orderId}`,
          localizationParameters: { orderNumber: serialNumber },
        },
      ]}
      defaultTabId="courseInfo"
      tapsToView={({ courseData }) => {
        return [
          {
            id: tabsIds.courseInfo,
            title: t("aboutCourse"),
            content: <AboutCourseTab course={courseData} />,
          },
        ];
      }}
      userType="StudentForPublishedOrder"
      headersToView={({ courseOrderData, courseData }) => [
        {
          title: t("orderNumber"),
          value: courseOrderData?.serialNumber ?? "",
        },
        {
          title: t("applicationDate"),
          value: toSystemThemeDateFormat("2024-01-01"), //TODO": return id
        },
        {
          title: t("courseStartDate"),
          value: toSystemThemeDateFormat(courseData?.startDate),
        },
        {
          title: t("applyCloseDate"),
          value: toSystemThemeDateFormat(courseData?.applyCloseDate),
        },
        {
          title: t("researchCenter"),
          value: `${courseOrderData?.centreManagement.displayName ?? ""}`,
        },
        {
          title: t("numberOfPages"),
          value: courseOrderData?.numberOfPages ?? 0,
        },
      ]}
      leftSection={({ courseOrderData }) => {
        const status = Object.values(CourseOrderStatus).find((s) => s.id === courseOrderData?.courseOrderStatusId);
        const className = status ? `bg-${status?.color} text-${status?.color}` : "bg-primary text-primary";

        return (
          <span className={`badge bg-opacity-10 ${className} rounded-4  py-2`}>{"قيد الاجراء"}</span>
        ); /* TODO: must be implemented from the back */
      }}
    />
  );
};

export default ViewUnPublishedCourseOrder;
