import { CreateScientificPublicationRequest } from "views/home/scientificPublication/form/ScientificPublicationForm.types";
import {
  GetAllScientificPublicationsResponse,
  ScientificPublicationResult,
  ScientificPublishingLocalItemPublicResult,
  ScientificPublishingLocalItemResult,
  ScientificPublishingLocalPublicResult,
} from "./models/scientificPublicationsTypes";
import { HttpClient } from "helpers";
import { BaseResponseType, DataGridRequest } from "services/shared-models/sharedTypes";
import { AxiosResponse } from "axios";

export const ScientificPublicationsService = {
  getAllScientificPublishing: async (gridRequest: DataGridRequest) => {
    return HttpClient.post<GetAllScientificPublicationsResponse>(
      "/api/ScientificPublications/GetAllScientificPublishing",
      gridRequest,
    );
  },
  exportToExcel: async (gridRequest: DataGridRequest) => {
    return HttpClient.post<AxiosResponse<Blob>>(
      "/api/ScientificPublications/ExportScientificPublishingToExcel",
      gridRequest,
      {
        responseType: "blob",
      },
    );
  },
  createScientificPublication: async (data: CreateScientificPublicationRequest) => {
    return HttpClient.post<BaseResponseType<ScientificPublicationResult>>(
      "/api/ScientificPublications/CreateScientificPublication",
      data,
    );
  },
  getScientificPublicationById: async (id: number) => {
    return HttpClient.get<BaseResponseType<ScientificPublishingLocalItemResult>>(
      `/api/ScientificPublications/GetScientificPublishingById/${id}`,
    );
  },
  delete: async (id: number) => {
    return HttpClient.delete<BaseResponseType<ScientificPublicationResult>>(
      `/api/ScientificPublications/DeleteScientificPublishing/${id}`,
    );
  },

  getAllPublicScientificPublishing: async (gridRequest: DataGridRequest) => {
    return HttpClient.post<BaseResponseType<ScientificPublishingLocalPublicResult[]>>(
      "/api/ScientificPublications/GetAllPublicScientificPublishing",
      gridRequest,
    );
  },
  getPublicScientificPublishingById: async (id: number) => {
    return HttpClient.get<BaseResponseType<ScientificPublishingLocalItemPublicResult>>(
      `/api/ScientificPublications/GetPublicScientificPublishingById/${id}`,
    );
  },
  updateScientificPublication: async (id: number, data: CreateScientificPublicationRequest) => {
    return HttpClient.put<BaseResponseType<ScientificPublicationResult>>(
      `/api/ScientificPublications/UpdateScientificPublishing/${id}`,
      data,
    );
  },
};
