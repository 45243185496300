import { Link, useParams } from "react-router-dom";
import NavBar from "../NavBar";
import { useQuery } from "hooks/useQuery";
import LoadingOverlay from "components/blocks/LoadingOverlay";
import { useTranslation } from "react-i18next";
import useSystemDateTheme from "hooks/useSystemDateTheme";
import AttachmentCard from "components/blocks/AttachmentCard";
import RichTextEditor from "components/RichTextEditor";
import { useCookieContext } from "contexts";
import { CourseService } from "services/courseService";

const CourseDetails = () => {
  const { userSession, isAr } = useCookieContext();
  const { toSystemThemeDateFormat } = useSystemDateTheme();
  const { t } = useTranslation("WelcomePage");
  const { id } = useParams();

  const { data: course, loading } = useQuery({
    queryFn: async () => {
      return await CourseService.getPublicCourseById(id ? +id : 0);
    },
    triggers: [id, isAr],
    options: { enabled: !!id },
  });

  return (
    <div className=" bg-white d-flex flex-column gap-4 position-relative text-dark">
      <LoadingOverlay visible={loading} className="h-100" />

      <div className="shadow py-1  px-6rem ">
        <NavBar tapClassName="text-dark" />
      </div>

      <div className="publications-details-container  d-flex flex-column gap-4 ">
        <div className=" d-flex align-items-center  rounded-4  justify-content-center position-relative">
          <img className="logo " src={course?.courseImageUrl} alt={course?.courseDetail.description} />
          <div className="overlay">
            <h1 className=" text-white">{course?.courseDetail.displayName}</h1>
          </div>
        </div>

        <div>
          <h5 className="fw-bold">{t("courseDescription")} </h5>
          <span className="fs-6">{course?.courseDetail.description}</span>
        </div>

        <div className="striped d-flex flex-column gap-2">
          <div className="d-flex p-3">
            <span className="flex-1 fw-bold">{t("courseNumber")}</span>
            <span className="flex-2"> {course?.serialNumber}</span>
          </div>
          <div className="d-flex p-3">
            <span className="flex-1 fw-bold"> {t("courseStartDate")}</span>
            <span className="flex-2"> {toSystemThemeDateFormat(course?.startDate)}</span>
          </div>
          <div className="d-flex p-3">
            <span className="flex-1 fw-bold"> {t("applyCloseDate")} </span>
            <span className="flex-2"> {toSystemThemeDateFormat(course?.applyCloseDate)}</span>
          </div>
        </div>

        <div>
          <h5 className="fw-bold"> {t("courseRules")}</h5>
          {course?.courseDetail && (
            <RichTextEditor initialData={course?.courseDetail.ruleAndConditionTemplate} isReadOnly />
          )}
        </div>

        <div>
          <h5 className="fw-bold">{t("requiredCommitment")} </h5>
          {course?.courseDetail && <RichTextEditor initialData={course?.courseDetail.commitmentTemplate} isReadOnly />}
        </div>

        <div>
          <h5 className="fw-bold"> {t("attachments")} </h5>
          <div className="d-flex flex-wrap gap-2 align-items-start">
            {course?.files.map((file) => (
              <AttachmentCard
                key={file.id}
                attachment={{
                  extension: file.extension ?? "",
                  fileName: file.name ?? "",
                  size: file.size ?? 0,
                  id: file.id ?? 0,
                }}
              />
            ))}
          </div>
        </div>

        <Link
          to={!userSession ? `/account/login?courseId=${id}` : `/home/orders/add?courseId=${id}`} // TODO: publ to be change later
          className="btn btn-primary p-2 my-4 rounded-3"
        >
          {t("applicationForm")}
        </Link>
      </div>
    </div>
  );
};

export default CourseDetails;
