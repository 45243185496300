import Accordion from "components/blocks/Accordion";
import AccordionItem from "components/blocks/Accordion/AccordionItem";
import DatePicker from "components/blocks/DatePicker";
import FilePreview from "components/blocks/FileInput/FilePreview";
import FormInput from "components/blocks/FormInput";
import { RefObject, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import PreviousRecords from "./PreviousRecords";
import FileInput from "components/blocks/FileInput";
import useMutation from "hooks/useMutation";
import { Controller, useForm } from "react-hook-form";
import { getCreateCourseMinuteSchema } from "./courseMinute.schema";
import { FileService } from "services/fileService";
import LoadingOverlay from "components/blocks/LoadingOverlay";
import PaginatedLookupDropdown from "components/blocks/PaginatedLookupDropdown";
import { SelectedFilesType } from "components/blocks/FileUploadWithPreview";
import { AdminTapActionsRef } from "../../UserTypeOrderView/CourseOrderAdminView";
import { CreateCourseOrderMinuteRequest } from "services/courseMinutesService/Model/CourseSpeechesTypes";
import { CourseMinutesService } from "services/courseMinutesService";

const now = new Date();
const CourseMinutesTab = ({
  submitRef,
  courseOrderId,
}: {
  submitRef: RefObject<AdminTapActionsRef>;
  courseOrderId: number;
}) => {
  const formRef = useRef<HTMLFormElement>(null);
  const { t, i18n } = useTranslation(["Orders", "Common"]);

  const [shouldUpdateMinutesTable, setShouldUpdateMinutesTable] = useState(true);
  const [files, setFiles] = useState<SelectedFilesType[]>([]);
  const {
    formState: { errors },
    handleSubmit,
    register,
    setValue,
    control,
    reset,
  } = useForm<CreateCourseOrderMinuteRequest>({
    resolver: getCreateCourseMinuteSchema(t),
    values: {
      date: now,
      description: "",
      files: [],
      courseMinutesNumber: "",
      courseOrderId: courseOrderId,
      users: [],
    },
  });

  const { loading, mutateAsync } = useMutation({
    queryFn: async (values: CreateCourseOrderMinuteRequest) => {
      return await CourseMinutesService.createCourseMinute(values);
    },
  });

  const { loading: fileUploadLoading, mutateAsync: uploadFileAsync } = useMutation({
    queryFn: async (values: File) => {
      return await FileService.uploadFile(values);
    },
  });

  const onFileUpload = async (file: File) => {
    const response = await uploadFileAsync(file);
    if (response && !response.hasError) {
      setFiles([
        ...files,
        {
          fileName: response.data!.fileName,
          uuid: response.data!.uuid,
          file,
          size: `${(file.size / 1024 / 1024).toFixed(2)}MB`,
        },
      ]);
    }
  };

  const onSubmit = async (values: CreateCourseOrderMinuteRequest) => {
    values.files = files.map((f) => f.uuid);
    const response = await mutateAsync(values);
    if (response && !response.hasError) {
      setShouldUpdateMinutesTable(!shouldUpdateMinutesTable);
      reset();
      setFiles([]);
      formRef.current?.classList.remove("was-validated");
    }
  };

  useImperativeHandle(submitRef, () => ({
    submit: async () => {
      formRef.current?.classList.add("was-validated");
      await handleSubmit(async (values) => {
        await onSubmit(values);
      })();
    },
  }));

  return (
    <form ref={formRef} noValidate onSubmit={handleSubmit(onSubmit)}>
      <LoadingOverlay visible={loading || fileUploadLoading} />
      <Accordion>
        <AccordionItem initiallyOpen title={t("newRecord")} elementId={"new-record"}>
          <div className="d-flex flex-column gap-2 pb-4 border-bottom border-1">
            <div className="d-flex gap-2 ">
              <FormInput
                label={t("recordNumber")}
                wrapperClassName="flex-grow-1"
                {...register("courseMinutesNumber")}
                error={errors.courseMinutesNumber?.message}
              />

              <DatePicker
                label={t("date")}
                wrapperClassName="flex-grow-1"
                error={errors.date?.message}
                onChange={(date) => {
                  setValue("date", date ?? new Date());
                }}
              />

              <Controller
                name="users"
                control={control}
                render={({ field }) => (
                  <PaginatedLookupDropdown
                    service="accountService"
                    endpoint="getUsersDropdown"
                    queryKey="members"
                    isPaginated
                    multiselect
                    idValueKey="id"
                    error={errors.users?.message}
                    textValueKey={i18n.language === "ar" ? "arabicName" : "englishName"}
                    label={t("members")}
                    wrapperClassName="flex-grow-1"
                    value={field.value}
                    onChange={(values: string[]) => {
                      field.onChange(values);
                    }}
                  />
                )}
              />
            </div>
            <div className="d-flex">
              <FormInput
                label={t("recordDetails")}
                wrapperClassName="flex-grow-1"
                {...register("description")}
                error={errors.description?.message}
              />
            </div>

            <div className="d-flex flex-column">
              <div className="d-flex flex-grow-1">
                <FileInput
                  label={t("attachments")}
                  clickHereForLabel={t("Common:toAttachFile")}
                  containerWrapperClassName="flex-grow-1"
                  {...register("files")}
                  onChange={(files) => {
                    files && onFileUpload(files[0]);
                  }}
                  // onChange={(files) => onFileUpload(files[0])}
                />
              </div>
              <div className="d-flex flex-wrap gap-2 mt-2">
                {files.map((file) => (
                  <FilePreview
                    key={file.uuid}
                    wrapperClassName="flex-grow-1"
                    onDelete={() => {
                      setFiles(files.filter((f) => f.uuid !== file.uuid));
                    }}
                    onShow={() => {}}
                    file={file.file}
                  />
                ))}
              </div>
            </div>
          </div>
        </AccordionItem>
      </Accordion>

      <Accordion>
        <AccordionItem initiallyOpen title={t("previousRecords")} elementId={"previous-records"}>
          <PreviousRecords courseOrderId={courseOrderId} shouldUpdateTable={shouldUpdateMinutesTable} />
        </AccordionItem>
      </Accordion>
    </form>
  );
};

export default CourseMinutesTab;
