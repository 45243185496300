import { useForm } from "react-hook-form";
import useMutation from "hooks/useMutation";
import { useTranslation } from "react-i18next";
import { useQuery } from "hooks/useQuery";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { SelectedFilesType } from "components/blocks/FileUploadWithPreview";
import { CreateCourseOrderRequestFormProps, getCreateOrderSchema } from "./createOrder.schema";
import { CourseOrderService } from "services/courseOrder";
import { CourseOrderStatus, CreateCourseOrderRequest } from "services/courseOrder/models/CourseOrderType";
import { CourseService } from "services/courseService";

const useOrderForm = () => {
  const { t } = useTranslation("Orders");
  const navigate = useNavigate();
  const routeParams = useParams();
  const [params] = useSearchParams();
  const [formSchema, setFormSchema] = useState();

  const courseOrderId = routeParams.orderId;
  const courseId = params.get("courseId");

  // STATES
  const isDraftSubmit = useRef(true);
  const [researcherAttachments, setResearcherAttachments] = useState<SelectedFilesType[]>([]);
  const [researcherAttachmentWithoutInfo, setResearcherAttachmentWithoutInfo] = useState<SelectedFilesType[]>([]);
  const {
    formState: { errors },
    handleSubmit,
    register,
    watch,
    getValues,
    setValue,
    reset,
  } = useForm<CreateCourseOrderRequestFormProps>({
    defaultValues: {
      acceptAmendments: false,
      acceptTermsAndCondition: false,
      actionName: null,
      centreManagementId: 0,
      formValue: "",
      appliedProcessInstanceId: 0,
      isDraft: true,
      languageOfScientificId: 0,
      numberOfPages: 0,
      researcherAttachmentIds: [],
      researcherAttachmentWithoutInfoIds: [],
      researcherNotes: "",
      scientificClassificationId: 0,
      scientificSpecializationId: 0,
      serialGuid: null,
      typeOfScientificId: 0,
      courseId: 0,
      isFormValueReady: true,
    },
    resolver: getCreateOrderSchema(t),
  });

  const { data: courseOrderData, loading: isOrderLoading } = useQuery({
    queryFn: async () => {
      return await CourseOrderService.getCourseOrderById(courseOrderId ? +courseOrderId : 0);
    },
    options: {
      enabled: !!courseOrderId,
    },
    triggers: [courseOrderId],
  });

  const { data: courseData, loading: isLoading } = useQuery({
    queryFn: async () => {
      return await CourseService.getCourseById((courseId ? +courseId : courseOrderData?.courseId) ?? 0);
    },
    options: {
      enabled: !!courseId || !!courseOrderData?.courseId,
    },
    triggers: [courseId, courseOrderData],
  });

  const { mutateAsync, loading: isCreatingOrder } = useMutation({
    queryFn: async (data: CreateCourseOrderRequest) => {
      data.courseId = courseData!.id;

      return courseOrderId
        ? await CourseOrderService.updateCourseOrder(+courseOrderId!, data)
        : await CourseOrderService.createCourseOrder(data);
    },
  });

  useEffect(() => {
    if (courseOrderData) {
      setResearcherAttachments(
        courseOrderData.researcherAttachment.map((file) => ({
          file: new File([], file.name ?? ""),
          fileName: file.name ?? "",
          uuid: file.id.toString(),
          size: file.size,
        })),
      );

      setResearcherAttachmentWithoutInfo(
        courseOrderData.researcherAttachmentWithoutInfo.map((file) => ({
          file: new File([], file.name ?? ""),
          fileName: file.name ?? "",
          uuid: file.id.toString(),
          size: file.size,
        })),
      );

      setFormSchema(
        courseOrderData.scientificClassification.jsonSchema
          ? JSON.parse(courseOrderData.scientificClassification.jsonSchema)
          : null,
      );

      reset({
        ...courseOrderData,
        researcherAttachmentIds: courseOrderData.researcherAttachment.map((x) => x.id),
        researcherAttachmentWithoutInfoIds: courseOrderData.researcherAttachmentWithoutInfo.map((x) => x.id),
        scientificClassificationId: courseOrderData.scientificClassification.id,
        scientificSpecializationId: courseOrderData.scientificSpecialization.id,
        typeOfScientificId: courseOrderData.typeOfScientific.id,
        languageOfScientificId: courseOrderData.languageOfScientific.id,
        centreManagementId: courseOrderData.centreManagementId,
        isDraft: courseOrderData.courseOrderStatusId === CourseOrderStatus.Draft.id,
        courseId: courseOrderData.courseId,
        formValue: courseOrderData.formValue,
      });
    }
  }, [courseOrderData]);

  const onSubmit = async (data: CreateCourseOrderRequest) => {
    data.isDraft = isDraftSubmit.current;
    const res = await mutateAsync(data);

    if (res && !res.hasError) {
      reset();
      navigate(courseData ? "/home/authorOrders/MyAllOrders" : "/home/courses");
    }
  };

  return {
    errors,
    onSubmit: handleSubmit(onSubmit),
    register,
    watch,
    getValues,
    setValue,
    isSubmitting: isCreatingOrder,
    isOrderLoading,
    researcherAttachments,
    setResearcherAttachments,
    researcherAttachmentWithoutInfo,
    setResearcherAttachmentWithoutInfo,
    courseOrderId,
    courseOrderData,
    courseData,
    isLoading,
    courseId,
    isDraftSubmit,
    formSchema,
    setFormSchema,
  };
};

export default useOrderForm;
