import Accordion from "components/blocks/Accordion";
import AccordionItem from "components/blocks/Accordion/AccordionItem";
import PaginatedLookupDropdown from "components/blocks/PaginatedLookupDropdown";
import { useTranslation } from "react-i18next";

const AssignPermission = () => {
  const { t, i18n } = useTranslation("Orders");
  return (
    <Accordion>
      <AccordionItem initiallyOpen elementId="AssignPermission" title={t("Orders:assignPermission")}>
        <PaginatedLookupDropdown
          wrapperClassName="col-lg-4 col-sm-12"
          queryKey="search"
          service="accountService"
          endpoint="getUsersDropdown"
          idValueKey="id"
          textValueKey={i18n.language === "ar" ? "arabicName" : "englishName"}
          label={t("userName")}
          isPaginated
          multiselect={false}
        />
        <div className="divider mt-4"></div>
      </AccordionItem>
    </Accordion>
  );
};

export default AssignPermission;
