import { HttpClient } from "helpers/http/httpClient";
import { LookupResult } from "services/lookupService/models/LookupsType";
import { PagedResponseType, BaseResponseType, DataGridRequest } from "services/shared-models/sharedTypes";
import { CreateBounceTypeRequest, BounceTypeResult } from "./models/bounceType";

export const BounceTypeService = {
  getAllBounceTypes: async (request: { search: string; pageIndex: number; pageSize: number }) => {
    return HttpClient.post<PagedResponseType<LookupResult[]>>(`/api/BonusType/GetAllBonusTypes`, request);
  },

  createBounceType: async (request: CreateBounceTypeRequest) => {
    return HttpClient.post<BaseResponseType<BounceTypeResult>>(`/api/BonusType/CreateBonusType`, request);
  },

  updateBounceType: async (id: number, request: CreateBounceTypeRequest) => {
    return HttpClient.put<BaseResponseType<BounceTypeResult>>(`/api/BonusType/UpdateBonusType/${id}`, request);
  },

  deleteBounceType: async (id: number) => {
    return HttpClient.delete<BaseResponseType<BounceTypeResult>>(`/api/BonusType/DeleteBonusType/${id}`);
  },

  exportBounceTypeToExcel: async (request: DataGridRequest) => {
    return HttpClient.post<BaseResponseType<BounceTypeResult>>(`/api/BonusType/ExportBonusTypeToExcel`, request);
  },

  getAllBounceTypeDropDown: async () => {
    return HttpClient.get<BaseResponseType<BounceTypeResult[]>>(`/api/BonusType/GetAllBonusTypeDropDown`);
  },
};
