import Checkbox from "components/blocks/Checkbox";
import FileUploadWithPreview, { SelectedFilesType } from "components/blocks/FileUploadWithPreview";
import FormInput from "components/blocks/FormInput";
import Modal from "components/blocks/Modal";
import Switch from "components/blocks/Switch";
import { useTranslation } from "react-i18next";
import { FileLangResult, ScientificPublishingAttachment } from "../ScientificPublicationForm.types";
import { useState, useEffect } from "react";
import LookupDropdown from "components/blocks/LookupDropdown";
import { z } from "zod";

type SPFAttachmentModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: ScientificPublishingAttachment, files: SelectedFilesType) => void;
  data?: ScientificPublishingAttachment;
};

const SPFAttachmentModal: React.FC<SPFAttachmentModalProps> = (props) => {
  const { t } = useTranslation("ScientificPublication");

  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [files, setFiles] = useState<SelectedFilesType[]>([]);
  const [data, setData] = useState<ScientificPublishingAttachment>({
    id: 0,
    fileId: "",
    description: "",
    translatedName: "",
    fileLangId: 0,
    isAllowedFileToDownload: false,
    status: 1,
    createdOn: new Date().toISOString(),
  });

  useEffect(() => {
    if (props.data) {
      setData(props.data);
      setFiles([
        {
          uuid: props.data.fileId,
          fileName: props.data.file?.name!,
          size: props.data.file?.size,
          file: new File([], props.data.file?.name!, { type: props.data.file?.type }),
        },
      ]);
    }
  }, [props.data]);

  const SPFAttachmentValidationSchema = z.object({
    fileId: z.string().uuid(),
    description: z.string().min(1, t("Common:requiredField")),
    translatedName: z.string().min(1, t("Common:requiredField")),
    fileLangId: z.number().min(1, t("Common:requiredField")),
    isAllowedFileToDownload: z.boolean(),
    status: z.number({ message: t("Common:requiredField") }),
  });

  const onFileChange = (files: SelectedFilesType[]) => {
    setErrors((prev) => ({ ...prev, fileId: "" }));
    setFiles(files);
  };

  const onFieldChange = (
    field: keyof ScientificPublishingAttachment,
    value: string | number | boolean | FileLangResult | FileLangResult[],
  ) => {
    setErrors((prev) => ({ ...prev, [field]: "" }));
    setData((prev) => ({ ...prev, [field]: value }));
  };

  const onAdd = () => {
    data.fileId = files[0]?.uuid;

    const validation = SPFAttachmentValidationSchema.safeParse(data);

    if (!validation.success) {
      const mappedErrors = validation.error.errors.reduce((acc, error) => {
        acc[error.path[0] as string] = error.message;
        return acc;
      }, {} as { [key: string]: string });

      setErrors(mappedErrors);

      return;
    }

    props.onSubmit(data, files[0]);
    resetData();
  };

  const onCancel = () => {
    resetData();
    props.onClose();
  };

  const resetData = () => {
    setErrors({});
    setFiles([]);
    setData({
      id: 0,
      fileId: "",
      description: "",
      translatedName: "",
      fileLangId: 0,
      isAllowedFileToDownload: false,
      status: 1,
      createdOn: new Date().toISOString(),
    });
  };

  return (
    <Modal
      size="lg"
      isOpen={props.isOpen}
      onClose={props.onClose}
      withHeaderBorder
      bodyMargin="mt-1"
      containerClassName="bg-white"
      headerContent={
        <div className="d-flex align-items-center gap-2">
          <h5>{t("addFile")}</h5>
          <Switch checked={data.status === 1} onChange={() => onFieldChange("status", data.status === 1 ? 2 : 1)} />
        </div>
      }
    >
      <FileUploadWithPreview
        maxNumberOfFiles={1}
        files={files}
        formProps={{}}
        setFiles={setFiles}
        onChange={onFileChange}
      />
      {errors.fileId && <div className="text-danger">{t("Common:fileIsRequired")}</div>}

      <FormInput
        wrapperClassName="mt-4"
        label={t("Common:description")}
        onChange={(e) => onFieldChange("description", e.target.value)}
        error={errors.description}
        value={data.description}
      />
      <div className="row">
        <FormInput
          wrapperClassName="col-12 col-md-6 mt-2"
          label={t("translatorName")}
          onChange={(e) => onFieldChange("translatedName", e.target.value)}
          error={errors.translatedName}
          value={data.translatedName}
        />
        <LookupDropdown
          service="lookupService"
          endpoint="getAllFileLangDropDown"
          wrapperClassName="col-12 col-md-6 mt-2"
          label={t("fileLanguage")}
          onChange={(_, item) => {
            onFieldChange("fileLangId", +item.id);
            onFieldChange("fileLang", {
              id: +item.id,
              fileLangDetail: {
                displayName: item.value,
                // TODO: why do I need to send the entire thing to the backend
                // this includes the file object
                // only ids should be expected to be sent to the backend
                locale: "Ar",
              },
            });
          }}
          value={data.fileLangId}
          idValueKey="id"
          textValueKey="details.0.displayName"
          useReactSelect
          error={errors.fileLangId}
        />
      </div>

      <Checkbox
        containerClassName="mt-4"
        label={t("allowFileDownload")}
        checked={data.isAllowedFileToDownload}
        onChange={(e) => onFieldChange("isAllowedFileToDownload", e.target.checked)}
      />

      <div className="divider my-4" />

      <div className="d-flex justify-content-center gap-2">
        <button className="btn btn-primary" style={{ width: 150 }} onClick={onAdd}>
          {t("Common:add")}
        </button>
        <button className="btn btn-outline-secondary" style={{ width: 150 }} onClick={onCancel}>
          {t("Common:cancel")}
        </button>
      </div>
    </Modal>
  );
};

export default SPFAttachmentModal;
