import Accordion from "components/blocks/Accordion";
import AccordionItem from "components/blocks/Accordion/AccordionItem";
import Checkbox from "components/blocks/Checkbox";
import { DropdownItemType } from "components/blocks/Dropdown";
import FileUploadWithPreview, { SelectedFilesType } from "components/blocks/FileUploadWithPreview";
import FormInput from "components/blocks/FormInput";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FieldErrors, UseFormGetValues, UseFormRegister, UseFormSetValue, UseFormWatch } from "react-hook-form";
import { CreateScientificPublicationRequest, SearchWord } from "../ScientificPublicationForm.types";
import LookupDropdown from "components/blocks/LookupDropdown";
import { ScientificPublishingLocalItemResult } from "services/scientificPublicationsService/models/scientificPublicationsTypes";

type SPFormDetailsProps = {
  register: UseFormRegister<CreateScientificPublicationRequest>;
  setValue: UseFormSetValue<CreateScientificPublicationRequest>;
  watch: UseFormWatch<CreateScientificPublicationRequest>;
  errors: FieldErrors<CreateScientificPublicationRequest>;
  getValues: UseFormGetValues<CreateScientificPublicationRequest>;
  imageFile?: ScientificPublishingLocalItemResult["scientificPublishingImage"];
};

const KeywordBadge: React.FC<{ keyword: string; onRemove: () => void }> = ({ keyword, onRemove }) => {
  return (
    <div className="keyword-badge">
      <span>{keyword}</span>
      <span role="button" onClick={onRemove} className="icon-close fs-5 text-muted" />
    </div>
  );
};

const SPFormDetails: React.FC<SPFormDetailsProps> = ({ register, setValue, watch, errors, getValues, imageFile }) => {
  const { t } = useTranslation("ScientificPublication");

  const [libraries, setLibraries] = useState<DropdownItemType[]>([]);
  const [publicationImageFile, setPublicationImageFile] = useState<SelectedFilesType | null>(null);

  useEffect(() => {
    if (imageFile) {
      setPublicationImageFile({
        fileName: imageFile.name,
        size: imageFile.size,
        uuid: imageFile.id,
        file: new File([], imageFile.name),
      });
      setValue("scientificPublishingImageId", imageFile.id);
    }
  }, [imageFile]);

  const onLibrarySelected = (id: number) => {
    const currentLibrariesIds = getValues("librariesIds");
    if (!currentLibrariesIds.some((libId: number) => libId === id)) {
      setValue("librariesIds", [...currentLibrariesIds, id]);
    }
  };

  const onLibraryRemoved = (id: number) => {
    setValue(
      "librariesIds",
      getValues("librariesIds").filter((libId: number) => libId !== id),
    );
  };

  const onLibrariesLoaded = (data: DropdownItemType[]) => {
    setLibraries(data);
  };

  const onSearchKeywordsKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && event.currentTarget.value) {
      event.preventDefault();
      setValue("searchWords", [...getValues("searchWords"), { searchWord: event.currentTarget.value }]);
      event.currentTarget.value = "";
    }
  };

  const onSearchWordRemoved = (keyword: string) => {
    setValue(
      "searchWords",
      getValues("searchWords").filter((kw: SearchWord) => kw.searchWord !== keyword),
    );
  };

  return (
    <Accordion negatePadding>
      <AccordionItem initiallyOpen title={t("details")} elementId="details">
        <FormInput
          label={t("searchKeywords")}
          placeholder={t("searchKeywords")}
          onKeyDown={onSearchKeywordsKeyDown}
          error={t(errors.searchWords?.[0]?.searchWord?.message ?? "")}
        />
        <div className="d-flex flex-wrap gap-2 mt-2">
          {watch("searchWords").map((keyword, idx) => (
            <KeywordBadge
              key={`${idx}-${keyword.searchWord}`}
              keyword={keyword.searchWord}
              onRemove={() => onSearchWordRemoved(keyword.searchWord)}
            />
          ))}
        </div>

        <div className="divider my-4" />

        <FileUploadWithPreview
          label={t("publicationImageFile")}
          files={publicationImageFile ? [publicationImageFile] : []}
          setFiles={(files) => {
            setPublicationImageFile(files?.[0]);
            setValue("scientificPublishingImageId", files?.[0]?.uuid);
          }}
          formProps={{}}
          maxNumberOfFiles={1}
          clickHereForLabel={t("toAttachPublicationImage")}
          subTitle={t("maxFileSize5MB")}
          maxFileSizeKb={1024 * 5}
        />

        {errors.scientificPublishingImageId && (
          <div className="text-danger">{t(errors.scientificPublishingImageId.message ?? "")}</div>
        )}

        <div className="divider my-4" />

        <div className="row">
          <FormInput
            label={t("numberOfPages")}
            placeholder={t("numberOfPages")}
            wrapperClassName="col-12 col-md-4 col-lg-3 mb-3"
            {...register("numberOfPaper", { valueAsNumber: true })}
            error={t(errors.numberOfPaper?.message ?? "")}
          />
          <FormInput
            label={t("publicationLink")}
            placeholder={t("publicationLink")}
            wrapperClassName="col-12 col-md-4 col-lg-3 mb-3"
            {...register("scientificPublicationLink")}
            error={t(errors.scientificPublicationLink?.message ?? "")}
          />
          <FormInput
            label={t("ISSNSerialNumber")}
            placeholder={t("ISSNSerialNumber")}
            wrapperClassName="col-12 col-md-4 col-lg-3 mb-3"
            {...register("serialNumber")}
            error={t(errors.serialNumber?.message ?? "")}
          />
        </div>
        <FormInput
          label={t("metaData")}
          placeholder={t("metaData")}
          {...register("metaData")}
          error={t(errors.metaData?.message ?? "")}
        />

        <div className="divider my-4" />

        <Checkbox
          label={t("prepareHardCopy")}
          containerClassName="mb-3"
          checked={watch("isHardCopyAvailable")}
          onChange={(evt) => setValue("isHardCopyAvailable", evt.target.checked)}
        />

        <LookupDropdown
          service="lookupService"
          endpoint="getAllLibrariesDropDown"
          textValueKey="details.0.displayName"
          idValueKey="id"
          label={t("libraryName")}
          wrapperClassName="mb-3 col-12 col-md-6 col-lg-4"
          noSelectionPlaceholder={t("libraryName")}
          onChange={(value) => onLibrarySelected(+value)}
          value=""
          onDataLoaded={onLibrariesLoaded}
          useReactSelect
          error={t(errors.librariesIds?.[0]?.message ?? "")}
        />
        <div className="d-flex flex-wrap gap-2 mt-2">
          {watch("librariesIds").map((libId: number) => (
            <div className="keyword-badge" key={libId}>
              <span>{libraries.find((lib) => lib.id === libId)?.value}</span>
              <span role="button" onClick={() => onLibraryRemoved(+libId)} className="icon-close fs-5 text-muted" />
            </div>
          ))}
        </div>
      </AccordionItem>
    </Accordion>
  );
};

export default SPFormDetails;
