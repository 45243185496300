import { createContext, ReactNode, useContext, useEffect, useRef, useState } from "react";
import Cookies from "js-cookie";
import { languageChange } from "helpers";
import { CookieContextType } from "./models/CookieContextType";
import { GzipProvider } from "helpers/compress/gzipProvider";
import { jwtDecode } from "jwt-decode";
import { LoginResult, PublishingRoles } from "services/accountService/models/AccountType";

const CookieContext = createContext<CookieContextType | undefined>(undefined);

export const gzipProviderInstance = new GzipProvider();

// Interface for decoded JWT roles
export interface DecodedToken {
  role: string[];
}

export const getRolesFromToken = (session: string): PublishingRoles[] => {
  if (!session) return [];

  try {
    const decoded: DecodedToken = jwtDecode(JSON.parse(session)?.token);

    // @ts-ignore
    return (decoded["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"] || []) as PublishingRoles[];
  } catch (error) {
    Cookies.remove("Session");
    window.location.href = "/account/login";
    return [];
  }
};

export const CookieProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const compressedSession = useRef<string | undefined>();
  const [userSession, setUserSession] = useState<LoginResult | undefined>();
  const [localization, setLocalization] = useState<string | undefined>();
  const [isArabic, setIsArabic] = useState(true);
  const [isRightToLeft, setIsRightToLeft] = useState(true);
  const [isHijri, setIsHijri] = useState(false);

  // Function to update state based on cookies
  const updateCookieState = () => {
    const newUserSession = Cookies.get("Session");
    const newLocalization = Cookies.get("localization");
    const newIsHijri = Cookies.get("isHijri") === "true";

    if (newUserSession !== compressedSession.current) {
      compressedSession.current = newUserSession;
      if (newUserSession) {
        const decompressedSession = gzipProviderInstance.decompress(newUserSession || "");
        const userRoles = getRolesFromToken(decompressedSession);
        const user = JSON.parse(decompressedSession) as LoginResult;
        user.roles = userRoles as PublishingRoles[];
        setUserSession(user);
      } else {
        setUserSession(undefined);
      }
    }

    if (newLocalization !== localization) {
      const { CurrentLanguage, isRTL } = JSON.parse(newLocalization || "{}");
      setIsArabic(CurrentLanguage === "ar");
      languageChange(CurrentLanguage === "en" ? "en" : "ar");
      setIsRightToLeft(isRTL);
      setLocalization(newLocalization);
    }

    if (newIsHijri !== isHijri) {
      setIsHijri(newIsHijri);
    }
  };

  // Handle cross-tab cookie changes via localStorage or sessionStorage
  const handleStorageChange = (event: StorageEvent) => {
    console.log("Storage event:", event);
    if (["Session", "localization", "isHijri"].includes(event.key || "")) {
      updateCookieState();
    }
  };

  // React to cookie changes when the component mounts
  useEffect(() => {
    // Update state initially
    updateCookieState();

    // Add cross-tab storage listener
    window.addEventListener("storage", handleStorageChange);

    return () => {
      // Clean up the storage listener
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []); // Only runs once on mount

  // Function to set a cookie and update state
  const setCookie = (name: string, value: string, options?: Cookies.CookieAttributes) => {
    Cookies.set(name, value, options);

    updateCookieState(); // Manually trigger state update for same-tab changes
  };

  // Function to remove a cookie and update state
  const removeCookie = (name: string) => {
    Cookies.remove(name);
    updateCookieState(); // Manually trigger state update for same-tab changes
  };

  return (
    <CookieContext.Provider
      value={{
        userSession,
        localization,
        setCookie,
        removeCookie,
        isAr: isArabic,
        isRTL: isRightToLeft,
        isHijri,
      }}
    >
      {children}
    </CookieContext.Provider>
  );
};

// Custom hook to use the CookieContext
export const useCookieContext = (): CookieContextType => {
  const context = useContext(CookieContext);
  if (context === undefined) {
    throw new Error("useCookieContext must be used within a CookieProvider");
  }
  return context;
};
