import GridView from "components/GridView";
import { GridSchema, GridViewHeaderProps } from "components/GridView/GridView.types";
import { useBreadcrumb } from "contexts/breadcrumb/BreadcrumbContext";
import useGridData from "hooks/useGridData";
import useSystemDateTheme from "hooks/useSystemDateTheme";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ScientificPublicationResult } from "services/scientificPublicationsService/models/scientificPublicationsTypes";

const ScientificPublicationGridView = () => {
  const { t } = useTranslation("ScientificPublication");
  const navigate = useNavigate();
  const { setBreadcrumbs } = useBreadcrumb();
  const { toSystemThemeDateFormat } = useSystemDateTheme();

  const { loading, gridData, totalCount, exportToExcel } = useGridData<
    "scientificPublicationsService",
    ScientificPublicationResult
  >({
    service: "scientificPublicationsService",
    endpoint: "getAllScientificPublishing",
    exportExcelEndpoint: "exportToExcel",
    exportFileName: "scientificPublications",
    localizationNameSpace: "ScientificPublication",
  });

  useEffect(() => {
    setBreadcrumbs([
      {
        localizationKey: "scientificPublication",
        path: "/home/scientificPublication",
        menuItemId: "scientificPublication",
      },
    ]);
  }, []);

  const gridSchema = useMemo<GridSchema[]>(
    () => [
      {
        field: "id",
        displayName: "",
        showOnMobile: true,
        render: (row: ScientificPublicationResult) => {
          return (
            <img
              src={`${process.env.REACT_APP_APIGATEWAY_KEY}/FileManagerApi/File/Download/${row.scientificPublishingImage.id}`}
              alt="publication"
              style={{ width: 55 }}
            />
          );
        },
      },
      {
        field: "scientificPublishingDetail.displayName",
        displayName: t("publicationName"),
        sortable: true,
        filterable: true,
        showOnMobile: true,
        render: (row: ScientificPublicationResult) => {
          return (
            // TODO: remove the 9 when the id is implemented
            <button className="btn btn-link" onClick={() => navigate(`/home/scientificPublication/${row.id ?? 12}`)}>
              {row.scientificPublishingDetail.displayName}
            </button>
          );
        },
      },
      {
        field: "scientificClassificationDetail.displayName",
        displayName: t("scientificClassification"),
        render: (row: ScientificPublicationResult) => {
          return row.scientificClassificationDetail.displayName;
        },
      },
      {
        field: "authorName",
        displayName: t("publisherName"),
      },
      {
        field: "publicationDate",
        displayName: t("publishingDate"),
        render: (row: ScientificPublicationResult) => {
          return toSystemThemeDateFormat(row.publicationDate);
        },
      },
      {
        field: "createBy",
        displayName: t("createdBy"),
        render: (row: ScientificPublicationResult) => {
          return `${row.createBy} - ${toSystemThemeDateFormat(row.createOn)}`;
        },
      },
      {
        field: "status",
        displayName: t("Common:status"),
        render: (row: ScientificPublicationResult) => {
          const statusClassName = row.status === "Publishing" ? "bg-success text-success " : "bg-primary text-danger";
          return (
            <span className={`badge bg-opacity-10 rounded-4 ${statusClassName} py-2`}>
              {t(`${row.status}`.toLowerCase())}
            </span>
          );
        },
      },
    ],
    [t, toSystemThemeDateFormat],
  );

  const viewHeader: GridViewHeaderProps = {
    title: t("scientificPublications"),
    singularName: t("scientificPublication"),
    totalCount: totalCount,
    onExportClick: exportToExcel,
    addPageRoute: "/home/scientificPublication/form",
  };

  return (
    <GridView
      loading={loading}
      gridProps={{
        data: gridData ?? [],
        gridSchema,
      }}
      viewHeaderProps={viewHeader}
    />
  );
};

export default ScientificPublicationGridView;
