import OrderView from "../..";
import { tabsIds } from "../../orderPage.types";
import useSystemDateTheme from "hooks/useSystemDateTheme";
import { useTranslation } from "react-i18next";
import { useRef } from "react";
import { OrderInfoTab } from "../../tabs/OrderInfoTab";
import CourseMinutesTab from "../../tabs/CourseMinutesTab";
import AdditionalAttachmentsTab from "../../tabs/AdditionalAttachmentsTab";
import SpeechesTab from "../../tabs/SpeechesTab";

export type AdminTapActionsRef = {
  submit: () => void;
};

const CourseOrderAdminView = () => {
  const submitRef = useRef<AdminTapActionsRef>(null);
  const { t } = useTranslation("Orders");
  const { toSystemThemeDateFormat } = useSystemDateTheme();

  return (
    <OrderView
      breadcrumbs={({ serialNumber, orderId }) => [
        {
          localizationKey: "allOrders",
          path: "/home/orders",
          menuItemId: "allOrders",
        },
        {
          localizationKey: "order-number",
          path: `/home/orders/${orderId}`,
          localizationParameters: { orderNumber: serialNumber },
        },
      ]}
      defaultTabId="info"
      leftSection={({ selectedTabId }) => {
        return selectedTabId !== tabsIds.info ? (
          <button
            key="save"
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              submitRef.current?.submit();
            }}
            className="btn btn-primary btn-sm rounded p-10-32P"
          >
            {t("save")}
          </button>
        ) : undefined;
      }}
      tapsToView={({ courseData, courseOrderData, openedTask, activePage, setActivePage, userType }) => {
        return [
          {
            id: tabsIds.info,
            title: t("info"),
            content: (
              <OrderInfoTab
                setActivePage={setActivePage}
                activePage={activePage}
                activeSectionsIds={[
                  "applicantInfoWithDetails",
                  "researchesCenter",
                  "judges",
                  "rewards",
                  "publishingHouse",
                  "procedures",
                ]}
                userType={userType}
                courseOrder={courseOrderData}
                course={courseData}
                openedTask={openedTask}
                submitRef={submitRef}
              />
            ),
          },
          {
            id: tabsIds.minutesOfMeeting,
            title: t("minutesOfMeeting"),
            content: <CourseMinutesTab courseOrderId={courseOrderData?.id ?? 0} submitRef={submitRef} />,
          },
          {
            id: tabsIds.letters,
            title: t("letters"),
            content: <SpeechesTab submitRef={submitRef} courseOrderId={courseOrderData?.id ?? 0} />,
          },

          {
            id: tabsIds.attachments,
            title: t("attachments"),
            content: <AdditionalAttachmentsTab submitRef={submitRef} courseOrderId={courseOrderData?.id ?? 0} />,
          },
        ];
      }}
      userType="Admin"
      headersToView={({ courseOrderData }) =>
        !courseOrderData
          ? []
          : [
              {
                title: t("orderNumber"),
                value: courseOrderData?.serialNumber ?? "",
              },
              {
                title: t("applicationDate"),
                value: toSystemThemeDateFormat("2024-01-01"), //TODO": return id
              },
              {
                title: t("publicationTypeCategory"),
                value: `${courseOrderData.typeOfScientific?.typeOfScientificDetail.displayName ?? ""} - ${
                  courseOrderData.scientificClassification?.scientificClassificationDetail?.displayName ?? ""
                }`,
              },
              {
                title: t("publicationLanguage"),
                value: `${courseOrderData?.languageOfScientific.languageOfScientificDetail.displayName}`,
              },
              {
                title: t("researchCenter"),
                value: `${courseOrderData?.centreManagement.displayName ?? ""}`,
              },
              {
                title: t("numberOfPages"),
                value: courseOrderData?.numberOfPages ?? 0,
              },
              {
                title: t("publicationDate"),
                value: toSystemThemeDateFormat(courseOrderData.submissionDate),
              },
              {
                title: t("rewardReceipt"),
                value: (
                  <button className="btn btn-link" type="button">
                    {t("receipt")}
                  </button>
                ),
              },
            ]
      }
    />
  );
};

export default CourseOrderAdminView;
