import GenericActionButtons from "../../GenericTaskActionButtons";
import { OpenPublishingTaskResult } from "services/tasks/models/tasksTypes";
import { RefObject, useState } from "react";
import { TaskActionsNames } from "services/shared-models/sharedTypes";
import GenericActionsModal from "../../OrderTaskActionModals/GenericActionsModal";
import { AdminTapActionsRef } from "../CourseOrderAdminView";
import { tabsIds } from "../../orderPage.types";
import { useTranslation } from "react-i18next";

const Actions = ({
  selectedTabId,
  openTask,
  submitRef,
}: {
  selectedTabId: string;
  openTask: OpenPublishingTaskResult | null;
  submitRef: RefObject<AdminTapActionsRef>;
}) => {
  const { t } = useTranslation("Orders");
  const [openActionModal, setOpenActionModal] = useState<{
    isOpen: boolean;
    msg: string;
    type?: string;
  }>();

  const onSubmit = async (action: TaskActionsNames) => {};

  return (
    <>
      {selectedTabId === tabsIds.info ? (
        <GenericActionButtons
          loading={false}
          openedTask={openTask}
          onActionClick={(action, msg) => {
            setOpenActionModal({ isOpen: true, msg, type: action });
          }}
        />
      ) : (
        <button
          key="save"
          type="submit"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            submitRef.current?.submit();
          }}
          className="btn btn-primary btn-sm rounded p-10-32P"
        >
          {t("save")}
        </button>
      )}

      <GenericActionsModal
        isOpen={openActionModal !== undefined}
        onClose={() => setOpenActionModal(undefined)}
        isSubmitting={/* !!openActionModal?.loading */ false}
        onSubmit={async () => {
          //onSubmit(openActionModal?.openedTask.actionName[0] as TaskActionsNames);
        }}
        msg={openActionModal?.msg ?? ""}
      />
    </>
  );
};

export default Actions;
